import {
    CYDER_GROUPS_DETAIL_REQUEST,
    CYDER_GROUPS_DETAIL_SUCCESS,
    CYDER_GROUPS_DETAIL_FAILURE,
    CYDER_GROUPS_DETAIL_REMOVE_USER_LIST_ADD,
    CYDER_GROUPS_DETAIL_REMOVE_USER_LIST_REMOVE,
    CYDER_GROUPS_DETAIL_ADD_USER_LIST_ADD,
    CYDER_GROUPS_DETAIL_ADD_USER_LIST_REMOVE,
    CYDER_GROUPS_DETAIL_CHANGE_FIELD,
    CYDER_GROUPS_DETAIL_SAVE_CHANGES_REQUEST,
    CYDER_GROUPS_DETAIL_SAVE_CHANGES_SUCCESS,
    CYDER_GROUPS_DETAIL_SAVE_CHANGES_FAILURE,
    CYDER_GROUPS_DETAIL_DELETE_REQUEST,
    CYDER_GROUPS_DETAIL_DELETE_SUCCESS,
    CYDER_GROUPS_DETAIL_DELETE_FAILURE,
    CYDER_GROUPS_DETAIL_MODAL_TOGGLE,
} from '../../actions/constants/actionTypes' 
import cyderlib from '../../js/cyderlib'

// defaults
var defaultState = {
    usersReference: {},
    loading: false,
    saving: false,
    saved: false,
    errorMessage: null,
    group: {},
    validation: {},
    removedUsers: {},
    changesMade: false,
    saveButtonPressed: false,
    modalOpen: false,
    modalAction: null,
    modalMessage: "Are you sure you want to update this group?",
}

export function cyderGroupsDetailReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    let removedUsers;
    let usersReference = {};
    let newUserList;
    let group;
    switch (action.type) {
        case CYDER_GROUPS_DETAIL_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            newState.removedUsers = {};
            newState.saved = false;
            return newState;
        case CYDER_GROUPS_DETAIL_SUCCESS:
            newState.loading = false;
            newState.group = action.response;
            const ulist = action.response.usersList;
            ulist.forEach((item) => {
                usersReference[item.username] = true;
            })
            newState.usersReference = Object.assign({}, usersReference);
            return newState;
        case CYDER_GROUPS_DETAIL_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            return newState;
        case CYDER_GROUPS_DETAIL_REMOVE_USER_LIST_ADD:
            removedUsers = Object.assign({}, state.removedUsers);
            removedUsers[action.username] = true;
            newState.removedUsers = removedUsers;
            newState.changesMade = true;
            return newState;
        case CYDER_GROUPS_DETAIL_REMOVE_USER_LIST_REMOVE:
            removedUsers = Object.assign({}, state.removedUsers);
            removedUsers[action.username] = false;
            newState.removedUsers = removedUsers;
            newState.changesMade = true;
            return newState;
        case CYDER_GROUPS_DETAIL_ADD_USER_LIST_ADD:
            var obj = {};
            obj[action.username] = true;
            usersReference = Object.assign(obj, state.usersReference);
            usersReference[action.username] = true;
            newUserList = state.group.usersList.concat({username: action.username});
            group = Object.assign({}, state.group);
            group.usersList = newUserList;
            newState.group = group;
            newState.usersReference = usersReference;
            newState.changesMade = true;
            return newState;
        case CYDER_GROUPS_DETAIL_ADD_USER_LIST_REMOVE:
            const existingUserList = state.group.usersList.concat();
            const i = existingUserList.find(action.username);
            newUserList = existingUserList.slice(0,i).concat(existingUserList.slice(i+1));
            group = Object.assign({}, state.group);
            group.usersList = newUserList;
            newState.group = group;
            newState.changesMade = true;
            return newState;
        case CYDER_GROUPS_DETAIL_CHANGE_FIELD:
            newState.group = Object.assign({}, state.group);
            newState.validation = Object.assign({}, state.validation);
            newState.group[action.key] = action.value;
            newState.validation[action.key] = cyderlib.validate(action.key, action.value);
            newState.changesMade = true;
            return newState;
        case CYDER_GROUPS_DETAIL_SAVE_CHANGES_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_GROUPS_DETAIL_SAVE_CHANGES_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Changes Successfully saved";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case CYDER_GROUPS_DETAIL_SAVE_CHANGES_FAILURE:
            newState.modalMessage = "Failed to save changes: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_GROUPS_DETAIL_DELETE_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_GROUPS_DETAIL_DELETE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Group successfully deleted";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "redirect";
            return newState;
        case CYDER_GROUPS_DETAIL_DELETE_FAILURE:
            newState.modalMessage = "Failed to delete group : " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_GROUPS_DETAIL_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === "update") {
                newState.modalMessage = "Are you sure you want to update this group?";
                newState.saved = false;
            } else if (action.modalAction === "delete") {
                newState.modalMessage = "Are you sure you want to delete this group?";
                newState.saved = false;
            }
            return newState;
        default:
            return state;
    }
}
