import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse, Card } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import history from '../../history';
import { setConfig, switchNavMenu } from '../../actions/config';
import { cyderLogoutAction } from '../../actions/security/cyderSecurityAction';

class Menu extends Component {
    state = {
        isOpen: false,
    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    shouldItemsShown = items => {
        if (!items.show) return false;
        const accessLevel = items.accessLevel || 0;
        if (this.props.role < accessLevel) return false;
        return true;
    };

    render() {
        const { t, items } = this.props;
        if (!this.shouldItemsShown(items)) return null;

        // If no children items found
        if (!items.items || items.items.length === 0) {
            const hasMatched = (title, match) => (title && match ? title.toLowerCase() === match : false);
            const isLeaveTypeMenu = hasMatched(items.title, 'djarvis leave');
            const isExpenseMenu = hasMatched(items.title, 'djarvis expenses');
            const isLogout = hasMatched(items.action, 'logout');

            return (
                <LinkMenuList
                    className="btn-sidebar-1"
                    t={t}
                    item={items}
                    onClick={() => {
                        this.props.closeSideMenu();
                        // SWITCH FOR LEAVE TYPE NAV OR EXPENSE NAV
                        if (isLeaveTypeMenu) this.props.switchNavMenu('leaveTypeNavigation');
                        if (isExpenseMenu) this.props.switchNavMenu('expenseNavigation');
                        if (isLogout) this.props.logout();
                    }}
                />
            );
        }

        // Children items list
        const childNodes = (
            <ul className="list-unstyled">
                {items.items.map((item, k) => {
                    if (!item.show) return null;
                    return (
                        <LinkMenuList
                            {...this.state}
                            className="btn-sidebar-2"
                            t={t}
                            key={k}
                            item={item}
                            onClick={() => {
                                if (item.action === 'logout') {
                                    this.props.logout();
                                }
                                this.props.closeSideMenu();
                            }}
                        />
                    );
                })}
            </ul>
        );

        return (
            <LinkMenuList
                arrow={this.props.layout === 'default-sidebar-1'}
                useHyperlink
                t={t}
                item={items}
                onClick={this.toggle}
                className={classnames('btn-sidebar-1 has-children', {
                    'is-open': this.props.layout === 'collapsed-sidebar-1' && this.state.isOpen,
                })}
            >
                {this.props.layout === 'default-sidebar-1' ? <Collapse isOpen={this.state.isOpen}>{childNodes}</Collapse> : childNodes}
            </LinkMenuList>
        );
    }
}

const LinkMenuList = props => {
    const { useHyperlink, children, arrow, isOpen, onClick, className, item, t = () => {} } = props;
    const { url, action, icon, micon, title, badge } = item;

    const newClassName = classnames('btn btn-default btn-flat btn-sidebar', className);
    const isMaterialIcon = micon ? true : false;
    const iconName = micon || icon;

    const childrenNode = (
        <div className="d-flex align-items-center">
            {isMaterialIcon ? <i className="material-icons">{iconName}</i> : <i className={classnames('fa', 'fa-' + iconName)} />}
            <span className={'title text-bold'}>{t(`${title}`)}</span>
            {badge && <span className={classnames('ml-auto', badge.className)}>{badge.title}</span>}
            {arrow && <i className="material-icons menulist-more-icon">keyboard_arrow_right</i>}
        </div>
    );

    return (
        <li>
            {useHyperlink ? (
                <>
                    <div className={newClassName} onClick={onClick}>
                        {childrenNode}
                    </div>
                    {children}
                </>
            ) : (
                <Link to={url} onClick={onClick} className={newClassName}>
                    {childrenNode}
                </Link>
            )}
        </li>
    );
};

const mapStateToProps = ({ config }) => {
    return {
        ...config,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        closeSideMenu: () => dispatch(setConfig('collapsed', false)),
        switchNavMenu: navType => dispatch(switchNavMenu(navType)),
        logout: () => {
            dispatch({ type: 'SWITCH_NAV_MENU', navType: 'expenseNavigation' });
            dispatch(cyderLogoutAction()).then(res => {
                history.push('/');
            });
        },
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Menu));
