import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Logo from '../logo';
import ToggleLayout1 from './ToggleLayout1';
import ToggleEmptyLayout from './ToggleEmptyLayout';
import ToggleLayout2 from './ToggleLayout2';
import DropdownUser from './DropdownUser';
import DevNav from '../../cyderComponents/common/DevNav';
import LinkButton from '../../cyderComponents/buttons/LinkButton';
import { capitalize } from 'js/generalUtils';
import '../../scss/elements/navbar-1.scss';
const pjson = require('../../../package.json');

const EnvLabel = () => {
    const env = process.env.NODE_ENV;
    const isDev = env === 'development';
    return (
        <>
            <div className="d-none d-lg-block">{isDev && <span className="text-danger text-bold">{capitalize(env)}</span>}</div>
            <div className="d-none d-sm-block d-lg-none">
                <span className="text-bold">Djarvis</span>
                {` `}
                <span className="text-bold">{pjson.version}</span>
                {isDev && (
                    <span className="text-danger text-bold">
                        {` `}
                        {capitalize(env)}
                    </span>
                )}
            </div>
            <div className="d-md-none">
                <span className="text-bold">Djarvis</span>
                {` `}
                <span className="text-bold">{pjson.version}</span>
                <br />
                {isDev && <span className="text-danger text-bold">{capitalize(env)}</span>}
            </div>
        </>
    );
};
const Navbar1 = ({ persistor, profile }) => {
    const hasProfilePic = profile.profile_picture ? true : false;
    const trialDaysRemaining = Math.floor(moment.duration(moment(profile.subscription_end).diff(moment())).as('days'));

    return (
        <nav className="navbar navbar-1 d-flex justify-content-around align-items-center flex-nowrap">
            <Logo />
            <ToggleLayout1 />
            <ToggleLayout2 />

            <EnvLabel />
            <DevNav persistor={persistor} />
            <div className="separator" />
            <ul className="nav nav-inline d-none d-sm-block pt15 mr20">
                <li className="nav-item">
                    <span style={{ lineHeight: '1.98rem', marginRight: '20px' }} className="navbar-text align-center text-bold">
                        {profile.firstname || ''}
                        {` `}
                        {profile.lastname || ''}
                    </span>
                    <span className="navbar-text align-center">{profile.companyname || ''}</span>
                </li>
                <li className="nav-item">
                    <div className="nav-link">
                        {profile.accountstatus === 'Trial' ? (
                            <LinkButton
                                color="success"
                                altColor="light"
                                to="https://djarvis.page/"
                                text={'Trial days remaining: ' + trialDaysRemaining}
                                className="mr-2"
                                strong
                                trueLink
                                fontSize="0.875rem"
                            />
                        ) : null}
                    </div>
                </li>
            </ul>
            {/* <div className="d-none d-sm-block">
                <ul className="nav nav-inline nav-inline-2">
                    <li className="nav-item nav-item-dropdown">
                        <div className="nav-link nav-link-avatar">
                            <img
                                alt="avatar"
                                src={hasProfilePic ? `data:image/png;base64, ${profile.profile_picture}` : ''}
                                onError={e => (e.target.src = '/assets/images/profile_picture_default.jpg')}
                            />
                        </div>
                        <DropdownUser />
                    </li>
                    <li style={{ width: '0px' }} className="nav-item nav-item-dropdown">
                        <div style={{ width: '0px' }} className="nav-link">
                            <span style={{ width: '0px' }} className="flag flag-icon-background flag-icon flag-icon-gb" />
                        </div>
                    </li>
                </ul>
            </div> */}
        </nav>
    );
};

const mapStateToProps = state => {
    return {
        ...state.cyderProfileReducer,
    };
};

export default connect(mapStateToProps, {})(Navbar1);
