import {
    CYDER_EXPENSE_REPORTS_CREATE_MODAL_TOGGLE,
    CYDER_EXPENSE_REPORTS_CREATE_SUBMIT_EXPENSE_REPORT_REQUEST,
    CYDER_EXPENSE_REPORTS_CREATE_SUBMIT_EXPENSE_REPORT_SUCCESS,
    CYDER_EXPENSE_REPORTS_CREATE_SUBMIT_EXPENSE_REPORT_FAILURE,
    CYDER_EXPENSE_REPORTS_CREATE_RESET_STATE,
    CYDER_EXPENSE_REPORTS_GET_APPROVERS_REQUEST,
    CYDER_EXPENSE_REPORTS_GET_APPROVERS_SUCCESS,
    CYDER_EXPENSE_REPORTS_GET_APPROVERS_FAILURE,
} from '../../actions/constants/actionTypesReports';

// defaults
var defaultState = {
    receiptIdForRemoval: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
    loading: false,
    approverList: [],
};

export function cyderExpenseReportsCreateReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    // let newSelectedItems = {};
    // let stateResult;
    switch (action.type) {
        case CYDER_EXPENSE_REPORTS_CREATE_RESET_STATE:
            console.log('resetting state');
            newState = Object.assign({}, defaultState);
            return newState;
        case CYDER_EXPENSE_REPORTS_CREATE_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === 'remove') {
                newState.modalMessage = 'Are you sure you want to remove this receipt from this report?';
                newState.receiptIdForRemoval = action.data.receiptId;
                newState.saved = false;
            } else if (action.modalAction === 'save' || action.modalAction === 'submit') {
                newState.modalMessage =
                    'Are you sure you want to submit these receipts as a report? Receipts will not be editable once submitted.';
                newState.saved = false;
            }
            return newState;
        case CYDER_EXPENSE_REPORTS_CREATE_SUBMIT_EXPENSE_REPORT_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_EXPENSE_REPORTS_CREATE_SUBMIT_EXPENSE_REPORT_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : 'Report Successfully submitted';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'redirect';
            return newState;
        case CYDER_EXPENSE_REPORTS_CREATE_SUBMIT_EXPENSE_REPORT_FAILURE:
            newState.modalMessage = 'Failed to submit report: ' + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_EXPENSE_REPORTS_GET_APPROVERS_REQUEST:
            newState.approverList = [];
            newState.loading = true;
            return newState;
        case CYDER_EXPENSE_REPORTS_GET_APPROVERS_SUCCESS:
            newState.approverList = [];
            action.response.forEach(item => {
                newState.approverList.push({
                    value: item.email,
                    label: item.email,
                });
            });
            newState.loading = false;
            return newState;
        case CYDER_EXPENSE_REPORTS_GET_APPROVERS_FAILURE:
            newState.approverList = [];
            newState.loading = false;
            return newState;
        default:
            return state;
    }
}
