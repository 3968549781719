import React from 'react';
import { Switch, Route } from 'react-router';

// COMPONENTS
import asyncImport from 'cyderComponents/asyncImport/asyncImport';

// PRIVATE ROUTE
import PrivateRoute from '../cyderComponents/common/PrivateRoute';

//PAGES
const Home = asyncImport(() => import('../pages/home/expenseHome'));
const Login = asyncImport(() => import('../pages/login'));
const ChangePassword = asyncImport(() => import('../pages/changepassword/ChangePassword'));
const ForgotPassword = asyncImport(() => import('../pages/forgotpassword/ForgotPassword'));
const Profile = asyncImport(() => import('../pages/profile'));

// SETTINGS
const MainSettingsPage = asyncImport(() => import('../pages/settings/MainSettingsPage'));
const Sysparams = asyncImport(() => import('../pages/settings/expense/Sysparams'));

// ADMINISTRATION
const UsersDetailPage = asyncImport(() => import('../pages/users/UsersDetailPage'));
const UsersSearchPage = asyncImport(() => import('../pages/users/UsersSearchPage'));
const UsersAddPage = asyncImport(() => import('../pages/users/UsersAddPage'));
const GroupsSearchPage = asyncImport(() => import('../pages/groups/GroupsSearchPage'));
const GroupsDetailPage = asyncImport(() => import('../pages/groups/GroupsDetailPage'));
const GroupsAddPage = asyncImport(() => import('../pages/groups/GroupsAddPage'));

// EXPENSE
const MyReceiptsPage = asyncImport(() => import('../pages/expenses/receipts/MyReceiptsPage'));
const ExpenseNewReceiptPage = asyncImport(() => import('../pages/expenses/receipts/new/ExpenseNewReceiptPage'));
const ExpenseNewMileagePage = asyncImport(() => import('../pages/expenses/receipts/new/ExpenseNewMileagePage'));
const MyReportsPage = asyncImport(() => import('../pages/expenses/reports/MyReportsPage'));
const ReportsDetailPage = asyncImport(() => import('../pages/expenses/reports/ReportsDetailPage'));
const CreateReportPage = asyncImport(() => import('../pages/expenses/reports/CreateReportPage'));

// REPORTS
const ReceiptByCategory = asyncImport(() => import('../pages/report/ReceiptByCategory'));
const ReceiptByProject = asyncImport(() => import('../pages/report/ReceiptByProject'));
const MileageByProject = asyncImport(() => import('../pages/report/MileageByProject'));
const MileageByCategory = asyncImport(() => import('../pages/report/MileageByCategory'));
const ReportsByMonth = asyncImport(() => import('../pages/report/ReportsByMonth'));
const ReportsByMonthPrint = asyncImport(() => import('../pages/report/ReportsByMonthPrint'));
const ReportsByMonthDetails = asyncImport(() => import('../pages/report/ReportsByMonthDetails'));

// UNKNOWN
const MyTasksPage = asyncImport(() => import('../pages/workflow/MyTasksPage'));
const TasksDetailPage = asyncImport(() => import('../pages/workflow/TasksDetailPage'));
const TaskDetailReceiptComponent = asyncImport(() => import('../pages/workflow/TaskDetailReceiptComponent'));
const TaskDetailMileageComponent = asyncImport(() => import('../pages/workflow/TaskDetailMileageComponent'));
const DelegationsPage = asyncImport(() => import('../pages/workflow/DelegationsPage'));
const PaymentsListPage = asyncImport(() => import('../pages/payments/PaymentsListPage'));

// COMMON
const ErrorPage = asyncImport(() => import('../pages/error-page'));

const ExpenseRoutes = () => (
    <div>
        <Switch>
            {/* GENERAL */}
            <Route exact path="/" component={Login} />
            <Route exact path="/" component={Login} />
            <Route exact path="/changepassword" component={ChangePassword} />
            <Route exact path="/changepassword/:token" component={ChangePassword} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />

            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/profile" component={Profile} />

            {/* SETTINGS */}
            <PrivateRoute accessLevel={1} exact path="/administration/settings/sysparams" component={Sysparams} />
            <PrivateRoute path="/settings/expense/expensecategories" component={MainSettingsPage} />
            <PrivateRoute path="/settings/expense/" component={MainSettingsPage} />

            {/* ADMINISTRATION */}
            <PrivateRoute accessLevel={1} exact path="/administration/users" component={UsersSearchPage} />
            <PrivateRoute accessLevel={1} exact path="/administration/users/add" component={UsersAddPage} />
            <PrivateRoute accessLevel={1} exact path="/administration/users/details/:userid" component={UsersDetailPage} />
            <PrivateRoute accessLevel={1} exact path="/administration/groups" component={GroupsSearchPage} />
            <PrivateRoute accessLevel={1} exact path="/administration/groups/add" component={GroupsAddPage} />
            <PrivateRoute accessLevel={1} exact path="/administration/groups/details/:groupid" component={GroupsDetailPage} />

            {/* EXPENSE - RECEIPTS */}
            <PrivateRoute path="/expense/myreceipts/edit/receipt/:receiptid?" component={ExpenseNewReceiptPage} />
            <PrivateRoute path="/expense/myreceipts/view/receipt/:receiptid?" component={ExpenseNewReceiptPage} />
            <PrivateRoute path="/expense/myreceipts/edit/mileage/:receiptid?" component={ExpenseNewMileagePage} headerTitle="Edit Saved Mileage" />
            <PrivateRoute path="/expense/myreceipts/view/mileage/:receiptid?" component={ExpenseNewMileagePage} headerTitle="View Mileage" />
            <PrivateRoute path="/expense/myreceipts/new/receipt/:receiptid?" component={ExpenseNewReceiptPage} />
            <PrivateRoute path="/expense/myreceipts/new/mileage/:receiptid?" component={ExpenseNewMileagePage} />
            <PrivateRoute path="/expense/myreceipts/new/receipt" component={ExpenseNewReceiptPage} />
            <PrivateRoute path="/expense/myreceipts/new/mileage" component={ExpenseNewMileagePage} />
            <PrivateRoute path="/expense/myreceipts" component={MyReceiptsPage} />

            {/* EXPENSE - MY REPORTS */}
            <PrivateRoute path="/expense/myreports/details/:reportid" component={ReportsDetailPage} mode="self" />
            <PrivateRoute path="/expense/myreports/create" component={CreateReportPage} />
            <PrivateRoute path="/expense/myreports" component={MyReportsPage} />

            {/* EXPENSE - RECURRING CHARGES */}
            <PrivateRoute recurring={true} path="/expense/recurring/edit/:receiptid?" component={ExpenseNewReceiptPage} />
            <PrivateRoute recurring={true} path="/expense/recurring/new/:receiptid?" component={ExpenseNewReceiptPage} />
            <PrivateRoute recurring={true} path="/expense/recurring/new" component={MyReceiptsPage} />
            <PrivateRoute recurring={true} path="/expense/recurring" component={MyReceiptsPage} />

            {/* REPORTS */}
            <PrivateRoute exact path="/report/expense/receiptbycategory" component={ReceiptByCategory} />
            <PrivateRoute exact path="/report/expense/receiptbyproject" component={ReceiptByProject} />
            <PrivateRoute exact path="/report/expense/mileagebyproject" component={MileageByProject} />
            <PrivateRoute exact path="/report/expense/mileagebycategory" component={MileageByCategory} />
            <PrivateRoute exact path="/report/expense/reportsbymonth" component={ReportsByMonth} />
            <PrivateRoute path="/report/expense/reportsbymonth/details/:reportid" component={ReportsByMonthDetails} mode="admin" />
            <PrivateRoute path="/report/expense/reportsbymonth/print" component={ReportsByMonthPrint} mode="admin" />

            {/* ADMIN - PAYMENT */}
            <PrivateRoute accessLevel={1} path="/payments/:reportid" component={ReportsDetailPage} />
            <PrivateRoute accessLevel={1} path="/payments" component={PaymentsListPage} />

            {/* MY TASKS */}
            <PrivateRoute path="/workflow/mytasks" component={MyTasksPage} />
            <PrivateRoute path="/workflow/delegations" component={DelegationsPage} />
            <PrivateRoute path="/workflow/task/taskview/mileage/:itemid" component={TaskDetailMileageComponent} />
            <PrivateRoute path="/workflow/task/taskview/receipt/:itemid" component={TaskDetailReceiptComponent} />
            <PrivateRoute path="/workflow/task/:taskid" component={TasksDetailPage} />

            {/* ERROR PAGE */}
            <PrivateRoute path="/" component={ErrorPage} />
        </Switch>
    </div>
);

export default ExpenseRoutes;
