import moment from 'moment';
import {
    DELEGATIONS_SEARCH_REQUEST,
    DELEGATIONS_SEARCH_SUCCESS,
    DELEGATIONS_SEARCH_FAILURE,
    DELEGATIONS_SEARCH_SET_FORCE_REFRESH_FLAG,
    DELEGATIONS_MODAL_TOGGLE,
    DELEGATIONS_MODAL_SET_DELEGATION,
    DELEGATIONS_MODAL_CHANGE_VALUE,
    DELEGATIONS_DELEGATION_ADD_REQUEST,
    DELEGATIONS_DELEGATION_ADD_SUCCESS,
    DELEGATIONS_DELEGATION_ADD_FAILURE,
    DELEGATIONS_DELEGATION_UPDATE_REQUEST,
    DELEGATIONS_DELEGATION_UPDATE_SUCCESS,
    DELEGATIONS_DELEGATION_UPDATE_FAILURE,
    DELEGATIONS_DELEGATION_DELETE_REQUEST,
    DELEGATIONS_DELEGATION_DELETE_SUCCESS,
    DELEGATIONS_DELEGATION_DELETE_FAILURE,
} from '../../actions/constants/actionTypesDelegations' 

// defaults
var defaultState = {
    modalDelegation: {
        id: "",
        code: "",
        name: "",
        users: [],
        delegateUsers: [],
        approvalvotescount: 1,
        overrideexpenseapproval: false,
    },
    usersReference: {},
    approversReference: {},
    saving: false,
    saved: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: "",
    modalHeader: "",
    loading: false,
    delegations: [],
    forceRefresh: false,
}

export function delegationsReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case DELEGATIONS_SEARCH_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case DELEGATIONS_SEARCH_SUCCESS:
            newState.loading = false;
            newState.delegations = action.response;
            return newState;
        case DELEGATIONS_SEARCH_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.delegations = [];
            return newState;
        case DELEGATIONS_DELEGATION_ADD_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case DELEGATIONS_DELEGATION_ADD_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Delegation has been successfully added";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case DELEGATIONS_DELEGATION_ADD_FAILURE:
            newState.modalMessage = "Failed to add delegation: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case DELEGATIONS_DELEGATION_UPDATE_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case DELEGATIONS_DELEGATION_UPDATE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Delegation has been successfully updated";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case DELEGATIONS_DELEGATION_UPDATE_FAILURE:
            newState.modalMessage = "Failed to save changes: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case DELEGATIONS_DELEGATION_DELETE_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case DELEGATIONS_DELEGATION_DELETE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Delegation has been successfully deleted";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case DELEGATIONS_DELEGATION_DELETE_FAILURE:
            newState.modalMessage = "Failed to save changes: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case DELEGATIONS_SEARCH_SET_FORCE_REFRESH_FLAG:
            newState.forceRefresh = action.val;
            return newState;
        case DELEGATIONS_MODAL_SET_DELEGATION:
            let delegation = action.modalDelegation;
            if (delegation.start_date) {
                const momentStartDate = moment(delegation.start_date);
                delegation.start_date = momentStartDate;
            }
            if (delegation.end_date) {
                const momentEndDate = moment(delegation.end_date);
                delegation.end_date = momentEndDate;
            }
            newState.modalDelegation = delegation;
            return newState;
        case DELEGATIONS_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === "update") {
                newState.modalMessage = null;
                newState.saved = false;
                newState.modalHeader = "Update Delegation";
            } else if (action.modalAction === "add") {
                newState.modalMessage = null;
                newState.saved = false;
                newState.modalHeader = "Add Delegation";
            } else if (action.modalAction === "delete") {
                newState.modalMessage = "Are you sure you want to delete this Delegation?";
                newState.saved = false;
                newState.modalHeader = "Delete Delegation";
            }
            return newState;
        case DELEGATIONS_MODAL_CHANGE_VALUE:
            newState.modalDelegation = Object.assign({}, state.modalDelegation);
            newState.modalDelegation[action.key] = action.value
            return newState;
        default:
            return state;
    }
}
