import React from 'react';
import { connect } from 'react-redux';

import '../../scss/elements/jumbotron-1.scss';

const Jumbotron1 = ({ jumbotronText, view }) => (
    <div className="jumbotron jumbotron-1">
        <div className="container-fluid">
            <div className="row justify-content-between">
                <div className="col">
                    <h4 className="title text-center text-uppercase text-inception text-muted">
                        <strong>{view || jumbotronText}</strong>
                    </h4>
                </div>
            </div>
        </div>
    </div>
);

const mapStateToProps = (state, ownProps) => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Jumbotron1);
