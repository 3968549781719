import {
    CYDER_PROFILE_CHANGE_VALUE,
    CYDER_PROFILE_SAVE_CHANGES_REQUEST,
    CYDER_PROFILE_SAVE_CHANGES_SUCCESS,
    CYDER_PROFILE_SAVE_CHANGES_FAILURE,
    CYDER_PROFILE_SAVE_PROFILEPIC_REQUEST,
    CYDER_PROFILE_SAVE_PROFILEPIC_SUCCESS,
    CYDER_PROFILE_SAVE_PROFILEPIC_FAILURE,
    CYDER_PROFILE_MODAL_TOGGLE,
    CYDER_DEV_CACHE_CURRENT_PROFILE,
    CYDER_DEV_REMOVE_CACHED_PROFILE,
    CYDER_DEV_CACHE_FAKE_PROFILE,
    CYDER_DEV_RECHECK_PROFILE,
    CYDER_PROFILE_FORCE_VALIDATE,
} from '../constants/actionTypes';
import withQuery from 'with-query';

import { CYDER_COMMON_API_ROOT } from '../../config';

export function changeFieldValueAction(key, value) {
    return {
        type: CYDER_PROFILE_CHANGE_VALUE,
        key,
        value,
    };
}

export function cyderDevCacheCurrentProfile() {
    return {
        type: CYDER_DEV_CACHE_CURRENT_PROFILE,
    };
}
export function cyderDevRemoveCachedProfile() {
    return {
        type: CYDER_DEV_REMOVE_CACHED_PROFILE,
    };
}
export function cyderDevCacheFakeProfile() {
    return {
        type: CYDER_DEV_CACHE_FAKE_PROFILE,
    };
}
export function cyderDevRecheckProfile() {
    return {
        type: CYDER_DEV_RECHECK_PROFILE,
    };
}

export function profileSaveAction() {
    return function(dispatch, getState) {
        const setSavingStateAction = {
            type: CYDER_PROFILE_SAVE_CHANGES_REQUEST,
        };
        let body = getState().cyderProfileReducer.profile;
        if (body.firstname_lower) delete body.firstname_lower;
        if (body.lastname_lower) delete body.lastname_lower;
        if (body.username_lower) delete body.username_lower;
        dispatch(setSavingStateAction);
        const url = withQuery(CYDER_COMMON_API_ROOT + '/users', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'update',
        });
        const options = {
            headers: {
                'x-api-key': getState().config.apiKeyCyder,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(body),
            method: 'POST',
        };
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: CYDER_PROFILE_SAVE_CHANGES_FAILURE, error });
                        return error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_PROFILE_SAVE_CHANGES_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_PROFILE_SAVE_CHANGES_FAILURE, error });
                return error;
            });
    };
}

export function profilePicSaveAction(b64img) {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_PROFILE_SAVE_PROFILEPIC_REQUEST });
        const url = withQuery(CYDER_COMMON_API_ROOT + '/users', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'updatepicture',
        });
        const body = {
            username: getState().cyderProfileReducer.profile.username,
            profile_picture: b64img,
        };
        const options = {
            headers: {
                'x-api-key': getState().config.apiKeyCyder,
                'content-type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(body),
            method: 'POST',
        };
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(res => {
                        // error
                        dispatch({ type: CYDER_PROFILE_SAVE_PROFILEPIC_FAILURE, response: res });
                        return res;
                    });
                } else {
                    return response.json().then(res => {
                        dispatch({ type: CYDER_PROFILE_SAVE_PROFILEPIC_SUCCESS, response: res });
                        return res;
                    });
                }
            })
            .catch(error => {
                // error
                dispatch({ type: CYDER_PROFILE_SAVE_PROFILEPIC_FAILURE, response: error });
                return error;
            });
    };
}
export function cyderProfileModalToggle(reset) {
    return {
        type: CYDER_PROFILE_MODAL_TOGGLE,
        reset,
    };
}
export function forceValidate() {
    return dispatch => {
        dispatch({ type: CYDER_PROFILE_FORCE_VALIDATE });
        // return this for async actions
        return Promise.resolve();
    };
}
