import {
    CYDER_EXPENSE_MYREPORTS_GET_REPORTS_REQUEST,
    CYDER_EXPENSE_MYREPORTS_GET_REPORTS_SUCCESS,
    CYDER_EXPENSE_MYREPORTS_GET_REPORTS_FAILURE,
    CYDER_EXPENSE_MYREPORTS_MODAL_TOGGLE,
} from '../../actions/constants/actionTypesReports' 

// defaults
var defaultState = {
    selectedItems: {},
    selectedAll: false,
    reports: [],
    loading: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
}

export function cyderExpenseMyReportsReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    let newSelectedItems = {};
    switch (action.type) {
        case CYDER_EXPENSE_MYREPORTS_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === "save") {
                newState.modalMessage = "Are you sure you want to save this receipt?";
                newState.saved = false;
            } else if (action.modalAction === "delete") {
                newState.modalMessage = "Are you sure you want to delete this receipt?";
                newState.receiptIdForDeletion = action.data.receiptId
                newState.saved = false;
            }
            return newState;
        case CYDER_EXPENSE_MYREPORTS_GET_REPORTS_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_EXPENSE_MYREPORTS_GET_REPORTS_SUCCESS:
            newState.loading = false;
            newState.reports = action.response;

            action.response.forEach((each) => {
                newSelectedItems[each.id] = false;
            });
            newState.selectedItems = newSelectedItems;
            return newState;
        case CYDER_EXPENSE_MYREPORTS_GET_REPORTS_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.reports = [];
            return newState;
        default:
            return state;
    }
}
