import history from './history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';

import rootReducer from './reducers/index';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const middleware = routerMiddleware(history);
export default function configureStore() {
    const config = {
        key: 'root',
        whitelist: ['cyderProfileReducer', 'cyderLoginReducer', 'mycorpReducer'],
        storage,
    };

    const combinedReducer = persistReducer(config, rootReducer);

    const actionSanitizer = action => {
        return action;
    };

    const stateSanitizer = state => {
        return state;
    };

    const composeEnhancers =
        typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  actionSanitizer,
                  stateSanitizer,
              })
            : compose;

    const store = createStore(combinedReducer, {}, composeEnhancers(applyMiddleware(middleware, thunk)));

    // Enable Webpack hot module replacement for reducers
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }

    const persistor = persistStore(store);
    return { store, persistor };
}
