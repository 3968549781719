import {
    CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_REQUEST,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_SUCCESS,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_FAILURE,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_TOGGLE,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_SET_EXPENSE_CATEGORY,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_CHANGE_VALUE,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_ADD_REQUEST,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_ADD_SUCCESS,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_ADD_FAILURE,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_UPDATE_REQUEST,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_UPDATE_SUCCESS,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_UPDATE_FAILURE,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_DELETE_REQUEST,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_DELETE_SUCCESS,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_DELETE_FAILURE,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_ADD_APPROVER,
    CYDER_EXPENSE_CATEGORIES_SETTINGS_REMOVE_APPROVER,
} from '../../actions/constants/actionTypes' 

// defaults
var defaultState = {
    modalExpenseCategory: {
        id: "",
        code: "",
        name: "",
        approvalvotescount: 0,
        approvers: [],
    },
    saving: false,
    saved: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: "",
    modalHeader: "",
    loading: false,
    expensecategories: [],
    forceRefresh: false,
    approversReference: {},
}

export function cyderExpenseCategoriesSettingsSearchReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    let indexOfUserToRemove;
    let approversReference = {};
    let obj = {};
    switch (action.type) {
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_SUCCESS:
            newState.loading = false;
            newState.expensecategories = action.response;
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.expensecategories = [];
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_ADD_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_ADD_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Expense Category has been successfully added";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_ADD_FAILURE:
            newState.modalMessage = "Failed to add project: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_UPDATE_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_UPDATE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Expense Category has been successfully updated";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_UPDATE_FAILURE:
            newState.modalMessage = "Failed to save changes: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_DELETE_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_DELETE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Expense Category has been successfully deleted";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_DELETE_FAILURE:
            newState.modalMessage = "Failed to save changes: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG:
            newState.forceRefresh = action.val;
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_SET_EXPENSE_CATEGORY:
            newState.modalExpenseCategory = {
                id: action.id,
                code: action.code,
                name: action.name,
                approvalvotescount: action.approvalvotescount,
                approvers: action.approvers,
            }
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === "update") {
                newState.modalMessage = null;
                newState.saved = false;
                newState.modalHeader = "Update Expense Category";
            } else if (action.modalAction === "add") {
                newState.modalMessage = null;
                newState.saved = false;
                newState.modalHeader = "Add Expense Category";
            } else if (action.modalAction === "delete") {
                newState.modalMessage = "Are you sure you want to delete this Expense Category?";
                newState.saved = false;
                newState.modalHeader = "Delete Expense Category";
            }
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_CHANGE_VALUE:
            newState.modalExpenseCategory = Object.assign({}, state.modalExpenseCategory);
            newState.modalExpenseCategory[action.key] = action.value
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_ADD_APPROVER:
            obj[action.username] = true;
            approversReference = Object.assign(obj, state.approversReference);
            approversReference[action.username] = true;
            newState.modalExpenseCategory = Object.assign({}, state.modalExpenseCategory);
            var approvers;
            if (state.modalExpenseCategory.approvers) {
                approvers = state.modalExpenseCategory.approvers.concat(action.username);
            } else {
                approvers = [action.username];
            }
            newState.modalExpenseCategory.approvers = approvers;
            newState.approversReference = approversReference;
            newState.changesMade = true;
            return newState;
        case CYDER_EXPENSE_CATEGORIES_SETTINGS_REMOVE_APPROVER:
            indexOfUserToRemove = state.modalExpenseCategory.approvers.indexOf(action.username);
            approversReference = Object.assign({}, state.approversReference);
            approversReference[action.username] = false;
            newState.approversReference = Object.assign({}, approversReference);
            newState.modalExpenseCategory.approvers = state.modalExpenseCategory.approvers.slice(0, indexOfUserToRemove).concat(state.modalExpenseCategory.approvers.slice(indexOfUserToRemove+1));
            newState.changesMade = true;
            return newState;
        default:
            return state;
    }
}
