import {
    CYDER_REPORT_EXPENSE_RECEIPT_BY_CATEGORY,
    CYDER_REPORT_EXPENSE_MILEAGE_BY_CATEGORY,
    CYDER_REPORT_EXPENSE_RECEIPT_BY_PROJECT,
    CYDER_REPORT_EXPENSE_MILEAGE_BY_PROJECT,
    CYDER_REPORT_EXPENSE_LOADING_SHOW,
    CYDER_REPORT_EXPENSE_LOADING_HIDE,
    CYDER_REPORT_EXPENSE_START_DATE_CHANGED,
    CYDER_REPORT_EXPENSE_END_DATE_CHANGED,
    CYDER_REPORT_EXPENSE_STATUS_CHANGED,
    CYDER_REPORT_EXPENSE_RESET_FORM
} from '../../actions/constants/actionTypes'

// defaults
var reportExpenseDefaultState = {
    loading: false,
    search: {
        startDate: '',
        endDate: '',
        status: {
            disabled: false,
            searchable: true,
            selectValue: 'Approved',
            clearable: true
        }
    },
    receiptByCategory: [],
    receiptByProject: [],
    mileageByCategory: [],
    mileageByProject: []
}

export function cyderReportExpenseReducer(state = reportExpenseDefaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case CYDER_REPORT_EXPENSE_RECEIPT_BY_CATEGORY:
            newState.receiptByCategory = action.report;
            return newState;
        case CYDER_REPORT_EXPENSE_MILEAGE_BY_CATEGORY:
            newState.mileageByCategory = action.report;
            return newState;
        case CYDER_REPORT_EXPENSE_RECEIPT_BY_PROJECT:
            newState.receiptByProject = action.report;
            return newState;
        case CYDER_REPORT_EXPENSE_MILEAGE_BY_PROJECT:
            newState.mileageByProject = action.report;
            return newState;
        case CYDER_REPORT_EXPENSE_LOADING_SHOW:
            newState.loading = true;
            return newState;
        case CYDER_REPORT_EXPENSE_LOADING_HIDE:
            newState.loading = false;
            return newState;
        case CYDER_REPORT_EXPENSE_START_DATE_CHANGED:
            newState.search = Object.assign({}, newState.search);
            newState.search.startDate = action.startDate;
            return newState;
        case CYDER_REPORT_EXPENSE_END_DATE_CHANGED:
            newState.search = Object.assign({}, newState.search);
            newState.search.endDate = action.endDate;
            return newState;
        case CYDER_REPORT_EXPENSE_STATUS_CHANGED:
            newState.search = Object.assign({}, newState.search);
            newState.search.status = Object.assign({}, newState.search.status);
            newState.search.status.selectValue = action.status;
            return newState;
        case CYDER_REPORT_EXPENSE_RESET_FORM:
            newState.search = Object.assign({}, newState.search);
            newState.search.status = Object.assign({}, newState.search.status);
            newState.search.endDate = '';
            newState.search.startDate = '';
            newState.search.status.selectValue = 'Approved';
            return newState;
        default:
            return state;
    }
}