import {
    CYDER_EXPENSE_NEW_MILEAGE_MODAL_TOGGLE,
    CYDER_EXPENSE_NEW_MILEAGE_CHANGE_FIELD,
    CYDER_EXPENSE_NEW_MILEAGE_SET_FIELDS,
    CYDER_EXPENSE_NEW_MILEAGE_RESET_FIELDS,
    CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_REQUEST,
    CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_SUCCESS,
    CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_FAILURE,
    CYDER_EXPENSE_NEW_MILEAGE_FORCE_LOADING_STATE,
    CYDER_EXPENSE_NEW_MILEAGE_SAVE_REQUEST,
    CYDER_EXPENSE_NEW_MILEAGE_SAVE_SUCCESS,
    CYDER_EXPENSE_NEW_MILEAGE_SAVE_FAILURE,
} from '../../actions/constants/actionTypes';
import moment from 'moment';

// import cyderlib from '../../js/cyderlib';

var defaultState = {
    loading: false,
    saving: false,
    saved: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
    allLoaded: false,
    mileageFields: {
        expensedate: moment().format('DD/MM/YYYY'),
        reimbursable: true,
    },
};

export function cyderExpenseNewMileageClaimReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case CYDER_EXPENSE_NEW_MILEAGE_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === 'save') {
                newState.modalMessage = 'Are you sure you want to save this mileage expense?';
                newState.saved = false;
            } else if (action.modalAction === 'delete') {
                newState.modalMessage = 'Are you sure you want to delete this mileage expense?';
                newState.saved = false;
            }
            return newState;
        case CYDER_EXPENSE_NEW_MILEAGE_CHANGE_FIELD:
            newState.mileageFields = Object.assign({}, state.mileageFields);
            newState.mileageFields[action.key] = action.value;
            return newState;
        case CYDER_EXPENSE_NEW_MILEAGE_SAVE_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_EXPENSE_NEW_MILEAGE_SAVE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : 'Mileage Successfully saved';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'redirect';
            return newState;
        case CYDER_EXPENSE_NEW_MILEAGE_SAVE_FAILURE:
            newState.modalMessage = 'Failed to save mileage: ' + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_EXPENSE_NEW_MILEAGE_RESET_FIELDS:
            newState.mileageFields = Object.assign({}, defaultState.mileageFields);
            return newState;
        case CYDER_EXPENSE_NEW_MILEAGE_SET_FIELDS:
            newState.mileageFields = action.mileageFields;
            return newState;
        case CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : 'Mileage successfully deleted';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'redirect';
            return newState;
        case CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_FAILURE:
            newState.modalMessage = 'Failed to delete mileage expense : ' + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            newState.modalOpen = true;
            return newState;
        case CYDER_EXPENSE_NEW_MILEAGE_FORCE_LOADING_STATE:
            newState.loading = action.loading;
            return newState;
        default:
            return state;
    }
}
