import {
    CYDER_LOGIN_REQUEST,
    CYDER_LOGIN_SUCCESS,
    CYDER_LOGIN_FAILURE,
    CYDER_LOGIN_SUCCESS_SET_PROFILE,
    CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE,
    CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE,
    CYDER_CHANGE_PASSWORD_REQUEST,
    CYDER_CHANGE_PASSWORD_SUCCESS,
    CYDER_CHANGE_PASSWORD_FAILURE,
    CYDER_CHANGE_PASSWORD_PAGE_CLEAR_MESSAGE,
    CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE,
    CYDER_LOGIN_PAGE_RESET_STATE,
    CYDER_CHANGE_PASSWORD_RESET_STATE,
    CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE,
    CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG,
    CYDER_FORGOT_PASSWORD_REQUEST,
    CYDER_FORGOT_PASSWORD_SUCCESS,
    CYDER_FORGOT_PASSWORD_FAILURE,
    CYDER_FORGOT_PASSWORD_PAGE_SET_MESSAGE,
    CYDER_CHANGE_PASSWORD_GET_INFO_REQUEST,
    CYDER_CHANGE_PASSWORD_GET_INFO_SUCCESS,
    CYDER_CHANGE_PASSWORD_GET_INFO_FAILURE,
    CYDER_LOGIN_SUCCESS_REQUIRE_TFA,
    CYDER_LOGIN_PAGE_SUBMIT_OTP_REQUEST,
    CYDER_LOGIN_PAGE_SUBMIT_OTP_FAILURE,
} from '../constants/actionTypes';
import withQuery from 'with-query';

import { CYDER_COMMON_API_ROOT } from '../../config';

/**
 * Login function
 *
 * @param {*} fields
 * @param {*} callback
 */
export function cyderLoginAction(data) {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_LOGIN_REQUEST });
        const url = CYDER_COMMON_API_ROOT + '/auth?appid=s&action=auth-v2';

        const options = {
            headers: {
                'x-api-key': getState().config.apiKeyCyder,
                'content-type': 'application/json',
                Authorization: 'notrequired',
            },
            body: JSON.stringify(data),
            method: 'POST',
        };
        // request(url, options);

        //TODO: refine handling
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        throw error;
                    });
                } else {
                    return response.json().then(res => {
                        // store user info
                        if (res.tfatoken) {
                            dispatch({ type: CYDER_LOGIN_SUCCESS_REQUIRE_TFA, tfatoken: res.tfatoken });
                        } else {
                            dispatch({ type: CYDER_LOGIN_SUCCESS_SET_PROFILE, userData: res });
                            dispatch({ type: CYDER_LOGIN_SUCCESS, userData: res });
                        }
                        return res;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_LOGIN_FAILURE, error });
                return error;
            });
    };
}

export function cyderLoginTokenAction(data) {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_LOGIN_REQUEST });
        const url = CYDER_COMMON_API_ROOT + '/auth?appid=s&action=auth-token';

        const options = {
            headers: {
                'x-api-key': getState().config.apiKeyCyder,
                'content-type': 'application/json',
                Authorization: 'notrequired',
            },
            body: JSON.stringify(data),
            method: 'POST',
        };
        //TODO: refine handling
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        throw error;
                    });
                } else {
                    return response.json().then(res => {
                        dispatch({ type: CYDER_LOGIN_SUCCESS_SET_PROFILE, userData: res });
                        dispatch({ type: CYDER_LOGIN_SUCCESS, userData: res });
                        return res;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_LOGIN_FAILURE, error });
                return error;
            });
    };
}
export function cyderLoginSubmitOTPAction(token) {
    // Redux Thunk will inject dispatch here:
    return (dispatch, getState) => {
        dispatch({ type: CYDER_LOGIN_PAGE_SUBMIT_OTP_REQUEST });
        const url = withQuery(CYDER_COMMON_API_ROOT + '/auth', {
            appid: 'notused',
            action: 'token',
        });
        const data = {
            token,
            tfatoken: getState().cyderLoginReducer.tfatoken,
            system: 'djarvis',
        };
        // fix for select defaults
        const options = {
            headers: {
                'x-api-key': getState().config.apiKeyCyder,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(data),
            method: 'POST',
        };

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        throw error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_LOGIN_SUCCESS_SET_PROFILE, userData: response });
                        dispatch({ type: CYDER_LOGIN_SUCCESS, userData: response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_LOGIN_PAGE_SUBMIT_OTP_FAILURE, error });
                return error;
            });
    };
}
export function cyderForgotPasswordAction(email) {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_FORGOT_PASSWORD_REQUEST });
        const url = CYDER_COMMON_API_ROOT + '/auth?appid=s&action=forgotpassword';
        const data = {
            emailaddress: email,
            system: 'djarvis',
        };
        const options = {
            headers: {
                'x-api-key': getState().config.apiKeyCyder,
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
            method: 'POST',
        };
        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        throw error;
                    });
                } else {
                    return response.json().then(res => {
                        dispatch({ type: CYDER_FORGOT_PASSWORD_SUCCESS, userData: res });
                        return res;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_FORGOT_PASSWORD_FAILURE, error });
                return error;
            });
    };
}
export function cyderForgotPasswordPageSetMessageAction(message) {
    return {
        type: CYDER_FORGOT_PASSWORD_PAGE_SET_MESSAGE,
        message,
    };
}
export function cyderChangePasswordAction(data) {
    return (dispatch, getState) => {
        const { appId, username, authorizationToken } = getState().cyderProfileReducer.profile;
        const { apiKeyCyder } = getState().config;
        const dataWithUsername = {
            ...data,
            username,
        };

        dispatch({ type: CYDER_CHANGE_PASSWORD_REQUEST });
        const url = withQuery(`${CYDER_COMMON_API_ROOT}/users`, {
            appid: appId,
            action: 'changepassword',
        });

        const options = {
            headers: {
                'x-api-key': apiKeyCyder,
                'content-type': 'application/json',
                Authorization: authorizationToken,
            },
            body: JSON.stringify(dataWithUsername),
            method: 'POST',
        };

        fetch(url, options)
            .then(async response => {
                const res = await response.json();

                // Error
                if (res.errdescription) {
                    dispatch({
                        type: CYDER_CHANGE_PASSWORD_FAILURE,
                        error: res,
                    });
                    return;
                }

                dispatch({ type: CYDER_CHANGE_PASSWORD_SUCCESS, userData: res });
                return res;
            })
            .catch(() => {
                const error = 'Failed to change password. Please try again or contact adminstrator.';
                dispatch({
                    type: CYDER_CHANGE_PASSWORD_FAILURE,
                    error,
                });
                return error;
            });
    };
}
export function cyderChangePasswordNoUserAction(data) {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_CHANGE_PASSWORD_REQUEST });
        const url = withQuery(CYDER_COMMON_API_ROOT + '/auth', {
            appid: 's',
            action: 'resetpassword',
        });

        const options = {
            headers: {
                'x-api-key': getState().config.apiKeyCyder,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            method: 'POST',
        };

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        throw error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_CHANGE_PASSWORD_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_CHANGE_PASSWORD_FAILURE, error });
                return error;
            });
    };
}

export function cyderGetResetPasswordInfoAction(forgotpasswordtoken) {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_CHANGE_PASSWORD_GET_INFO_REQUEST });
        const url = withQuery(CYDER_COMMON_API_ROOT + '/auth', {
            appid: 's',
            action: 'resetpasswordinfo',
        });

        const data = {
            forgotpasswordtoken,
        };

        const options = {
            headers: {
                'x-api-key': getState().config.apiKeyCyder,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            method: 'POST',
        };

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        throw error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_CHANGE_PASSWORD_GET_INFO_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_CHANGE_PASSWORD_GET_INFO_FAILURE, error });
                return error;
            });
    };
}

export function cyderLoginSetErrorMessageAction(errorMessage) {
    return {
        type: CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE,
        errorMessage,
    };
}

export function cyderLoginClearErrorMessageAction() {
    return {
        type: CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE,
    };
}
export function cyderChangePasswordPageSetMessageAction(message) {
    return {
        type: CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE,
        message,
    };
}
export function cyderChangePasswordPageClearMessageAction() {
    return {
        type: CYDER_CHANGE_PASSWORD_PAGE_CLEAR_MESSAGE,
    };
}

export function cyderLogoutAction() {
    return function(dispatch) {
        // call api to invalidate tokens
        // on success, remove profile from user
        dispatch({ type: CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE });
        dispatch({ type: CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG });
        return Promise.resolve();
    };
}

export function cyderResetProfileAction() {
    return { type: CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE };
}
export function cyderLoginResetState() {
    return {
        type: CYDER_LOGIN_PAGE_RESET_STATE,
    };
}
export function cyderChangePasswordResetState() {
    return {
        type: CYDER_CHANGE_PASSWORD_RESET_STATE,
    };
}
