import { CYDER_API_KEY, DJARVIS_API_KEY, DJARVIS_WEBSITE } from '../config';

const configState = {
    djarvisWebsite: DJARVIS_WEBSITE,
    apiKey: DJARVIS_API_KEY,
    apiKeyCyder: CYDER_API_KEY,
    appId: 'CYDERINTRANET',
    layout: 'default-sidebar-1',
    background: 'light',
    navbar: 'light',
    topNavigation: 'light',
    logo: 'dark',
    leftSidebar: 'dark',
    leftSidebarIcons: 'dark',
    rightSidebar: false,
    collapsed: false,
    momentDateFormat: 'DD/MM/YYYY',
    momentDateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
    navigationMenu: 'expenseNavigation',
};

export function config(state = configState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'SET_CONFIG':
            return Object.assign({}, state, {
                ...action.config,
            });
        case 'SWITCH_NAV_MENU':
            newState.navigationMenu = action.navType;
            return newState;
        default:
            return state;
    }
}
