const urlRecurring = `/${window.location.pathname.indexOf('mycorp') >= 0 ? 'mycorp' : 'expense'}/recurring`;
const urlReceipt = `/${window.location.pathname.indexOf('mycorp') >= 0 ? 'mycorp' : 'expense'}/myreceipts`;

const expenseDeletableStatuses = {
    Ready: true,
    Attention: true,
    Active: true,
};

const expenseSubmitableStatuses = {
    ready: true,
    returned: true,
    autoreceipt: true,
};

const layoutLib = {
    mobileWidth: 576,
};
export { urlReceipt, urlRecurring, expenseDeletableStatuses, expenseSubmitableStatuses, layoutLib };
