// Expenses (new)
export const PAYMENTS_LIST_REQUEST = 'PAYMENTS_LIST_REQUEST';
export const PAYMENTS_LIST_SUCCESS = 'PAYMENTS_LIST_SUCCESS';
export const PAYMENTS_LIST_FAILURE = 'PAYMENTS_LIST_FAILURE';

export const PAYMENTS_LIST_TOGGLE_CHECKBOX = 'PAYMENTS_LIST_TOGGLE_CHECKBOX';
export const PAYMENTS_LIST_TOGGLE_CHECKBOX_ALL = 'PAYMENTS_LIST_TOGGLE_CHECKBOX_ALL';

export const PAYMENTS_LIST_GET_EXPENSES_REQUEST = 'PAYMENTS_LIST_GET_EXPENSES_REQUEST';
export const PAYMENTS_LIST_GET_EXPENSES_SUCCESS = 'PAYMENTS_LIST_GET_EXPENSES_SUCCESS';
export const PAYMENTS_LIST_GET_EXPENSES_FAILURE = 'PAYMENTS_LIST_GET_EXPENSES_FAILURE';

export const PAYMENTS_LIST_GET_REPORTS_REQUEST = 'PAYMENTS_LIST_GET_REPORTS_REQUEST';
export const PAYMENTS_LIST_GET_REPORTS_SUCCESS = 'PAYMENTS_LIST_GET_REPORTS_SUCCESS';
export const PAYMENTS_LIST_GET_REPORTS_FAILURE = 'PAYMENTS_LIST_GET_REPORTS_FAILURE';

export const PAYMENTS_LIST_DELETE_RECEIPT_REQUEST = 'PAYMENTS_LIST_DELETE_RECEIPT_REQUEST';
export const PAYMENTS_LIST_DELETE_RECEIPT_SUCCESS = 'PAYMENTS_LIST_DELETE_RECEIPT_SUCCESS';
export const PAYMENTS_LIST_DELETE_RECEIPT_FAILURE = 'PAYMENTS_LIST_DELETE_RECEIPT_FAILURE';

export const PAYMENTS_LIST_MODAL_TOGGLE = 'PAYMENTS_LIST_MODAL_TOGGLE';
export const PAYMENTS_LIST_COPY_RECEIPT = 'PAYMENTS_LIST_COPY_RECEIPT';

