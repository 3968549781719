import moment from 'moment';

export function isPlural(string, number) {
    if (string === 'is') {
        if (number > 1) return 'are';
        else return string;
    }

    if (string === 'has') {
        if (number > 1) return 'have';
        else return string;
    }

    if (number > 1) return `${string}s`;
    return string;
}

// Capitalize a string
export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

// Get month options for react-select options
export function getMonthOptions() {
    const months = new Array(12).fill(' ').map((x, index) => {
        const month = index + 1;
        return {
            value: month,
            label: month,
        };
    });
    return [{ value: null, label: 'None' }, ...months];
}

// Get year options for react-select options
export function getYearOptions(minYear) {
    const min = minYear || 2017;
    const max = moment().year() + 2;
    const years = new Array(max - min).fill(' ').map((x, index) => {
        const year = min + index;
        return {
            value: year,
            label: year,
        };
    });
    return [{ value: null, label: 'None' }, ...years];
}

// Check is date is future
export function isFutureDate(date) {
    const today = moment().format('YYYY-MM-DD');
    const from = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    return moment(from).isAfter(today);
}

// Open file using href
export function openFile(url, filename) {
    window.open(url);
    let link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
}

export function convertDateFormat(original, fromFormat, toFormat) {
    return moment(original, fromFormat).format(toFormat);
}

export function calNumOfDays(from, to, holidays, includeWeekends) {
    if (!from || !to) return '';
    const fromDateString = from.format('YYYY-MM-DD');
    const toDateString = to.format('YYYY-MM-DD');

    const weekendDays = getWeekends(fromDateString, toDateString);

    var totalNumOfDays = to.diff(from, 'days') + 2;
    var numOfDays = totalNumOfDays;
    var holidaysList = [];
    var weekendsList = [];

    // DEDUCT HOLIDAYS
    if (holidays) {
        holidays.forEach(holiday => {
            const momentDate = moment(holiday.holidaydate, 'YYYY-MM-DD');
            const isHoliday = momentDate.isBetween(fromDateString, toDateString, null, '[]');

            if (isHoliday && momentDate.isoWeekday() !== 6 && momentDate.isoWeekday() !== 7) {
                holidaysList.push({ ...holiday, deductedOnDate: momentDate.format('YYYY-MM-DD') });
                numOfDays--;
            }

            if (momentDate.isoWeekday() === 6 || momentDate.isoWeekday() === 7) {
                weekendsList.push({ date: momentDate.format('YYYY-MM-DD') });
            }
        });
    }

    // IMPORTANT
    const isSameDate = fromDateString === toDateString;
    numOfDays = isSameDate ? 1 - holidaysList.length : numOfDays;
    totalNumOfDays = isSameDate ? numOfDays : totalNumOfDays;
    let deductedNumOfDays = isSameDate ? 1 - numOfDays : totalNumOfDays - numOfDays;

    // DEDUCT WEEKENDS
    if (includeWeekends) {
        numOfDays -= weekendDays || 0;
        deductedNumOfDays += weekendDays;
    }

    return [
        numOfDays,
        totalNumOfDays,
        {
            deductedNumOfDays,
            weekendDays: includeWeekends ? weekendDays : 0,
            holidays: holidaysList.length,
        },
        { weekendsList, holidaysList },
    ];
}

export function getWeekends(fromDate, toDate) {
    // Clone dates
    fromDate = moment(fromDate);
    toDate = moment(toDate).add(1, 'days');

    let days = 0;
    while (!fromDate.isSame(toDate)) {
        if (fromDate.isoWeekday() === 6 || fromDate.isoWeekday() === 7) days += 1;
        fromDate = fromDate.add(1, 'days');
    }

    return days;
}

export function randDarkColor() {
    var lum = -0.25;
    var hex = String(
        '#' +
            Math.random()
                .toString(16)
                .slice(2, 8)
                .toUpperCase(),
    ).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    var rgb = '#',
        c,
        i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
    }
    return rgb;
}
