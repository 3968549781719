/**
 * Multi environment confirmation
 *
 * Author: Fernando Karnagi
 * Reference: https://daveceddia.com/multiple-environments-with-react/
 */

const dev = {
    CYDER_API_ROOT: 'https://api-dev.cyder.com.sg',
    CYDER_API_KEY: 'yKVymmIP8IaBumZkBTc4R2iHLq6Ovmqu2MQwwit0',
    DJARVIS_API_KEY: '4Qy0OvHlvX61bzqPSfu2r8FUBIKmZj4y7qAgdqrS',
    DJARVIS_WEBSITE: 'https://www-dev.djarvis.page/',
    DJARVIS_REPORT_API_ROOT: 'https://report.api.djarvis.cyder.com.sg',
    DJARVIS_EXPENSE_API_ROOT: 'https://expense.api.djarvis.cyder.com.sg',
    DJARVIS_COMMON_API_ROOT: 'https://common.api.djarvis.cyder.com.sg',
    CYDER_COMMON_API_ROOT: 'https://common.api.cyder.com.sg',
    CYDER_LEAVE_API_ROOT: 'https://leave.api.djarvis.cyder.com.sg',
};

const prod = {
    CYDER_API_ROOT: 'https://api.cyder.com.sg',
    CYDER_API_KEY: 'OX0CR7p8Zd6ayqyqGI8UJ7qr8XaSDsSf6WKovUp6',
    DJARVIS_API_KEY: 'SvM6LHsK7P4sZvzFgDUZN6qUdqmWjLUy1S2OSc4j',
    DJARVIS_WEBSITE: 'https://www.djarvis.page/',
    DJARVIS_REPORT_API_ROOT: 'https://report.api.prod.djarvis.cyder.com.sg',
    DJARVIS_EXPENSE_API_ROOT: 'https://expense.api.prod.djarvis.cyder.com.sg',
    DJARVIS_COMMON_API_ROOT: 'https://common.api.prod.djarvis.cyder.com.sg',
    CYDER_COMMON_API_ROOT: 'https://common.api.prod.cyder.com.sg',
    CYDER_LEAVE_API_ROOT: 'https://leave.api.prod.djarvis.cyder.com.sg',
};

const hostname = window && window.location && window.location.hostname;
const isProd = hostname === 'app.djarvis.page' || hostname === 'app.djarv.is';
const isDev = hostname === 'app-dev.djarvis.page' || hostname === 'app-dev.djarv.is' || hostname === 'localhost';
// const config = isProd ? prod : isDev && dev; // Dev environment has been decomm
const config = prod;

export const {
    CYDER_API_ROOT,
    CYDER_API_KEY,
    DJARVIS_API_KEY,
    DJARVIS_WEBSITE,
    DJARVIS_REPORT_API_ROOT,
    DJARVIS_EXPENSE_API_ROOT,
    DJARVIS_COMMON_API_ROOT,
    CYDER_COMMON_API_ROOT,
    CYDER_LEAVE_API_ROOT,
} = config;
