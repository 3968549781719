import { expenseDeletableStatuses, urlRecurring, urlReceipt, layoutLib } from 'js/constlib';
const isMobile = window.innerWidth <= layoutLib.mobileWidth;

const urls = [
    {
        title: 'Concierge',
        show: true,
        accessLevel: [0, 1, 2],
        items: [
            {
                url: '/home',
                icon: 'home',
                title: 'Concierge',
                show: true,
            },
        ],
    },
    {
        title: 'Expenses',
        show: true,
        accessLevel: [0, 1, 2],
        items: [
            {
                url: '',
                icon: 'credit-card',
                title: 'Expenses',
                show: true,
                items: [
                    {
                        url: '/expense/myreceipts',
                        title: 'My Expenses',
                        show: true,
                    },
                    {
                        url: '/expense/myreports',
                        title: 'My Reports',
                        show: true,
                    },
                    {
                        url: '/expense/recurring',
                        title: 'Recurring Charges',
                        show: false,
                    },
                ],
            },
        ],
    },
    {
        title: 'Tasks',
        show: true,
        accessLevel: [0, 1, 2],
        items: [
            {
                url: '/workflow/mytasks',
                icon: 'thumb-tack',
                title: 'Tasks',
                show: true,
            },
        ],
    },
    {
        title: 'Reports',
        show: true,
        accessLevel: [1, 2],
        items: [
            {
                micon: 'chrome_reader_mode',
                title: 'Reports',
                show: true,
                items: [
                    {
                        url: '/report/expense/receiptbycategory',
                        title: 'Receipts By Category',
                        show: true,
                    },
                    {
                        url: '/report/expense/receiptbyproject',
                        title: 'Receipts By Project',
                        show: true,
                    },
                    {
                        url: '/report/expense/mileagebycategory',
                        title: 'Mileages By Category',
                        show: true,
                    },
                    {
                        url: '/report/expense/mileagebyproject',
                        title: 'Mileages By Project',
                        show: true,
                    },
                    {
                        url: '/report/expense/reportsbymonth',
                        title: 'Expense Reports',
                        show: true,
                    },
                ],
            },
        ],
    },
    {
        title: 'Administration',
        show: !isMobile,
        accessLevel: [1, 2],
        items: [
            {
                url: '',
                icon: 'users',
                title: 'User Access Control',
                show: true,
                items: [
                    {
                        url: '/administration/users',
                        icon: '',
                        title: 'Users',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/administration/groups',
                        icon: '',
                        title: 'Groups',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/administration/users/add',
                        icon: '',
                        title: 'Add New User',
                        show: false,
                        items: [],
                    },
                ],
            },
        ],
    },
    {
        title: 'Settings',
        show: !isMobile,
        accessLevel: [1, 2],
        items: [
            {
                url: '/settings/expense/',
                icon: 'cog',
                title: 'Settings',
                show: true,
            },
        ],
    },
    /**
     * Make sure to change Menu.js hasMatched function;
     * Menu.js uses title to identify whether to switch menu navigation
     * */
    {
        title: 'Others',
        show: true,
        items: [
            {
                url: '/leavetype/home',
                micon: 'alarm',
                title: 'Djarvis Leave',
                show: true,
                items: [],
            },
            {
                url: '/profile',
                micon: 'account_circle',
                title: 'Profile',
                show: true,
                items: [],
            },
            {
                action: 'logout',
                micon: 'exit_to_app',
                title: 'Logout',
                show: true,
                items: [],
            },
        ],
    },
];

export function expenseNavigation(state = Array.from(urls), action) {
    switch (action.type) {
        case 'SET_NAVIGATION':
            return Object.assign({}, state);
        default:
            return state;
    }
}
