import {
    CYDER_GROUPS_ADD_CHANGE_FIELD,
    CYDER_GROUPS_ADD_ADD_GROUP_FORCE_VALIDATE,
    CYDER_GROUPS_ADD_ADD_GROUP_REQUEST,
    CYDER_GROUPS_ADD_ADD_GROUP_SUCCESS,
    CYDER_GROUPS_ADD_ADD_GROUP_FAILURE,
    CYDER_GROUPS_ADD_MODAL_TOGGLE,
    CYDER_GROUPS_ADD_CLEAR_FIELDS,
    CYDER_GROUPS_ADD_ADD_USER,
    CYDER_GROUPS_ADD_REMOVE_USER,
} from '../../actions/constants/actionTypes' 

import cyderlib from '../../js/cyderlib';

// defaults
var cyderGroupsAddState = {
    fields: {
        groupname: "",
        groupdescription: "",
    },
    users: [],
    usersReference: {},
    validation: {},
    saveButtonPressed: false,
    modalOpen: false,
    saving: false,
    saved: false,
    messageModalHeader: "Confirmation",
    messageModalMessage: "Are you sure you want to add this group?",
}

export function cyderGroupsAddReducer(state = cyderGroupsAddState, action) {
    let newState = Object.assign({}, state);
    let usersReference;
    switch (action.type) {
    case CYDER_GROUPS_ADD_CHANGE_FIELD:
        newState.fields = Object.assign({}, state.fields);
        newState.validation = Object.assign({}, state.validation);

        newState.fields[action.key] = action.value;

        let pw = null;
        if (action.key === "confirmpassword") {
            pw = state.fields['password'];
        }
        newState.validation[action.key] = cyderlib.validate(action.key, action.value, pw);
        return newState;
    case CYDER_GROUPS_ADD_ADD_GROUP_FORCE_VALIDATE:
        newState.fields = Object.assign({}, state.fields);
        newState.validation = Object.assign({}, state.validation);
        for (var k in state.fields) {
            let pw = null;
            if (k === "confirmpassword") {
                pw = state.fields['password'];
            }
            newState.validation[k] = cyderlib.validate(k, state.fields[k], pw);
        }
        newState.saveButtonPressed = true;
        return newState;
    case CYDER_GROUPS_ADD_ADD_GROUP_REQUEST:
        newState.fields = Object.assign({}, state.fields);
        newState.validation = Object.assign({}, state.validation);
        newState.saving = true;
        return newState;
    case CYDER_GROUPS_ADD_ADD_GROUP_SUCCESS:
        newState.fields = cyderGroupsAddState.fields;
        newState.users = cyderGroupsAddState.users;
        newState.validation = Object.assign({}, state.validation);
        newState.modalOpen = true;
        newState.messageModalHeader = "Success";
        newState.messageModalMessage = action.response.message? action.response.message : "Group Added";
        newState.saving = false;
        newState.saved = true;
        return newState;
    case CYDER_GROUPS_ADD_ADD_GROUP_FAILURE:
        newState.fields = Object.assign({}, state.fields);
        newState.validation = Object.assign({}, state.validation);
        newState.modalOpen = true;
        newState.messageModalHeader = "Error";
        newState.messageModalMessage = action.response.message ? action.response.message : "Error";
        newState.saving = false;
        return newState;
    case CYDER_GROUPS_ADD_MODAL_TOGGLE:
        newState.fields = Object.assign({}, state.fields);
        newState.validation = Object.assign({}, state.validation);
        if (action.reset) {
            newState.messageModalMessage = cyderGroupsAddState.messageModalMessage;
            newState.saved = false;
        }
        newState.modalOpen = !state.modalOpen;
        return newState;
    case CYDER_GROUPS_ADD_CLEAR_FIELDS:
        newState.fields = cyderGroupsAddState.fields;
        newState.validation = cyderGroupsAddState.validation;
        newState.saveButtonPressed = false;
        newState.messageModalMessage = cyderGroupsAddState.messageModalMessage;
        return newState;
    case CYDER_GROUPS_ADD_ADD_USER:
        var users = state.users.concat(action.username);
        var obj = {};
        obj[action.username] = true;
        usersReference = Object.assign(obj, state.usersReference);
        newState.users = users;
        newState.usersReference = usersReference;
        newState.changesMade = true;
        return newState;
    case CYDER_GROUPS_ADD_REMOVE_USER:
        var indexOfUserToRemove = state.users.indexOf(action.username);
        usersReference = Object.assign({}, state.usersReference);
        usersReference[action.username] = false;
        newState.usersReference = Object.assign({}, usersReference);
        newState.users = state.users.slice(0, indexOfUserToRemove).concat(state.users.slice(indexOfUserToRemove+1));
        newState.changesMade = true;
        return newState;
    default:
        return state;
    }
}
