import {
    CYDER_PROFILE_CHANGE_VALUE,
    CYDER_PROFILE_SAVE,
    CYDER_LOGIN_SUCCESS_SET_PROFILE,
    CYDER_PROFILE_SAVE_CHANGES_SUCCESS,
    CYDER_PROFILE_SAVE_CHANGES_FAILURE,
    CYDER_PROFILE_MODAL_TOGGLE,
    // CYDER_GROUPS_DETAIL_SUCCESS,
    // CYDER_GROUPS_DETAIL_FAILURE,
    // CYDER_GROUPS_DETAIL_SAVE_CHANGES_FAILURE,
    CYDER_DEV_CACHE_CURRENT_PROFILE,
    CYDER_DEV_REMOVE_CACHED_PROFILE,
    CYDER_DEV_CACHE_FAKE_PROFILE,
    CYDER_DEV_RECHECK_PROFILE,
    CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE,
    CYDER_PROFILE_FORCE_VALIDATE,
    CYDER_PROFILE_SAVE_PROFILEPIC_REQUEST,
    CYDER_PROFILE_SAVE_PROFILEPIC_SUCCESS,
    CYDER_PROFILE_SAVE_PROFILEPIC_FAILURE,
    CYDER_PROFILE_REFRESH_OVERRIDE_REQUEST,
    CYDER_PROFILE_REFRESH_OVERRIDE_SUCCESS,
    CYDER_PROFILE_REFRESH_OVERRIDE_FAILURE,
} from '../../actions/constants/actionTypes'

import Cookies from 'universal-cookie';
import cyderlib from '../../js/cyderlib';

// defaults
const cookies = new Cookies();

let profileObj = {
    init: true,
    logintime: null,
};
if (cookies.get('profile')) {
    profileObj = cookies.get('profile');
}
var cyderProfileDefaultState = {
    profile: profileObj,
    validation: {},
    saving: false,
    saved: false,
    errorMessage: null,
    modalOpen: false,
    modalMessage: "Are you sure you want to update your profile?",
    savedProfilePic: false,
    loading: false,
}


export function cyderProfileReducer(state = cyderProfileDefaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case CYDER_LOGIN_SUCCESS_SET_PROFILE:
            newState.profile = Object.assign({logintime: new Date().getTime()}, action.userData);
            newState.validation = Object.assign({}, state.validation);
            return newState;
        case CYDER_PROFILE_REFRESH_OVERRIDE_REQUEST:
            newState.loading = true;
            return newState;
        case CYDER_PROFILE_REFRESH_OVERRIDE_SUCCESS:
            newState.profile = Object.assign(state.profile, action.userData);
            newState.validation = Object.assign({}, state.validation);
            newState.loading = false;
            return newState;
        case CYDER_PROFILE_REFRESH_OVERRIDE_FAILURE:
            newState.loading = false;
            return newState;
        case CYDER_PROFILE_CHANGE_VALUE:
            newState.profile = Object.assign({}, state.profile);
            newState.validation = Object.assign({}, state.validation);
            newState.profile[action.key] = action.value;
            newState.validation[action.key] = cyderlib.validate(action.key, action.value);
            return newState;
        case CYDER_PROFILE_SAVE:
            newState.savingSysparam = true;
            return newState;
        case CYDER_PROFILE_SAVE_CHANGES_SUCCESS:
            newState.modalMessage = "Profile Successfully updated";
            newState.saving = false;
            newState.saved = true;
            newState.loading = true;
            return newState;
        case CYDER_PROFILE_SAVE_CHANGES_FAILURE:
            newState.modalMessage = "Failed to update profile: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.loading = false;
            return newState;
        case CYDER_PROFILE_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            if (action.reset) {
                newState.modalMessage = "Are you sure you want to update your profile?";
                newState.saved = false;
            }
            newState.loading = false;
            return newState;
        case CYDER_DEV_CACHE_CURRENT_PROFILE:
            if (state.profile.init) {
                console.log('warning: no real profile cached!');
            }
            cookies.set('profile', Object.assign({cached: true}, state.profile), { path: '/' });
            return state;
        case CYDER_DEV_REMOVE_CACHED_PROFILE:
            cookies.remove('profile');
            return state;
        case CYDER_DEV_CACHE_FAKE_PROFILE:
            const fakeProfile = {
                "idno": "S7962708I",
                "officenumber": "+658888888",
                "mobilenumber": "+6597772291",
                "userstatus": "Active",
                "companyname": "Cyder SG",
                "gender": "Male",
                "lastname": "Karnagi",
                "dateofbirth": "2017/01/21",
                "homeaddress": "Singapore",
                "firstname": "Fernando",
                "username": "fernando@cyder.com.sg",
                "appId": "CYDERINTRANET",
                "companyaddress": "Redhill",
                "homenumber": "+6599999999",
                "id": "453c51ac-e316-4cb4-a598-7066a6b037e6",
                "idtype": "FIN",
                "email2": "yin@cyder.com.sg",
                fake: true,
            }
            cookies.set('profile', fakeProfile, { path: '/' });
            return state;
        case CYDER_DEV_RECHECK_PROFILE:
            if (cookies.get('profile')) {
                newState.profile = Object.assign({}, cookies.get('profile'));
                console.log('setting profile in state to cached profile.');
            } else {
                console.log('no cached profile. using profle from state');
            }
            const defaultObj = {
                saving: cyderProfileDefaultState.saving,
                saved: cyderProfileDefaultState.saved,
                errorMessage: cyderProfileDefaultState.errorMessage,
                modalOpen: cyderProfileDefaultState.modalOpen,
                modalMessage: cyderProfileDefaultState.modalMessage,
                savedProfilePic: cyderProfileDefaultState.savedProfilePic,
                validation: cyderProfileDefaultState.validation,
            }

            newState = Object.assign(state, defaultObj);
            return newState;
        case CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE:
            newState.profile = {};
            return newState;
        case CYDER_PROFILE_FORCE_VALIDATE:
            newState.profile = Object.assign({}, state.profile);
            newState.validation = Object.assign({}, state.validation);
            for (var k in state.profile) {
                newState.validation[k] = cyderlib.validate(k, state.profile[k]);
            }
            newState.saveButtonPressed = true;
            return newState;
        case CYDER_PROFILE_SAVE_PROFILEPIC_REQUEST:
            newState.savingProfilePic = true;
            return newState;
        case CYDER_PROFILE_SAVE_PROFILEPIC_SUCCESS:
            newState.savingProfilePic = false;
            newState.savedProfilePic = true;
            return newState;
        case CYDER_PROFILE_SAVE_PROFILEPIC_FAILURE:
            newState.savingProfilePic = false;
            newState.savedProfilePic = false;
            return newState;
        default:
            return state;
    }
}
