const defaultState = {
    module: 'MyCorp',
};

export default function mycorpReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'MYCORP_SET_MODULE':
            newState.module = action.module;
            return newState;
        default:
            return state;
    }
}
