export const TIMESHEET_CLOCK_CLOCK_GET_CURRENT_ACTIVITY_REQUEST = 'TIMESHEET_CLOCK_CLOCK_GET_CURRENT_ACTIVITY_REQUEST';
export const TIMESHEET_CLOCK_CLOCK_GET_CURRENT_ACTIVITY_SUCCESS = 'TIMESHEET_CLOCK_CLOCK_GET_CURRENT_ACTIVITY_SUCCESS';
export const TIMESHEET_CLOCK_CLOCK_GET_CURRENT_ACTIVITY_FAILURE = 'TIMESHEET_CLOCK_CLOCK_GET_CURRENT_ACTIVITY_FAILURE';

export const TIMESHEET_CLOCK_CLOCK_IN_REQUEST = 'TIMESHEET_CLOCK_CLOCK_IN_REQUEST';
export const TIMESHEET_CLOCK_CLOCK_IN_SUCCESS = 'TIMESHEET_CLOCK_CLOCK_IN_SUCCESS';
export const TIMESHEET_CLOCK_CLOCK_IN_FAILURE = 'TIMESHEET_CLOCK_CLOCK_IN_FAILURE';

export const TIMESHEET_CLOCK_CLOCK_OUT_REQUEST = 'TIMESHEET_CLOCK_CLOCK_OUT_REQUEST';
export const TIMESHEET_CLOCK_CLOCK_OUT_SUCCESS = 'TIMESHEET_CLOCK_CLOCK_OUT_SUCCESS';
export const TIMESHEET_CLOCK_CLOCK_OUT_FAILURE = 'TIMESHEET_CLOCK_CLOCK_OUT_FAILURE';

export const TIMESHEET_CLOCK_FORCE_LOADING = 'TIMESHEET_CLOCK_FORCE_LOADING';

// My Clocks
export const TIMESHEET_MYCLOCKS_GET_PAST_ACTIVITY_REQUEST = 'TIMESHEET_MYCLOCKS_GET_PAST_ACTIVITY_REQUEST';
export const TIMESHEET_MYCLOCKS_GET_PAST_ACTIVITY_SUCCESS = 'TIMESHEET_MYCLOCKS_GET_PAST_ACTIVITY_SUCCESS';
export const TIMESHEET_MYCLOCKS_GET_PAST_ACTIVITY_FAILURE = 'TIMESHEET_MYCLOCKS_GET_PAST_ACTIVITY_FAILURE';

export const TIMESHEET_CLOCK_CLOCK_GET_ALL_FAVOURITES_SUCCESS = 'TIMESHEET_CLOCK_CLOCK_GET_ALL_FAVOURITES_SUCCESS';
export const TIMESHEET_CLOCK_CLOCK_GET_ALL_FAVOURITES_FAILURE = 'TIMESHEET_CLOCK_CLOCK_GET_ALL_FAVOURITES_FAILURE';
