import {
    CYDER_PROJECTS_SETTINGS_SEARCH_REQUEST,
    CYDER_PROJECTS_SETTINGS_SEARCH_SUCCESS,
    CYDER_PROJECTS_SETTINGS_SEARCH_FAILURE,
    CYDER_PROJECTS_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG,
    CYDER_PROJECTS_SETTINGS_MODAL_TOGGLE,
    CYDER_PROJECTS_SETTINGS_MODAL_SET_PROJECT,
    CYDER_PROJECTS_SETTINGS_MODAL_CHANGE_VALUE,
    CYDER_PROJECTS_SETTINGS_PROJECT_ADD_REQUEST,
    CYDER_PROJECTS_SETTINGS_PROJECT_ADD_SUCCESS,
    CYDER_PROJECTS_SETTINGS_PROJECT_ADD_FAILURE,
    CYDER_PROJECTS_SETTINGS_PROJECT_UPDATE_REQUEST,
    CYDER_PROJECTS_SETTINGS_PROJECT_UPDATE_SUCCESS,
    CYDER_PROJECTS_SETTINGS_PROJECT_UPDATE_FAILURE,
    CYDER_PROJECTS_SETTINGS_PROJECT_DELETE_REQUEST,
    CYDER_PROJECTS_SETTINGS_PROJECT_DELETE_SUCCESS,
    CYDER_PROJECTS_SETTINGS_PROJECT_DELETE_FAILURE,
    CYDER_PROJECTS_SETTINGS_ADD_USER,
    CYDER_PROJECTS_SETTINGS_REMOVE_USER,
    CYDER_PROJECTS_SETTINGS_ADD_APPROVER,
    CYDER_PROJECTS_SETTINGS_REMOVE_APPROVER,
} from '../../actions/constants/actionTypes';

// defaults
var defaultState = {
    modalProject: {
        id: '',
        code: '',
        name: '',
        users: [],
        approvers: [],
        approvalvotescount: 1,
        overrideexpenseapproval: false,
    },
    usersReference: {},
    approversReference: {},
    saving: false,
    saved: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: '',
    modalHeader: '',
    loading: false,
    projects: [],
    forceRefresh: false,
};

export function cyderProjectsSettingsSearchReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    let usersReference;
    let approversReference;
    let obj = {};
    let indexOfUserToRemove;
    switch (action.type) {
        case CYDER_PROJECTS_SETTINGS_SEARCH_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_PROJECTS_SETTINGS_SEARCH_SUCCESS:
            newState.loading = false;
            newState.projects = action.response;
            return newState;
        case CYDER_PROJECTS_SETTINGS_SEARCH_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.projects = [];
            return newState;
        case CYDER_PROJECTS_SETTINGS_PROJECT_ADD_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_PROJECTS_SETTINGS_PROJECT_ADD_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : 'Project added successfully';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'refresh';
            return newState;
        case CYDER_PROJECTS_SETTINGS_PROJECT_ADD_FAILURE:
            newState.modalMessage = 'Failed to add project: ' + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_PROJECTS_SETTINGS_PROJECT_UPDATE_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_PROJECTS_SETTINGS_PROJECT_UPDATE_SUCCESS:
            newState.modalMessage = 'Project updated successfully';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'refresh';
            return newState;
        case CYDER_PROJECTS_SETTINGS_PROJECT_UPDATE_FAILURE:
            newState.modalMessage = 'Failed to save changes: ' + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_PROJECTS_SETTINGS_PROJECT_DELETE_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_PROJECTS_SETTINGS_PROJECT_DELETE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : 'Project deleted successfully';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'refresh';
            return newState;
        case CYDER_PROJECTS_SETTINGS_PROJECT_DELETE_FAILURE:
            newState.modalMessage = 'Failed to save changes: ' + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_PROJECTS_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG:
            newState.forceRefresh = action.val;
            return newState;
        case CYDER_PROJECTS_SETTINGS_MODAL_SET_PROJECT:
            newState.modalProject = {
                ...action,
            };
            action.users.forEach(user => {
                newState.usersReference[user] = true;
            });
            action.approvers.forEach(approver => {
                newState.approversReference[approver] = true;
            });
            return newState;
        case CYDER_PROJECTS_SETTINGS_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === 'update') {
                newState.modalMessage = null;
                newState.saved = false;
                newState.modalHeader = 'Update Project';
            } else if (action.modalAction === 'add') {
                newState.modalMessage = null;
                newState.saved = false;
                newState.modalHeader = 'Add Project';
            } else if (action.modalAction === 'delete') {
                newState.modalMessage = 'Are you sure you want to delete this project?';
                newState.saved = false;
                newState.modalHeader = 'Delete Project';
            }
            return newState;
        case CYDER_PROJECTS_SETTINGS_MODAL_CHANGE_VALUE:
            newState.modalProject = Object.assign({}, state.modalProject);
            newState.modalProject[action.key] = action.value;
            return newState;
        case CYDER_PROJECTS_SETTINGS_ADD_USER:
            obj[action.username] = true;
            usersReference = Object.assign(obj, state.usersReference);
            usersReference[action.username] = true;
            newState.modalProject = Object.assign({}, state.modalProject);
            var users = state.modalProject.users.concat(action.username);
            newState.modalProject.users = users;
            newState.usersReference = usersReference;
            newState.changesMade = true;
            return newState;
        case CYDER_PROJECTS_SETTINGS_REMOVE_USER:
            indexOfUserToRemove = state.modalProject.users.indexOf(action.username);
            usersReference = Object.assign({}, state.usersReference);
            usersReference[action.username] = false;
            newState.usersReference = Object.assign({}, usersReference);
            newState.modalProject.users = state.modalProject.users
                .slice(0, indexOfUserToRemove)
                .concat(state.modalProject.users.slice(indexOfUserToRemove + 1));
            newState.changesMade = true;
            return newState;
        case CYDER_PROJECTS_SETTINGS_ADD_APPROVER:
            obj[action.username] = true;
            approversReference = Object.assign(obj, state.approversReference);
            approversReference[action.username] = true;
            newState.modalProject = Object.assign({}, state.modalProject);
            var approvers = state.modalProject.approvers.concat(action.username);
            newState.modalProject.approvers = approvers;
            newState.approversReference = approversReference;
            newState.changesMade = true;
            return newState;
        case CYDER_PROJECTS_SETTINGS_REMOVE_APPROVER:
            indexOfUserToRemove = state.modalProject.approvers.indexOf(action.username);
            approversReference = Object.assign({}, state.approversReference);
            approversReference[action.username] = false;
            newState.approversReference = Object.assign({}, approversReference);
            newState.modalProject.approvers = state.modalProject.approvers
                .slice(0, indexOfUserToRemove)
                .concat(state.modalProject.approvers.slice(indexOfUserToRemove + 1));
            newState.changesMade = true;
            return newState;
        default:
            return state;
    }
}
