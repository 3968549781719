import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class DialogModal extends Component {
    state = {
        isOpen: false,
    };

    toggle = () => {
        const { toggle } = this.props;
        if (toggle) {
            toggle(this.state.isOpen);
            return;
        }
        this.setState({ isOpen: this.state.isOpen });
    };

    positiveButtonAction = () => {
        const { positiveButtonAction } = this.props;
        if (positiveButtonAction) {
            positiveButtonAction();
            return;
        }
        this.toggle();
    };

    negativeButtonAction = () => {
        const { negativeButtonAction } = this.props;
        if (negativeButtonAction) {
            negativeButtonAction();
            return;
        }
        this.toggle();
    };

    render() {
        const {
            modal,
            size,
            className,
            customHeader,
            customModalBody,
            buttonDisabler,
            buttonDisablerPositive,
            buttonDisablerNegative,
            onlyOneButton,
            positiveButtonText,
            negativeButtonText,
            bodyContent,
            children,
        } = this.props;

        const positiveBtnText = positiveButtonText || 'Ok';
        const negativeBtnText = negativeButtonText || 'Cancel';
        const positiveBtnDisabled = buttonDisabler || buttonDisablerPositive;
        const negativeBtnDisabled = buttonDisabler || buttonDisablerNegative;
        const isOpen = modal || this.state.isOpen;

        return (
            <Modal size={size || 'md'} isOpen={isOpen} toggle={this.toggle} className={className}>
                <ModalHeader className="border-0" toggle={this.toggle}>
                    <b>{customHeader}</b>
                </ModalHeader>
                <ModalBody>{bodyContent ? children : customModalBody}</ModalBody>
                <ModalFooter className="border-0">
                    <Button color="success" disabled={positiveBtnDisabled} onClick={this.positiveButtonAction}>
                        {positiveBtnText}
                    </Button>{' '}
                    {!onlyOneButton ? (
                        <Button color="light" className="color-danger" disabled={negativeBtnDisabled} onClick={this.negativeButtonAction}>
                            {negativeBtnText}
                        </Button>
                    ) : null}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = () => {
    return {};
};

// translate() is if we want to use HOC to perform t()
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DialogModal);
