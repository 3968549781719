import {
    TIMESHEET_CLOCK_CLOCK_GET_CURRENT_ACTIVITY_REQUEST,
    TIMESHEET_CLOCK_CLOCK_GET_CURRENT_ACTIVITY_SUCCESS,
    TIMESHEET_CLOCK_CLOCK_GET_CURRENT_ACTIVITY_FAILURE,
    TIMESHEET_CLOCK_CLOCK_IN_REQUEST,
    TIMESHEET_CLOCK_CLOCK_IN_SUCCESS,
    TIMESHEET_CLOCK_CLOCK_IN_FAILURE,
    TIMESHEET_CLOCK_CLOCK_OUT_REQUEST,
    TIMESHEET_CLOCK_CLOCK_OUT_SUCCESS,
    TIMESHEET_CLOCK_CLOCK_OUT_FAILURE,
    TIMESHEET_CLOCK_FORCE_LOADING,
    TIMESHEET_MYCLOCKS_GET_PAST_ACTIVITY_REQUEST,
    TIMESHEET_MYCLOCKS_GET_PAST_ACTIVITY_SUCCESS,
    TIMESHEET_MYCLOCKS_GET_PAST_ACTIVITY_FAILURE,
    TIMESHEET_CLOCK_CLOCK_GET_ALL_FAVOURITES_SUCCESS,
    TIMESHEET_CLOCK_CLOCK_GET_ALL_FAVOURITES_FAILURE,
} from '../../actions/constants/actionTypesTimesheet';

const defaultState = {
    clockedIn: false,
    loading: false,
    forceLoading: false,
    activity: [],
    pastActivity: [],
    activity: [],
    allFavourites: [],
};

export default function clockReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case TIMESHEET_CLOCK_CLOCK_GET_ALL_FAVOURITES_SUCCESS:
            newState.allFavourites = action.response.data;
            return newState;
        case TIMESHEET_CLOCK_CLOCK_GET_ALL_FAVOURITES_FAILURE:
            return newState;
        case TIMESHEET_CLOCK_CLOCK_GET_CURRENT_ACTIVITY_REQUEST:
            newState.loading = true;
            return newState;
        case TIMESHEET_CLOCK_CLOCK_GET_CURRENT_ACTIVITY_SUCCESS:
            newState.loading = false;
            newState.activity = action.response.data;
            return newState;
        case TIMESHEET_CLOCK_CLOCK_GET_CURRENT_ACTIVITY_FAILURE:
            newState.loading = false;
            newState.activity = defaultState.activity;
            return newState;
        case TIMESHEET_CLOCK_CLOCK_IN_REQUEST:
            newState.loading = true;
            return newState;
        case TIMESHEET_CLOCK_CLOCK_IN_SUCCESS:
            newState.loading = false;
            newState.clockedIn = true;
            return newState;
        case TIMESHEET_CLOCK_CLOCK_IN_FAILURE:
            newState.loading = false;
            return newState;
        case TIMESHEET_CLOCK_CLOCK_OUT_REQUEST:
            newState.loading = true;
            return newState;
        case TIMESHEET_CLOCK_CLOCK_OUT_SUCCESS:
            newState.loading = false;
            newState.clockedIn = false;
            return newState;
        case TIMESHEET_CLOCK_CLOCK_OUT_FAILURE:
            newState.loading = false;
            return newState;
        case TIMESHEET_CLOCK_FORCE_LOADING:
            newState.forceLoading = action.loadingState;
            return newState;
        case TIMESHEET_MYCLOCKS_GET_PAST_ACTIVITY_REQUEST:
            newState.loading = true;
            return newState;
        case TIMESHEET_MYCLOCKS_GET_PAST_ACTIVITY_SUCCESS:
            newState.loading = false;
            newState.pastActivity = action.response.data;
            return newState;
        case TIMESHEET_MYCLOCKS_GET_PAST_ACTIVITY_FAILURE:
            newState.loading = false;
            newState.pastActivity = defaultState.pastActivity;
            return newState;
        default:
            return state;
    }
}
