import React from 'react';
import { Switch, Route } from 'react-router';

// COMPONENTS
import asyncImport from 'cyderComponents/asyncImport/asyncImport';

// PRIVATE ROUTE
import PrivateRoute from '../cyderComponents/common/PrivateRoute';

//PAGES
const ExpensHome = asyncImport(() => import('../pages/home/expenseHome'));
const MyCorpSubmitLeaveForm = asyncImport(() => import('../pages/leaves/MyCorpSubmitLeaveForm'));
const Token = asyncImport(() => import('../pages/token'));

// TIMESHEETS
const ClockPage = asyncImport(() => import('../pages/timesheet/ClockPage'));
const MyClocksPage = asyncImport(() => import('../pages/timesheet/MyClocksPage'));
const MyCorpTimesheetHome = asyncImport(() => import('../pages/timesheet/MyCorpTimesheetHome'));
const MyCorpTimesheetHistory = asyncImport(() => import('../pages/timesheet/MyCorpTimesheetHistory'));
const MyCorpTimesheetReport = asyncImport(() => import('../pages/timesheet/MyCorpTimesheetReport'));
const MyCorpTimesheetReportDetailPage = asyncImport(() => import('../pages/timesheet/MyCorpTimesheetReportDetailPage'));
const MyCorpTimesheetApprovalPage = asyncImport(() => import('../pages/timesheet/MyCorpTimesheetApprovalPage'));
const TimesheetTaskDetailPage = asyncImport(() => import('../pages/timesheet/TimesheetTaskDetailPage'));

// LEAVES
const MyCorpLeavePlanPage = asyncImport(() => import('../pages/leaves/MyCorpLeavePlanPage'));
const MyCorpLeaveApprovalPage = asyncImport(() => import('../pages/leaves/MyCorpLeaveApprovalPage'));
const LeavePlanDetailPage = asyncImport(() => import('../pages/leaves/LeavePlanDetailPage'));
const LeaveTasksDetailPage = asyncImport(() => import('../pages/leaves/LeaveTasksDetailPage'));
const LeaveCalendar = asyncImport(() => import('../pages/leaves/LeaveCalendar'));

// EXPENSE
const MyReceiptsPage = asyncImport(() => import('../pages/expenses/receipts/MyReceiptsPage'));
const MyCorpExpensesHistory = asyncImport(() => import('../pages/expenses/receipts/MyCorpExpensesHistory'));
const ExpenseNewReceiptPage = asyncImport(() => import('../pages/expenses/receipts/new/ExpenseNewReceiptPage'));
const MyCorpNewReceiptPage = asyncImport(() => import('../pages/expenses/receipts/new/MyCorpNewReceiptPage'));
const ExpenseNewMileagePage = asyncImport(() => import('../pages/expenses/receipts/new/ExpenseNewMileagePage'));
const MyCorpNewMileagePage = asyncImport(() => import('../pages/expenses/receipts/new/MyCorpNewMileagePage'));
const MyCorpReportsPage = asyncImport(() => import('../pages/expenses/reports/MyCorpReportsPage'));
const MyCorpReportsDetailPage = asyncImport(() => import('../pages/expenses/reports/MyCorpReportsDetailPage'));
const ReportsDetailPage = asyncImport(() => import('../pages/expenses/reports/ReportsDetailPage'));
const MyCorpCreateReportPage = asyncImport(() => import('../pages/expenses/reports/MyCorpCreateReportPage'));
const ReceiptByCategory = asyncImport(() => import('../pages/report/ReceiptByCategory'));
const ReceiptByProject = asyncImport(() => import('../pages/report/ReceiptByProject'));
const MileageByProject = asyncImport(() => import('../pages/report/MileageByProject'));
const MileageByCategory = asyncImport(() => import('../pages/report/MileageByCategory'));
const ReportsByMonth = asyncImport(() => import('../pages/report/ReportsByMonth'));
const ReportsByMonthPrint = asyncImport(() => import('../pages/report/ReportsByMonthPrint'));
const ReportsByMonthDetails = asyncImport(() => import('../pages/report/ReportsByMonthDetails'));

// UNKNOWN
const MyCorpExpenseApprovalPage = asyncImport(() => import('../pages/workflow/MyCorpExpenseApprovalPage'));
const MyTasksPage = asyncImport(() => import('../pages/workflow/MyTasksPage'));
const TasksDetailPage = asyncImport(() => import('../pages/workflow/TasksDetailPage'));
const MyCorpTasksDetailPage = asyncImport(() => import('../pages/workflow/MyCorpTasksDetailPage'));
const TaskDetailReceiptComponent = asyncImport(() => import('../pages/workflow/TaskDetailReceiptComponent'));
const TaskDetailMileageComponent = asyncImport(() => import('../pages/workflow/TaskDetailMileageComponent'));
const DelegationsPage = asyncImport(() => import('../pages/workflow/DelegationsPage'));
const PaymentsListPage = asyncImport(() => import('../pages/payments/PaymentsListPage'));

// SETTINGS
const ProjectsSettingsPage = asyncImport(() => import('../pages/settings/expense/ProjectsSettingsPage'));

// COMMON
const ErrorPage = asyncImport(() => import('../pages/error-page'));

const ExpenseRoutes = () => (
    <div>
        <Switch>
            <Route exact path="/mycorp/token/:token/:module" component={Token} />

            <PrivateRoute path="/mycorp/report/mycorp/reportsbymonth/print/:reportparam" component={ReportsByMonthPrint} mode="admin" />
            <PrivateRoute path="/mycorp/timesheet/home" component={MyCorpTimesheetHome} />
            <PrivateRoute path="/mycorp/leaves/home" component={MyCorpSubmitLeaveForm} />
            <PrivateRoute path="/mycorp/expense/home" component={MyCorpNewReceiptPage} />

            {/* TIMESHEET */}
            <PrivateRoute exact path="/mycorp/timesheet/clock" component={ClockPage} />
            <PrivateRoute exact path="/mycorp/timesheet/myclocks" component={MyClocksPage} />
            <PrivateRoute exact path="/mycorp/timesheet/history" component={MyCorpTimesheetHistory} />
            <PrivateRoute exact path="/mycorp/timesheet/report" component={MyCorpTimesheetReport} />
            <PrivateRoute exact path="/mycorp/timesheet/report/:reportId" component={MyCorpTimesheetReportDetailPage} />
            <PrivateRoute exact path="/mycorp/timesheet/approval" component={MyCorpTimesheetApprovalPage} />
            <PrivateRoute exact path="/mycorp/timesheet/approval/:reportId/:taskId" component={MyCorpTimesheetReportDetailPage} />
            <PrivateRoute exact path="/mycorp/timesheet/projects" mode="mycorp" component={ProjectsSettingsPage} />

            {/* LEAVES */}
            {/* <PrivateRoute exact path="/mycorp/leaves/leaveplan/:apply" component={LeavePlanPage} /> */}
            <PrivateRoute exact path="/mycorp/leaves/leaveplan" component={MyCorpLeavePlanPage} />
            <PrivateRoute exact path="/mycorp/leaves/leaveplan/detail/:id" component={LeavePlanDetailPage} />
            <PrivateRoute exact path="/mycorp/leaves/leaveapproval" component={MyCorpLeaveApprovalPage} />
            <PrivateRoute path="/mycorp/leaves/leavetask/detail/:taskid" component={LeaveTasksDetailPage} />
            <PrivateRoute path="/mycorp/leaves/leavecalendar" component={LeaveCalendar} />

            {/* EXPENSE - RECEIPTS */}
            <PrivateRoute path="/mycorp/myreceipts/edit/receipt/:receiptid?" component={MyCorpNewReceiptPage} />
            <PrivateRoute path="/mycorp/myreceipts/view/receipt/:receiptid?" component={MyCorpNewReceiptPage} />
            <PrivateRoute path="/mycorp/myreceipts/edit/mileage/:receiptid?" component={MyCorpNewMileagePage} headerTitle="Edit Saved Mileage" />
            <PrivateRoute path="/mycorp/myreceipts/view/mileage/:receiptid?" component={MyCorpNewMileagePage} headerTitle="View Mileage" />
            <PrivateRoute path="/mycorp/myreceipts/new/receipt/:receiptid?" component={MyCorpNewReceiptPage} />
            <PrivateRoute path="/mycorp/myreceipts/new/mileage/:receiptid?" component={MyCorpNewMileagePage} />
            <PrivateRoute path="/mycorp/myreceipts/new/receipt" component={MyCorpNewReceiptPage} />
            <PrivateRoute path="/mycorp/myreceipts/new/mileage" component={ExpenseNewMileagePage} />
            <PrivateRoute path="/mycorp/myreceipts" component={MyCorpExpensesHistory} />

            {/* EXPENSE - MY REPORTS */}
            <PrivateRoute path="/mycorp/myreports/details/:reportid" component={MyCorpReportsDetailPage} mode="self" />
            <PrivateRoute path="/mycorp/myreports/create" component={MyCorpCreateReportPage} />
            <PrivateRoute path="/mycorp/myreports" component={MyCorpReportsPage} />

            {/* EXPENSE - RECURRING CHARGES */}
            <PrivateRoute recurring={true} path="/mycorp/recurring/edit/:receiptid?" component={ExpenseNewReceiptPage} />
            <PrivateRoute recurring={true} path="/mycorp/recurring/new/:receiptid?" component={ExpenseNewReceiptPage} />
            <PrivateRoute recurring={true} path="/mycorp/recurring/new" component={MyReceiptsPage} />
            <PrivateRoute recurring={true} path="/mycorp/recurring" component={MyReceiptsPage} />

            {/* REPORTS */}
            <PrivateRoute exact path="/mycorp/report/expense/receiptbycategory" component={ReceiptByCategory} />
            <PrivateRoute exact path="/mycorp/report/expense/receiptbyproject" component={ReceiptByProject} />
            <PrivateRoute exact path="/mycorp/report/expense/mileagebyproject" component={MileageByProject} />
            <PrivateRoute exact path="/mycorp/report/expense/mileagebycategory" component={MileageByCategory} />
            <PrivateRoute exact path="/mycorp/report/expense/reportsbymonth" component={ReportsByMonth} />
            <PrivateRoute path="/mycorp/report/expense/reportsbymonth/details/:reportid" component={ReportsByMonthDetails} mode="admin" />
            <PrivateRoute path="/mycorp/report/expense/reportsbymonth/print" component={ReportsByMonthPrint} mode="admin" />

            {/* ADMIN - PAYMENT */}
            <PrivateRoute accessLevel={1} path="/mycorp/payments/:reportid" component={ReportsDetailPage} />
            <PrivateRoute accessLevel={1} path="/mycorp/payments" component={PaymentsListPage} />

            {/* MY TASKS */}
            <PrivateRoute path="/mycorp/mytasks" component={MyCorpExpenseApprovalPage} />
            <PrivateRoute path="/mycorp/delegations" component={DelegationsPage} />
            <PrivateRoute path="/mycorp/task/taskview/mileage/:itemid" component={TaskDetailMileageComponent} />
            <PrivateRoute path="/mycorp/task/taskview/receipt/:itemid" component={TaskDetailReceiptComponent} />
            <PrivateRoute path="/mycorp/task/:taskid" component={MyCorpTasksDetailPage} />
        </Switch>
    </div>
);

export default ExpenseRoutes;
