import {
    CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_REQUEST,
    CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_FAILURE,
    CYDER_EXPENSE_SHARED_GET_PROJECTS_REQUEST,
    CYDER_EXPENSE_SHARED_GET_PROJECTS_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_PROJECTS_FAILURE,
    CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_REQUEST,
    CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_FAILURE,
    CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_REQUEST,
    CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_FAILURE,
    CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_REQUEST,
    CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_FAILURE,
    CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_REQUEST,
    CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_FAILURE,
    CYDER_EXPENSE_SHARED_GET_LOCATIONS_REQUEST,
    CYDER_EXPENSE_SHARED_GET_LOCATIONS_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_LOCATIONS_FAILURE,
    CYDER_EXPENSE_SHARED_GET_RELEVANT_ITEMS_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_TASKS_COUNT_REQUEST,
    CYDER_EXPENSE_SHARED_GET_TASKS_COUNT_SUCCESS,
} from '../../actions/constants/actionTypes';

var defaultState = {
    allLoaded: false,
    mileageCategories: [],
    mileageCategoriesMap: {},
    projects: [],
    projectsMap: {},
    expenseCategories: [],
    expenseCategoriesMap: {},
    dashboardItems: {},
    settingsCount: {},
    paymentModes: [],
    locations: [],
};
export function cyderExpenseSharedReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_REQUEST:
            newState.loading = true;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_SUCCESS:
            newState.loading = false;
            newState.mileageCategories = action.response;
            action.response.forEach(item => {
                newState.mileageCategoriesMap[item.code] = item.name;
            });
            return newState;
        case CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_PROJECTS_REQUEST:
            newState.loading = true;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_PROJECTS_SUCCESS:
            newState.loading = false;
            newState.projects = action.response;
            action.response.forEach(item => {
                newState.projectsMap[item.code] = item.name;
            });
            return newState;
        case CYDER_EXPENSE_SHARED_GET_PROJECTS_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_REQUEST:
            newState.loading = true;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_SUCCESS:
            newState.loading = false;
            newState.expenseCategories = action.response;
            action.response.forEach(item => {
                newState.expenseCategoriesMap[item.code] = item.name;
            });
            return newState;
        case CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_REQUEST:
            newState.loading = true;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_SUCCESS:
            newState.loading = false;
            newState.dashboardItems = action.response;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_REQUEST:
            newState.loading = true;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_SUCCESS:
            newState.loading = false;
            action.response.forEach(item => {
                newState.settingsCount[item.name] = item.count;
            });
            return newState;
        case CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_REQUEST:
            newState.loading = true;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_SUCCESS:
            newState.loading = false;
            newState.paymentModes = action.response;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_LOCATIONS_REQUEST:
            newState.loading = true;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_LOCATIONS_SUCCESS:
            newState.loading = false;
            newState.locations = action.response;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_LOCATIONS_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            return newState;
        case CYDER_EXPENSE_SHARED_GET_RELEVANT_ITEMS_SUCCESS:
            newState.allLoaded = true;
            return newState;
        default:
            return state;
    }
}
