/**
 * Dropdown panel
 *
 * Author: Fernando
 */
import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';

import { cyderLogoutAction } from '../../actions/security/cyderSecurityAction';

import '../../scss/elements/dropdown-user.scss';

const DropdownUser = ({ fields, items, menuAction }) => {
    return (
        <div className="navbar-dropdown dropdown-user">
            {items.map((item, i) => (
                <div className="dropdown-item" key={i} onClick={() => menuAction(fields, item)}>
                    <i className={item.icon} />
                    <span className="title">{item.name}</span>
                    <div className="separator" />
                    {item.badge && <span className={item.badgeClass}>{item.badgeText}</span>}
                </div>
            ))}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        fields: state.cyderLoginReducer.fields,
        items: state.usermenu,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        menuAction: (fields, menuItem) => {
            // This function ONLY does the binding. Validation should be done in the reducer
            switch (menuItem.action) {
                case 'profile':
                    history.push('/profile');
                    break;
                case 'logout':
                    // SET NAVIGATION MENU TYPE BACK TO EXPENSE NAVIGATION
                    dispatch({ type: 'SWITCH_NAV_MENU', navType: 'expenseNavigation' });
                    dispatch(cyderLogoutAction()).then(res => {
                        history.push('/');
                    });
                    break;
                default:
                    return;
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownUser);
