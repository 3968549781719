export const CYDER_EXPENSE_MYREPORTS_GET_REPORTS_REQUEST = 'CYDER_EXPENSE_MYREPORTS_GET_REPORTS_REQUEST';
export const CYDER_EXPENSE_MYREPORTS_GET_REPORTS_SUCCESS = 'CYDER_EXPENSE_MYREPORTS_GET_REPORTS_SUCCESS';
export const CYDER_EXPENSE_MYREPORTS_GET_REPORTS_FAILURE = 'CYDER_EXPENSE_MYREPORTS_GET_REPORTS_FAILURE';

export const CYDER_EXPENSE_MYREPORTS_MODAL_TOGGLE = 'CYDER_EXPENSE_MYREPORTS_MODAL_TOGGLE';
// My Reports Create Report Page
export const CYDER_EXPENSE_REPORTS_CREATE_MODAL_TOGGLE = 'CYDER_EXPENSE_REPORTS_CREATE_MODAL_TOGGLE';
export const CYDER_EXPENSE_REPORTS_CREATE_SUBMIT_EXPENSE_REPORT_REQUEST = 'CYDER_EXPENSE_REPORTS_CREATE_SUBMIT_EXPENSE_REPORT_REQUEST';
export const CYDER_EXPENSE_REPORTS_CREATE_SUBMIT_EXPENSE_REPORT_SUCCESS = 'CYDER_EXPENSE_REPORTS_CREATE_SUBMIT_EXPENSE_REPORT_SUCCESS';
export const CYDER_EXPENSE_REPORTS_CREATE_SUBMIT_EXPENSE_REPORT_FAILURE = 'CYDER_EXPENSE_REPORTS_CREATE_SUBMIT_EXPENSE_REPORT_FAILURE';
export const CYDER_EXPENSE_REPORTS_CREATE_RESET_STATE = 'CYDER_EXPENSE_REPORTS_CREATE_RESET_STATE';
export const CYDER_EXPENSE_REPORTS_GET_APPROVERS_REQUEST = 'CYDER_EXPENSE_REPORTS_GET_APPROVERS_REQUEST';
export const CYDER_EXPENSE_REPORTS_GET_APPROVERS_SUCCESS = 'CYDER_EXPENSE_REPORTS_GET_APPROVERS_SUCCESS';
export const CYDER_EXPENSE_REPORTS_GET_APPROVERS_FAILURE = 'CYDER_EXPENSE_REPORTS_GET_APPROVERS_FAILURE';

// Reports Detail Page
export const CYDER_EXPENSE_REPORT_DETAILS_MODAL_TOGGLE = 'CYDER_EXPENSE_REPORT_DETAILS_MODAL_TOGGLE';
export const CYDER_EXPENSE_REPORT_DETAILS_SET_FIELDS = 'CYDER_EXPENSE_REPORT_DETAILS_SET_FIELDS';
export const CYDER_EXPENSE_REPORT_DETAILS_GET_SAVED_REPORT_REQUEST = 'CYDER_EXPENSE_REPORT_DETAILS_GET_SAVED_REPORT_REQUEST';
export const CYDER_EXPENSE_REPORT_DETAILS_GET_SAVED_REPORT_SUCCESS = 'CYDER_EXPENSE_REPORT_DETAILS_GET_SAVED_REPORT_SUCCESS';
export const CYDER_EXPENSE_REPORT_DETAILS_GET_SAVED_REPORT_FAILURE = 'CYDER_EXPENSE_REPORT_DETAILS_GET_SAVED_REPORT_FAILURE';

export const CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_REPORT_REQUEST = 'CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_REPORT_REQUEST';
export const CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_REPORT_SUCCESS = 'CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_REPORT_SUCCESS';
export const CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_REPORT_FAILURE = 'CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_REPORT_FAILURE';

export const CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_ATTACHMENT_REQUEST = 'CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_ATTACHMENT_REQUEST';
export const CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_ATTACHMENT_SUCCESS = 'CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_ATTACHMENT_SUCCESS';
export const CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_ATTACHMENT_FAILURE = 'CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_ATTACHMENT_FAILURE';

export const CYDER_EXPENSE_REPORT_DETAILS_SET_STATUS_REQUEST = 'CYDER_EXPENSE_REPORT_DETAILS_SET_STATUS_REQUEST';
export const CYDER_EXPENSE_REPORT_DETAILS_SET_STATUS_SUCCESS = 'CYDER_EXPENSE_REPORT_DETAILS_SET_STATUS_SUCCESS';
export const CYDER_EXPENSE_REPORT_DETAILS_SET_STATUS_FAILURE = 'CYDER_EXPENSE_REPORT_DETAILS_SET_STATUS_FAILURE';

export const CYDER_EXPENSE_REPORT_DETAILS_TOGGLE_CHECKBOX = 'CYDER_EXPENSE_REPORT_DETAILS_TOGGLE_CHECKBOX';
export const CYDER_EXPENSE_REPORT_DETAILS_TOGGLE_CHECKBOX_ALL = 'CYDER_EXPENSE_REPORT_DETAILS_TOGGLE_CHECKBOX_ALL';
