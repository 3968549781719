import {
    CYDER_WORKFLOW_MYTASKS_GET_MYTASKS_REQUEST,
    CYDER_WORKFLOW_MYTASKS_GET_MYTASKS_SUCCESS,
    CYDER_WORKFLOW_MYTASKS_GET_MYTASKS_FAILURE,
    CYDER_WORKFLOW_MYTASKS_MODAL_TOGGLE,
} from 'actions/constants/actionTypesWorkflow';

// defaults
var defaultState = {
    selectedItems: {},
    selectedAll: false,
    receipts: [],
    loading: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
};

export function myTasksReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    let newSelectedItems = {};
    switch (action.type) {
        case CYDER_WORKFLOW_MYTASKS_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === 'save') {
                newState.modalMessage = 'Are you sure you want to save this receipt?';
                newState.saved = false;
            } else if (action.modalAction === 'delete') {
                newState.modalMessage = 'Are you sure you want to delete this receipt?';
                newState.receiptIdForDeletion = action.data.receiptId;
                newState.saved = false;
            }
            return newState;
        case CYDER_WORKFLOW_MYTASKS_GET_MYTASKS_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_WORKFLOW_MYTASKS_GET_MYTASKS_SUCCESS:
            newState.loading = false;
            newState.receipts = action.response.data;
            action.response.data.forEach(each => (newSelectedItems[each.receiptId] = false));
            newState.selectedItems = newSelectedItems;
            newState.selectedAll = false;
            return newState;
        case CYDER_WORKFLOW_MYTASKS_GET_MYTASKS_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.receipts = [];
            return newState;
        default:
            return state;
    }
}
