import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Media } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import '../../scss/elements/logo.scss';
const pjson = require('../../../package.json');

class Logo extends React.Component {
    render() {
        const logoStyle = {
            width: '60%',
        };
        return (
            <Link
                to="/home"
                style={{
                    paddingLeft: '15px',
                }}
                className="logo d-flex justify-content-start align-items-center flex-nowrap"
            >
                <Media className="d-none d-lg-block" style={logoStyle} object src="/assets/images/header_1.png" />
                <Media className="d-lg-none" style={logoStyle} object src="/assets/images/djarvis-small.png" />
                <span
                    className="d-none d-lg-block"
                    style={{
                        paddingTop: '5px',
                        marginLeft: '50px',
                    }}
                >
                    {pjson.version}
                </span>
            </Link>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const isNavBarCollapsed = state.config.layout === 'collapsed-sidebar-1';
    return {
        isNavBarCollapsed,
    };
};

export default withTranslation()(connect(mapStateToProps, null)(Logo));
