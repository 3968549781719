import {
    CYDER_LOCATIONS_SETTINGS_SEARCH_REQUEST,
    CYDER_LOCATIONS_SETTINGS_SEARCH_SUCCESS,
    CYDER_LOCATIONS_SETTINGS_SEARCH_FAILURE,
    CYDER_LOCATIONS_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG,
    CYDER_LOCATIONS_SETTINGS_MODAL_TOGGLE,
    CYDER_LOCATIONS_SETTINGS_MODAL_SET_LOCATION,
    CYDER_LOCATIONS_SETTINGS_MODAL_CHANGE_VALUE,
    CYDER_LOCATIONS_SETTINGS_LOCATION_ADD_REQUEST,
    CYDER_LOCATIONS_SETTINGS_LOCATION_ADD_SUCCESS,
    CYDER_LOCATIONS_SETTINGS_LOCATION_ADD_FAILURE,
    CYDER_LOCATIONS_SETTINGS_LOCATION_UPDATE_REQUEST,
    CYDER_LOCATIONS_SETTINGS_LOCATION_UPDATE_SUCCESS,
    CYDER_LOCATIONS_SETTINGS_LOCATION_UPDATE_FAILURE,
    CYDER_LOCATIONS_SETTINGS_LOCATION_DELETE_REQUEST,
    CYDER_LOCATIONS_SETTINGS_LOCATION_DELETE_SUCCESS,
    CYDER_LOCATIONS_SETTINGS_LOCATION_DELETE_FAILURE,
    CYDER_LOCATIONS_SETTINGS_LOCATION_MAP_SET_LOC,
    CYDER_LOCATIONS_SETTINGS_CHANGE_FIELD_VALUE,
    CYDER_LOCATIONS_SETTINGS_CHANGE_LAT_LNG,
    CYDER_LOCATIONS_SETTINGS_QR_OPEN,
} from '../../actions/constants/actionTypes';
import cyderlib from '../../js/cyderlib';

// defaults
var defaultState = {
    modalLocation: {
        id: '',
        code: '',
        name: '',
        lat: '',
        lng: '',
        address: '',
    },
    saving: false,
    saved: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: '',
    modalHeader: '',
    loading: false,
    locations: [],
    forceRefresh: false,
    loc: null,
    locMarker: null,
    validation: {},
    qrOpen: false,
    qrLocation: null,
};

export function cyderLocationsSettingsSearchReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case CYDER_LOCATIONS_SETTINGS_SEARCH_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_LOCATIONS_SETTINGS_SEARCH_SUCCESS:
            newState.loading = false;
            newState.locations = action.response;
            return newState;
        case CYDER_LOCATIONS_SETTINGS_SEARCH_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.locations = [];
            return newState;
        case CYDER_LOCATIONS_SETTINGS_LOCATION_ADD_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_LOCATIONS_SETTINGS_QR_OPEN:
            newState.qrLocation = action.location;
            newState.qrOpen = action.open;
            return newState;
        case CYDER_LOCATIONS_SETTINGS_LOCATION_ADD_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : 'Location has been successfully added';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'refresh';
            return newState;
        case CYDER_LOCATIONS_SETTINGS_LOCATION_ADD_FAILURE:
            newState.modalMessage = 'Failed to add locations: ' + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_LOCATIONS_SETTINGS_LOCATION_UPDATE_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_LOCATIONS_SETTINGS_LOCATION_UPDATE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : 'Location has been successfully updated';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'refresh';
            return newState;
        case CYDER_LOCATIONS_SETTINGS_LOCATION_UPDATE_FAILURE:
            newState.modalMessage = 'Failed to save changes: ' + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_LOCATIONS_SETTINGS_LOCATION_DELETE_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_LOCATIONS_SETTINGS_LOCATION_DELETE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : 'Location has been successfully deleted';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'refresh';
            return newState;
        case CYDER_LOCATIONS_SETTINGS_LOCATION_DELETE_FAILURE:
            newState.modalMessage = 'Failed to save changes: ' + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_LOCATIONS_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG:
            newState.forceRefresh = action.val;
            return newState;
        case CYDER_LOCATIONS_SETTINGS_MODAL_SET_LOCATION:
            newState.modalLocation = {
                id: action.id,
                code: action.code,
                name: action.name,
                lat: action.lat,
                lng: action.lng,
                address: action.address,
            };
            return newState;
        case CYDER_LOCATIONS_SETTINGS_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === 'update') {
                newState.modalMessage = null;
                newState.saved = false;
                newState.modalHeader = 'Update Location';
            } else if (action.modalAction === 'add') {
                newState.modalMessage = null;
                newState.saved = false;
                newState.modalHeader = 'Add Location';
            } else if (action.modalAction === 'delete') {
                newState.modalMessage = 'Are you sure you want to delete this Location?';
                newState.saved = false;
                newState.modalHeader = 'Delete Location';
            }
            return newState;
        case CYDER_LOCATIONS_SETTINGS_MODAL_CHANGE_VALUE:
            newState.modalLocation = Object.assign({}, state.modalLocation);
            newState.modalLocation[action.key] = action.value;
            return newState;
        case CYDER_LOCATIONS_SETTINGS_LOCATION_MAP_SET_LOC:
            newState.loc = action.place;
            newState.locMarker = action.marker;
            return newState;
        case CYDER_LOCATIONS_SETTINGS_CHANGE_FIELD_VALUE:
            newState.modalLocation = Object.assign({}, state.modalLocation);
            newState.validation = Object.assign({}, state.validation);

            newState.modalLocation[action.key] = action.value;

            if (action.key === 'lat' || action.key === 'lng') {
                newState.locMarker = null;
                newState.loc = null;
            }
            newState.validation[action.key] = cyderlib.validate(action.key, action.value);
            return newState;
        case CYDER_LOCATIONS_SETTINGS_CHANGE_LAT_LNG:
            newState.modalLocation = Object.assign({}, state.modalLocation);
            newState.modalLocation.lat = action.lat;
            newState.modalLocation.lng = action.lng;
            return newState;
        default:
            return state;
    }
}
