import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DevNav from '../../cyderComponents/common/DevNav';
import { capitalize } from 'js/generalUtils';
const pjson = require('../../../package.json');

const EnvLabel = () => {
    const env = process.env.NODE_ENV;
    const isDev = env === 'development';
    return (
        <>
            <div className="d-none d-lg-block">{isDev && <span className="text-danger text-bold">{capitalize(env)}</span>}</div>
            <div className="d-none d-sm-block d-lg-none">
                <span className="text-bold">Djarvis</span>
                {` `}
                <span className="text-bold">{pjson.version}</span>
                {isDev && (
                    <span className="text-danger text-bold">
                        {` `}
                        {capitalize(env)}
                    </span>
                )}
            </div>
            <div className="d-md-none">
                <span className="text-bold">Djarvis</span>
                {` `}
                <span className="text-bold">{pjson.version}</span>
                <br />
                {isDev && <span className="text-danger text-bold">{capitalize(env)}</span>}
            </div>
            =[]
        </>
    );
};
const MyCorpNavBar = ({ persistor, profile, module }) => {
    const hasProfilePic = profile.profile_picture ? true : false;
    const trialDaysRemaining = Math.floor(moment.duration(moment(profile.subscription_end).diff(moment())).as('days'));

    const homeUrl =
        module === 'instatime'
            ? '/mycorp/timesheet/home'
            : module === 'instaxpense'
            ? '/mycorp/expense/home'
            : module === 'instaleave'
            ? '/mycorp/leave/home'
            : '';

    const moduleTitle =
        module === 'instatime'
            ? 'MyCorp Timesheet'
            : module === 'instaxpense'
            ? 'MyCorp Expense'
            : module === 'instaleave'
            ? 'MyCorp Leave'
            : '';
    return (
        <nav className="navbar mycorp-navbar d-flex justify-content-around align-items-center flex-nowrap">
            {/* <Logo /> */}
            {/* <ToggleLayout1 />
            <ToggleLayout2 /> */}

            {/* <EnvLabel /> */}
            <DevNav persistor={persistor} />
            <div className="separator" />
            <Link
                to={homeUrl}
                style={{
                    color: 'unset',
                    width: '100%',
                }}
            >
                <ul
                    className="nav nav-inline mr20 w-100p pt10 d-flex justify-content-start"
                    style={{
                        paddingLeft: '10px',
                    }}
                >
                    <li>
                        <i
                            className="material-icons"
                            style={{
                                fontSize: 25,
                                paddingTop: 7,
                            }}
                        >
                            home
                        </i>
                    </li>
                    <li>
                        <h4
                            style={{
                                paddingLeft: 10,
                            }}
                        >
                            {moduleTitle}
                        </h4>
                    </li>
                </ul>
            </Link>
        </nav>
    );
};

const mapStateToProps = state => {
    return {
        ...state.cyderProfileReducer,
        ...state.mycorpReducer,
    };
};

export default connect(mapStateToProps, {})(MyCorpNavBar);
