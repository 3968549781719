import {
    CYDER_EXPENSE_REPORT_DETAILS_MODAL_TOGGLE,
    CYDER_EXPENSE_REPORT_DETAILS_SET_FIELDS,
    CYDER_EXPENSE_REPORT_DETAILS_GET_SAVED_REPORT_REQUEST,
    CYDER_EXPENSE_REPORT_DETAILS_GET_SAVED_REPORT_SUCCESS,
    CYDER_EXPENSE_REPORT_DETAILS_GET_SAVED_REPORT_FAILURE,
    CYDER_EXPENSE_REPORT_DETAILS_SET_STATUS_REQUEST,
    CYDER_EXPENSE_REPORT_DETAILS_SET_STATUS_SUCCESS,
    CYDER_EXPENSE_REPORT_DETAILS_SET_STATUS_FAILURE,
    CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_REPORT_REQUEST,
    CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_REPORT_SUCCESS,
    CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_REPORT_FAILURE,
    CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_ATTACHMENT_REQUEST,
    CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_ATTACHMENT_SUCCESS,
    CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_ATTACHMENT_FAILURE,
    CYDER_EXPENSE_REPORT_DETAILS_TOGGLE_CHECKBOX,
    CYDER_EXPENSE_REPORT_DETAILS_TOGGLE_CHECKBOX_ALL,
} from '../../actions/constants/actionTypesReports';

// defaults
var defaultState = {
    receiptIdForRemoval: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
    report: {},
    selectedItems: {},
    selectedAll: false,
};

export function expenseReportsDetailReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    let newSelectedItems = {};
    let stateResult;
    switch (action.type) {
        case CYDER_EXPENSE_REPORT_DETAILS_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === 'validating') {
                newState.modalMessage = 'Are you sure you want to mark this item as Validating?';
            }
            if (action.modalAction === 'paid') {
                newState.modalMessage = 'Are you sure you want to mark this item as Paid?';
                newState.saved = false;
            }
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_GET_SAVED_REPORT_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_GET_SAVED_REPORT_SUCCESS:
            newState.loading = false;
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_GET_SAVED_REPORT_FAILURE:
            newState.loading = false;
            newState.modalOpen = true;
            newState.modalHeader = 'Error';
            newState.modalAction = 'close';
            newState.errorMessage = action.error.message;
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_SET_FIELDS:
            const { report, resetFields } = action;
            const { receipts, receiptDetails } = action.report;

            if (report) newState.report = Object.assign({}, report);
            if (resetFields) newState.report = Object.assign({}, defaultState.report);
            if (receipts) receipts.forEach(each => (newSelectedItems[each.id] = false));
            if (receiptDetails) receiptDetails.forEach(each => (newSelectedItems[each.id] = false));

            newState.selectedItems = newSelectedItems;
            newState.selectedAll = false;
            newState.loading = false;
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_SET_STATUS_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_SET_STATUS_SUCCESS:
            newState.modalMessage = action.response.message || 'Item status succesfully set to ' + action.newStatus;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'redirectPayment';
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_SET_STATUS_FAILURE:
            newState.modalMessage = 'Failed to set item status';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_REPORT_REQUEST:
            newState.loading = true;
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_REPORT_SUCCESS:
            newState.loading = false;
            newState.modalMessage = 'Successfully download expense report';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_REPORT_FAILURE:
            newState.loading = false;
            newState.modalMessage = 'Failed to download expense report';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_ATTACHMENT_REQUEST:
            newState.loading = true;
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_ATTACHMENT_SUCCESS:
            newState.loading = false;
            newState.modalMessage = 'Successfully download report attachments';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_DOWNLOAD_ATTACHMENT_FAILURE:
            newState.loading = false;
            newState.modalMessage = 'Failed to download report attachments';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_TOGGLE_CHECKBOX:
            newSelectedItems = Object.assign({}, state.selectedItems);
            if (newSelectedItems[action.id] === undefined) {
                newSelectedItems[action.id] = true;
            } else {
                newSelectedItems[action.id] = !newSelectedItems[action.id];
            }
            newState.selectedItems = newSelectedItems;
            return newState;
        case CYDER_EXPENSE_REPORT_DETAILS_TOGGLE_CHECKBOX_ALL:
            newSelectedItems = Object.assign({}, state.selectedItems);
            stateResult = !newState.selectedAll;
            newState.selectedAll = stateResult;
            for (let key in newSelectedItems) {
                newSelectedItems[key] = stateResult;
            }
            newState.selectedItems = newSelectedItems;
            return newState;
        default:
            return state;
    }
}
