/**
 * Action Types
 *
 * Author: Fernando
 */

// Security
export const CYDER_LOGIN_REQUEST = 'CYDER_LOGIN_REQUEST';
export const CYDER_LOGIN_SUCCESS = 'CYDER_LOGIN_SUCCESS';
export const CYDER_LOGIN_SUCCESS_SET_PROFILE = 'CYDER_LOGIN_SUCCESS_SET_PROFILE';
export const CYDER_LOGIN_FAILURE = 'CYDER_LOGIN_FAILURE';
export const CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE = 'CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE';
export const CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE = 'CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE';
export const CYDER_LOGIN_PAGE_RESET_STATE = 'CYDER_LOGIN_PAGE_RESET_STATE';
export const CYDER_LOGOUT = 'CYDER_LOGOUT';
export const CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE = 'CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE';
export const CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG = 'CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG';
export const CYDER_LOGIN_SUCCESS_REQUIRE_TFA = 'CYDER_LOGIN_SUCCESS_REQUIRE_TFA';
export const CYDER_LOGIN_PAGE_SUBMIT_OTP_REQUEST = 'CYDER_LOGIN_PAGE_SUBMIT_OTP_REQUEST';
export const CYDER_LOGIN_PAGE_SUBMIT_OTP_SUCCESS = 'CYDER_LOGIN_PAGE_SUBMIT_OTP_SUCCESS';
export const CYDER_LOGIN_PAGE_SUBMIT_OTP_FAILURE = 'CYDER_LOGIN_PAGE_SUBMIT_OTP_FAILURE';

// Change password
export const CYDER_CHANGE_PASSWORD_RESET_STATE = 'CYDER_CHANGE_PASSWORD_RESET_STATE';
export const CYDER_CHANGE_PASSWORD_REQUEST = 'CYDER_CHANGE_PASSWORD_REQUEST';
export const CYDER_CHANGE_PASSWORD_SUCCESS = 'CYDER_CHANGE_PASSWORD_SUCCESS';
export const CYDER_CHANGE_PASSWORD_FAILURE = 'CYDER_CHANGE_PASSWORD_FAILURE';
export const CYDER_CHANGE_PASSWORD_PAGE_CLEAR_MESSAGE = 'CYDER_CHANGE_PASSWORD_PAGE_CLEAR_MESSAGE';
export const CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE = 'CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE';
export const CYDER_CHANGE_PASSWORD_GET_INFO_REQUEST = 'CYDER_CHANGE_PASSWORD_GET_INFO_REQUEST';
export const CYDER_CHANGE_PASSWORD_GET_INFO_SUCCESS = 'CYDER_CHANGE_PASSWORD_GET_INFO_SUCCESS';
export const CYDER_CHANGE_PASSWORD_GET_INFO_FAILURE = 'CYDER_CHANGE_PASSWORD_GET_INFO_FAILURE';

export const CYDER_FORGOT_PASSWORD_REQUEST = 'CYDER_FORGOT_PASSWORD_REQUEST';
export const CYDER_FORGOT_PASSWORD_SUCCESS = 'CYDER_FORGOT_PASSWORD_SUCCESS';
export const CYDER_FORGOT_PASSWORD_FAILURE = 'CYDER_FORGOT_PASSWORD_FAILURE';
export const CYDER_FORGOT_PASSWORD_PAGE_SET_MESSAGE = 'CYDER_FORGOT_PASSWORD_PAGE_SET_MESSAGE';

// Sysparams
export const CYDER_SYSPARAMS_GET = 'CYDER_SYSPARAMS_GET';
export const CYDER_SYSPARAMS_GET_SUCCESS = 'CYDER_SYSPARAMS_GET_SUCCESS';

export const CYDER_SYSPARAMS_GET_BY_CODE_REQUEST = 'CYDER_SYSPARAMS_GET_BY_CODE_REQUEST';
export const CYDER_SYSPARAMS_GET_BY_CODE_SUCCESS = 'CYDER_SYSPARAMS_GET_BY_CODE_SUCCESS';
export const CYDER_SYSPARAMS_GET_BY_CODE_FAILURE = 'CYDER_SYSPARAMS_GET_BY_CODE_FAILURE';

export const CYDER_SYSPARAMS_PAGE_MODAL_TOGGLE = 'CYDER_SYSPARAMS_PAGE_MODAL_TOGGLE';
export const CYDER_SYSPARAMS_PAGE_MODAL_OPEN = 'CYDER_SYSPARAMS_PAGE_MODAL_OPEN';
export const CYDER_SYSPARAMS_PAGE_MODAL_CLOSE = 'CYDER_SYSPARAMS_PAGE_MODAL_CLOSE';
export const CYDER_SYSPARAMS_PAGE_MODAL_CHANGE_VALUE = 'CYDER_SYSPARAMS_PAGE_MODAL_CHANGE_VALUE';
export const CYDER_SYSPARAMS_PAGE_LOADING = 'CYDER_SYSPARAMS_PAGE_LOADING';
export const CYDER_SYSPARAMS_PAGE_MODAL_SET_SYSPARAM = 'CYDER_SYSPARAMS_PAGE_MODAL_SET_SYSPARAM';

export const CYDER_SYSPARAMS_SAVE_VALUE = 'CYDER_SYSPARAMS_SAVE_VALUE';
export const CYDER_SYSPARAMS_SAVE_VALUE_SUCCESS = 'CYDER_SYSPARAMS_SAVE_VALUE_SUCCESS';
export const CYDER_SYSPARAMS_SAVE_VALUE_SAVING = 'CYDER_SYSPARAMS_SAVE_VALUE_SAVING';
//
// Projects Settings List
export const CYDER_PROJECTS_SETTINGS_SEARCH_REQUEST = 'CYDER_PROJECTS_SETTINGS_SEARCH_REQUEST';
export const CYDER_PROJECTS_SETTINGS_SEARCH_SUCCESS = 'CYDER_PROJECTS_SETTINGS_SEARCH_SUCCESS';
export const CYDER_PROJECTS_SETTINGS_SEARCH_FAILURE = 'CYDER_PROJECTS_SETTINGS_SEARCH_FAILURE';
export const CYDER_PROJECTS_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG = 'CYDER_PROJECTS_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG';

export const CYDER_PROJECTS_SETTINGS_MODAL_TOGGLE = 'CYDER_PROJECTS_SETTINGS_MODAL_TOGGLE';
export const CYDER_PROJECTS_SETTINGS_MODAL_OPEN = 'CYDER_PROJECTS_SETTINGS_MODAL_OPEN';
export const CYDER_PROJECTS_SETTINGS_MODAL_CLOSE = 'CYDER_PROJECTS_SETTINGS_MODAL_CLOSE';
export const CYDER_PROJECTS_SETTINGS_MODAL_CHANGE_VALUE = 'CYDER_PROJECTS_SETTINGS_MODAL_CHANGE_VALUE';
export const CYDER_PROJECTS_SETTINGS_LOADING = 'CYDER_PROJECTS_SETTINGS_LOADING';
export const CYDER_PROJECTS_SETTINGS_MODAL_SET_PROJECT = 'CYDER_PROJECTS_SETTINGS_MODAL_SET_PROJECT';

export const CYDER_PROJECTS_SETTINGS_PROJECT_ADD_REQUEST = 'CYDER_PROJECTS_SETTINGS_PROJECT_ADD_REQUEST';
export const CYDER_PROJECTS_SETTINGS_PROJECT_ADD_SUCCESS = 'CYDER_PROJECTS_SETTINGS_PROJECT_ADD_SUCCESS';
export const CYDER_PROJECTS_SETTINGS_PROJECT_ADD_FAILURE = 'CYDER_PROJECTS_SETTINGS_PROJECT_ADD_FAILURE';

export const CYDER_PROJECTS_SETTINGS_PROJECT_UPDATE_REQUEST = 'CYDER_PROJECTS_SETTINGS_PROJECT_UPDATE_REQUEST';
export const CYDER_PROJECTS_SETTINGS_PROJECT_UPDATE_SUCCESS = 'CYDER_PROJECTS_SETTINGS_PROJECT_UPDATE_SUCCESS';
export const CYDER_PROJECTS_SETTINGS_PROJECT_UPDATE_FAILURE = 'CYDER_PROJECTS_SETTINGS_PROJECT_UPDATE_FAILURE';

export const CYDER_PROJECTS_SETTINGS_PROJECT_DELETE_REQUEST = 'CYDER_PROJECTS_SETTINGS_PROJECT_DELETE_REQUEST';
export const CYDER_PROJECTS_SETTINGS_PROJECT_DELETE_SUCCESS = 'CYDER_PROJECTS_SETTINGS_PROJECT_DELETE_SUCCESS';
export const CYDER_PROJECTS_SETTINGS_PROJECT_DELETE_FAILURE = 'CYDER_PROJECTS_SETTINGS_PROJECT_DELETE_FAILURE';
export const CYDER_PROJECTS_SETTINGS_ADD_USER = 'CYDER_PROJECTS_SETTINGS_ADD_USER';
export const CYDER_PROJECTS_SETTINGS_REMOVE_USER = 'CYDER_PROJECTS_SETTINGS_REMOVE_USER';
export const CYDER_PROJECTS_SETTINGS_ADD_APPROVER = 'CYDER_PROJECTS_SETTINGS_ADD_APPROVER';
export const CYDER_PROJECTS_SETTINGS_REMOVE_APPROVER = 'CYDER_PROJECTS_SETTINGS_REMOVE_APPROVER';

// Locations Settings List
export const CYDER_LOCATIONS_SETTINGS_SEARCH_REQUEST = 'CYDER_LOCATIONS_SETTINGS_SEARCH_REQUEST';
export const CYDER_LOCATIONS_SETTINGS_SEARCH_SUCCESS = 'CYDER_LOCATIONS_SETTINGS_SEARCH_SUCCESS';
export const CYDER_LOCATIONS_SETTINGS_SEARCH_FAILURE = 'CYDER_LOCATIONS_SETTINGS_SEARCH_FAILURE';
export const CYDER_LOCATIONS_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG = 'CYDER_LOCATIONS_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG';

export const CYDER_LOCATIONS_SETTINGS_MODAL_TOGGLE = 'CYDER_LOCATIONS_SETTINGS_MODAL_TOGGLE';
export const CYDER_LOCATIONS_SETTINGS_MODAL_OPEN = 'CYDER_LOCATIONS_SETTINGS_MODAL_OPEN';
export const CYDER_LOCATIONS_SETTINGS_QR_OPEN = 'CYDER_LOCATIONS_SETTINGS_QR_OPEN';
export const CYDER_LOCATIONS_SETTINGS_MODAL_CLOSE = 'CYDER_LOCATIONS_SETTINGS_MODAL_CLOSE';
export const CYDER_LOCATIONS_SETTINGS_MODAL_CHANGE_VALUE = 'CYDER_LOCATIONS_SETTINGS_MODAL_CHANGE_VALUE';
export const CYDER_LOCATIONS_SETTINGS_LOADING = 'CYDER_LOCATIONS_SETTINGS_LOADING';
export const CYDER_LOCATIONS_SETTINGS_MODAL_SET_LOCATION = 'CYDER_LOCATIONS_SETTINGS_MODAL_SET_LOCATION';

export const CYDER_LOCATIONS_SETTINGS_LOCATION_ADD_REQUEST = 'CYDER_LOCATIONS_SETTINGS_LOCATION_ADD_REQUEST';
export const CYDER_LOCATIONS_SETTINGS_LOCATION_ADD_SUCCESS = 'CYDER_LOCATIONS_SETTINGS_LOCATION_ADD_SUCCESS';
export const CYDER_LOCATIONS_SETTINGS_LOCATION_ADD_FAILURE = 'CYDER_LOCATIONS_SETTINGS_LOCATION_ADD_FAILURE';

export const CYDER_LOCATIONS_SETTINGS_LOCATION_UPDATE_REQUEST = 'CYDER_LOCATIONS_SETTINGS_LOCATION_UPDATE_REQUEST';
export const CYDER_LOCATIONS_SETTINGS_LOCATION_UPDATE_SUCCESS = 'CYDER_LOCATIONS_SETTINGS_LOCATION_UPDATE_SUCCESS';
export const CYDER_LOCATIONS_SETTINGS_LOCATION_UPDATE_FAILURE = 'CYDER_LOCATIONS_SETTINGS_LOCATION_UPDATE_FAILURE';

export const CYDER_LOCATIONS_SETTINGS_LOCATION_DELETE_REQUEST = 'CYDER_LOCATIONS_SETTINGS_LOCATION_DELETE_REQUEST';
export const CYDER_LOCATIONS_SETTINGS_LOCATION_DELETE_SUCCESS = 'CYDER_LOCATIONS_SETTINGS_LOCATION_DELETE_SUCCESS';
export const CYDER_LOCATIONS_SETTINGS_LOCATION_DELETE_FAILURE = 'CYDER_LOCATIONS_SETTINGS_LOCATION_DELETE_FAILURE';

export const CYDER_LOCATIONS_SETTINGS_LOCATION_MAP_SET_LOC = 'CYDER_LOCATIONS_SETTINGS_LOCATION_MAP_SET_LOC';
export const CYDER_LOCATIONS_SETTINGS_CHANGE_FIELD_VALUE = 'CYDER_LOCATIONS_SETTINGS_CHANGE_FIELD_VALUE';
export const CYDER_LOCATIONS_SETTINGS_CHANGE_LAT_LNG = 'CYDER_LOCATIONS_SETTINGS_CHANGE_LAT_LNG';

// Payment Modes Settings List
export const CYDER_PAYMENT_MODES_SETTINGS_SEARCH_REQUEST = 'CYDER_PAYMENT_MODES_SETTINGS_SEARCH_REQUEST';
export const CYDER_PAYMENT_MODES_SETTINGS_SEARCH_SUCCESS = 'CYDER_PAYMENT_MODES_SETTINGS_SEARCH_SUCCESS';
export const CYDER_PAYMENT_MODES_SETTINGS_SEARCH_FAILURE = 'CYDER_PAYMENT_MODES_SETTINGS_SEARCH_FAILURE';
export const CYDER_PAYMENT_MODES_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG = 'CYDER_PAYMENT_MODES_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG';

export const CYDER_PAYMENT_MODES_SETTINGS_MODAL_TOGGLE = 'CYDER_PAYMENT_MODES_SETTINGS_MODAL_TOGGLE';
export const CYDER_PAYMENT_MODES_SETTINGS_MODAL_OPEN = 'CYDER_PAYMENT_MODES_SETTINGS_MODAL_OPEN';
export const CYDER_PAYMENT_MODES_SETTINGS_MODAL_CLOSE = 'CYDER_PAYMENT_MODES_SETTINGS_MODAL_CLOSE';
export const CYDER_PAYMENT_MODES_SETTINGS_MODAL_CHANGE_VALUE = 'CYDER_PAYMENT_MODES_SETTINGS_MODAL_CHANGE_VALUE';
export const CYDER_PAYMENT_MODES_SETTINGS_LOADING = 'CYDER_PAYMENT_MODES_SETTINGS_LOADING';
export const CYDER_PAYMENT_MODES_SETTINGS_MODAL_SET_PAYMENT_MODE = 'CYDER_PAYMENT_MODES_SETTINGS_MODAL_SET_PAYMENT_MODE';

export const CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_ADD_REQUEST = 'CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_ADD_REQUEST';
export const CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_ADD_SUCCESS = 'CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_ADD_SUCCESS';
export const CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_ADD_FAILURE = 'CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_ADD_FAILURE';

export const CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_UPDATE_REQUEST = 'CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_UPDATE_REQUEST';
export const CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_UPDATE_SUCCESS = 'CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_UPDATE_SUCCESS';
export const CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_UPDATE_FAILURE = 'CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_UPDATE_FAILURE';

export const CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_DELETE_REQUEST = 'CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_DELETE_REQUEST';
export const CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_DELETE_SUCCESS = 'CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_DELETE_SUCCESS';
export const CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_DELETE_FAILURE = 'CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_DELETE_FAILURE';

export const CYDER_PAYMENT_MODES_SETTINGS_SAVE_VALUE_REQUEST = 'CYDER_PAYMENT_MODES_SETTINGS_SAVE_VALUE_REQUEST';
export const CYDER_PAYMENT_MODES_SETTINGS_SAVE_VALUE_SUCCESS = 'CYDER_PAYMENT_MODES_SETTINGS_SAVE_VALUE_SUCCESS';
export const CYDER_PAYMENT_MODES_SETTINGS_SAVE_VALUE_FAILURE = 'CYDER_PAYMENT_MODES_SETTINGS_SAVE_VALUE_FAILURE';

// Expense Categories Settings List
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_REQUEST = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_REQUEST';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_SUCCESS = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_SUCCESS';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_FAILURE = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_FAILURE';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG';

export const CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_TOGGLE = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_TOGGLE';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_OPEN = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_OPEN';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_CLOSE = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_CLOSE';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_CHANGE_VALUE = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_CHANGE_VALUE';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_LOADING = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_LOADING';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_SET_EXPENSE_CATEGORY = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_MODAL_SET_EXPENSE_CATEGORY';

export const CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_ADD_REQUEST = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_ADD_REQUEST';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_ADD_SUCCESS = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_ADD_SUCCESS';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_ADD_FAILURE = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_ADD_FAILURE';

export const CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_UPDATE_REQUEST = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_UPDATE_REQUEST';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_UPDATE_SUCCESS = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_UPDATE_SUCCESS';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_UPDATE_FAILURE = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_UPDATE_FAILURE';

export const CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_DELETE_REQUEST = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_DELETE_REQUEST';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_DELETE_SUCCESS = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_DELETE_SUCCESS';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_DELETE_FAILURE = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_EXPENSE_CATEGORY_DELETE_FAILURE';

export const CYDER_EXPENSE_CATEGORIES_SETTINGS_SAVE_VALUE_REQUEST = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_SAVE_VALUE_REQUEST';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_SAVE_VALUE_SUCCESS = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_SAVE_VALUE_SUCCESS';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_SAVE_VALUE_FAILURE = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_SAVE_VALUE_FAILURE';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_ADD_APPROVER = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_ADD_APPROVER';
export const CYDER_EXPENSE_CATEGORIES_SETTINGS_REMOVE_APPROVER = 'CYDER_EXPENSE_CATEGORIES_SETTINGS_REMOVE_APPROVER';

// Mileage Categories Settings List
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_SEARCH_REQUEST = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_SEARCH_REQUEST';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_SEARCH_SUCCESS = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_SEARCH_SUCCESS';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_SEARCH_FAILURE = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_SEARCH_FAILURE';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG';

export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MODAL_TOGGLE = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MODAL_TOGGLE';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MODAL_OPEN = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MODAL_OPEN';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MODAL_CLOSE = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MODAL_CLOSE';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MODAL_CHANGE_VALUE = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MODAL_CHANGE_VALUE';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_LOADING = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_LOADING';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MODAL_SET_MILEAGE_CATEGORY = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MODAL_SET_MILEAGE_CATEGORY';

export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_ADD_REQUEST = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_ADD_REQUEST';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_ADD_SUCCESS = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_ADD_SUCCESS';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_ADD_FAILURE = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_ADD_FAILURE';

export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_UPDATE_REQUEST = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_UPDATE_REQUEST';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_UPDATE_SUCCESS = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_UPDATE_SUCCESS';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_UPDATE_FAILURE = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_UPDATE_FAILURE';

export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_DELETE_REQUEST = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_DELETE_REQUEST';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_DELETE_SUCCESS = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_DELETE_SUCCESS';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_DELETE_FAILURE = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_MILEAGE_CATEGORY_DELETE_FAILURE';

export const CYDER_MILEAGE_CATEGORIES_SETTINGS_SAVE_VALUE_REQUEST = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_SAVE_VALUE_REQUEST';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_SAVE_VALUE_SUCCESS = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_SAVE_VALUE_SUCCESS';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_SAVE_VALUE_FAILURE = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_SAVE_VALUE_FAILURE';

export const CYDER_MILEAGE_CATEGORIES_SETTINGS_ADD_APPROVER = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_ADD_APPROVER';
export const CYDER_MILEAGE_CATEGORIES_SETTINGS_REMOVE_APPROVER = 'CYDER_MILEAGE_CATEGORIES_SETTINGS_REMOVE_APPROVER';

// Users List
export const CYDER_USERS_SEARCH_REQUEST = 'CYDER_USERS_SEARCH_REQUEST';
export const CYDER_USERS_SEARCH_SUCCESS = 'CYDER_USERS_SEARCH_SUCCESS';
export const CYDER_USERS_SEARCH_FAILURE = 'CYDER_USERS_SEARCH_FAILURE';
export const CYDER_USERS_SEARCH_SET_FORCE_REFRESH_FLAG = 'CYDER_USERS_SEARCH_SET_FORCE_REFRESH_FLAG';

// Users Detail Page
export const CYDER_USERS_DETAIL_REQUEST = 'CYDER_USERS_DETAIL_REQUEST';
export const CYDER_USERS_DETAIL_SUCCESS = 'CYDER_USERS_DETAIL_SUCCESS';
export const CYDER_USERS_DETAIL_FAILURE = 'CYDER_USERS_DETAIL_FAILURE';
export const CYDER_USERS_DETAIL_CHANGE_FIELD = 'CYDER_USERS_DETAIL_CHANGE_FIELD';
export const CYDER_USERS_DETAIL_SAVE_CHANGES_REQUEST = 'CYDER_USERS_DETAIL_SAVE_CHANGES_REQUEST';
export const CYDER_USERS_DETAIL_SAVE_CHANGES_SUCCESS = 'CYDER_USERS_DETAIL_SAVE_CHANGES_SUCCESS';
export const CYDER_USERS_DETAIL_SAVE_CHANGES_FAILURE = 'CYDER_USERS_DETAIL_SAVE_CHANGES_FAILURE';
export const CYDER_USERS_DETAIL_DELETE_REQUEST = 'CYDER_USERS_DETAIL_DELETE_REQUEST';
export const CYDER_USERS_DETAIL_DELETE_SUCCESS = 'CYDER_USERS_DETAIL_DELETE_SUCCESS';
export const CYDER_USERS_DETAIL_DELETE_FAILURE = 'CYDER_USERS_DETAIL_DELETE_FAILURE';
export const CYDER_USERS_DETAIL_MODAL_TOGGLE = 'CYDER_USERS_DETAIL_MODAL_TOGGLE';
export const CYDER_USERS_DETAIL_FORCE_VALIDATE = 'CYDER_USERS_DETAIL_FORCE_VALIDATE';

// Users Add
export const CYDER_USERS_ADD_CHANGE_FIELD = 'CYDER_USERS_ADD_CHANGE_FIELD';
export const CYDER_USERS_ADD_MESSAGE_MODAL_TOGGLE = 'CYDER_USERS_ADD_MESSAGE_MODAL_TOGGLE';
export const CYDER_USERS_ADD_MESSAGE_MODAL_OPEN = 'CYDER_USERS_ADD_MESSAGE_MODAL_OPEN';
export const CYDER_USERS_ADD_MESSAGE_MODAL_CLOSE = 'CYDER_USERS_ADD_MESSAGE_MODAL_CLOSE';
export const CYDER_USERS_ADD_ADD_USER_FORCE_VALIDATE = 'CYDER_USERS_ADD_ADD_USER_FORCE_VALIDATE';
export const CYDER_USERS_ADD_ADD_USER_REQUEST = 'CYDER_USERS_ADD_ADD_USER_REQUEST';
export const CYDER_USERS_ADD_ADD_USER_SUCCESS = 'CYDER_USERS_ADD_ADD_USER_SUCCESS';
export const CYDER_USERS_ADD_ADD_USER_FAILURE = 'CYDER_USERS_ADD_ADD_USER_FAILURE';
export const CYDER_USERS_ADD_CLEAR_FIELDS = 'CYDER_USERS_ADD_CLEAR_FIELDS';

// Groups List
export const CYDER_GROUPS_SEARCH_REQUEST = 'CYDER_GROUPS_SEARCH_REQUEST';
export const CYDER_GROUPS_SEARCH_SUCCESS = 'CYDER_GROUPS_SEARCH_SUCCESS';
export const CYDER_GROUPS_SEARCH_FAILURE = 'CYDER_GROUPS_SEARCH_FAILURE';

export const CYDER_GROUPS_SEARCH_PAGE_RESET_STATE = 'CYDER_GROUPS_SEARCH_PAGE_RESET_STATE';

// Groups Detail Page
export const CYDER_GROUPS_DETAIL_REQUEST = 'CYDER_GROUPS_DETAIL_REQUEST';
export const CYDER_GROUPS_DETAIL_SUCCESS = 'CYDER_GROUPS_DETAIL_SUCCESS';
export const CYDER_GROUPS_DETAIL_FAILURE = 'CYDER_GROUPS_DETAIL_FAILURE';
export const CYDER_GROUPS_DETAIL_REMOVE_USER_LIST_ADD = 'CYDER_GROUPS_DETAIL_REMOVE_USER_LIST_ADD';
export const CYDER_GROUPS_DETAIL_REMOVE_USER_LIST_REMOVE = 'CYDER_GROUPS_DETAIL_REMOVE_USER_LIST_REMOVE';
export const CYDER_GROUPS_DETAIL_ADD_USER_LIST_ADD = 'CYDER_GROUPS_DETAIL_ADD_USER_LIST_ADD';
export const CYDER_GROUPS_DETAIL_ADD_USER_LIST_REMOVE = 'CYDER_GROUPS_DETAIL_ADD_USER_LIST_REMOVE';
export const CYDER_GROUPS_DETAIL_CHANGE_FIELD = 'CYDER_GROUPS_DETAIL_CHANGE_FIELD';

export const CYDER_GROUPS_DETAIL_MODAL_TOGGLE = 'CYDER_GROUPS_DETAIL_MODAL_TOGGLE';

export const CYDER_GROUPS_DETAIL_SAVE_CHANGES_REQUEST = 'CYDER_GROUPS_DETAIL_SAVE_CHANGES_REQUEST';
export const CYDER_GROUPS_DETAIL_SAVE_CHANGES_SUCCESS = 'CYDER_GROUPS_DETAIL_SAVE_CHANGES_SUCCESS';
export const CYDER_GROUPS_DETAIL_SAVE_CHANGES_FAILURE = 'CYDER_GROUPS_DETAIL_SAVE_CHANGES_FAILURE';
export const CYDER_GROUPS_DETAIL_DELETE_REQUEST = 'CYDER_GROUPS_DETAIL_DELETE_REQUEST';
export const CYDER_GROUPS_DETAIL_DELETE_SUCCESS = 'CYDER_GROUPS_DETAIL_DELETE_SUCCESS';
export const CYDER_GROUPS_DETAIL_DELETE_FAILURE = 'CYDER_GROUPS_DETAIL_DELETE_FAILURE';

// Groups Add Page
export const CYDER_GROUPS_ADD_CHANGE_FIELD = 'CYDER_GROUPS_ADD_CHANGE_FIELD';
export const CYDER_GROUPS_ADD_CLEAR_FIELDS = 'CYDER_GROUPS_ADD_CLEAR_FIELDS';
export const CYDER_GROUPS_ADD_ADD_USER = 'CYDER_GROUPS_ADD_ADD_USER';
export const CYDER_GROUPS_ADD_REMOVE_USER = 'CYDER_GROUPS_ADD_REMOVE_USER';
export const CYDER_GROUPS_ADD_ADD_GROUP_FORCE_VALIDATE = 'CYDER_GROUPS_ADD_ADD_GROUP_FORCE_VALIDATE';
export const CYDER_GROUPS_ADD_ADD_USER_SEARCH_REQUEST = 'CYDER_GROUPS_ADD_ADD_USER_SEARCH_REQUEST';
export const CYDER_GROUPS_ADD_ADD_USER_SEARCH_SUCCESS = 'CYDER_GROUPS_ADD_ADD_USER_SEARCH_SUCCESS';
export const CYDER_GROUPS_ADD_ADD_USER_SEARCH_FAILURE = 'CYDER_GROUPS_ADD_ADD_USER_SEARCH_FAILURE';
export const CYDER_GROUPS_ADD_ADD_GROUP_REQUEST = 'CYDER_GROUPS_ADD_ADD_GROUP_REQUEST';
export const CYDER_GROUPS_ADD_ADD_GROUP_SUCCESS = 'CYDER_GROUPS_ADD_ADD_GROUP_SUCCESS';
export const CYDER_GROUPS_ADD_ADD_GROUP_FAILURE = 'CYDER_GROUPS_ADD_ADD_GROUP_FAILURE';
export const CYDER_GROUPS_ADD_MODAL_TOGGLE = 'CYDER_GROUPS_ADD_MODAL_TOGGLE';

// Expenses
export const CYDER_EXPENSE_SELECT_CATEGORY = 'CYDER_EXPENSE_SELECT_CATEGORY';
export const CYDER_EXPENSE_START = 'CYDER_EXPENSE_START';
export const CYDER_EXPENSE_CANCEL = 'CYDER_EXPENSE_CANCEL';
export const CYDER_EXPENSE_SELECT_PROJECT = 'CYDER_EXPENSE_SELECT_PROJECT';
export const CYDER_EXPENSE_SELECT_EXPENSE_DATE = 'CYDER_EXPENSE_SELECT_EXPENSE_DATE';
export const CYDER_EXPENSE_SELECT_TRAVEL_METHOD = 'CYDER_EXPENSE_SELECT_TRAVEL_METHOD';

// Expenses (new)
export const CYDER_EXPENSE_MYRECEIPTS_REQUEST = 'CYDER_EXPENSE_MYRECEIPTS_REQUEST';
export const CYDER_EXPENSE_MYRECEIPTS_SUCCESS = 'CYDER_EXPENSE_MYRECEIPTS_SUCCESS';
export const CYDER_EXPENSE_MYRECEIPTS_FAILURE = 'CYDER_EXPENSE_MYRECEIPTS_FAILURE';

export const CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX = 'CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX';
export const CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX_ALL = 'CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX_ALL';

export const CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_REQUEST = 'CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_REQUEST';
export const CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_SUCCESS = 'CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_SUCCESS';
export const CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_FAILURE = 'CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_FAILURE';

export const CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_REQUEST = 'CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_REQUEST';
export const CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_SUCCESS = 'CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_SUCCESS';
export const CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_FAILURE = 'CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_FAILURE';

export const CYDER_EXPENSE_MYRECEIPTS_MODAL_TOGGLE = 'CYDER_EXPENSE_MYRECEIPTS_MODAL_TOGGLE';

// My Reports Page
export const CYDER_EXPENSE_MYREPORTS_GET_REPORTS_REQUEST = 'CYDER_EXPENSE_MYREPORTS_GET_REPORTS_REQUEST';
export const CYDER_EXPENSE_MYREPORTS_GET_REPORTS_SUCCESS = 'CYDER_EXPENSE_MYREPORTS_GET_REPORTS_SUCCESS';
export const CYDER_EXPENSE_MYREPORTS_GET_REPORTS_FAILURE = 'CYDER_EXPENSE_MYREPORTS_GET_REPORTS_FAILURE';

// Page
export const SET_NAVIGATION = 'SET_NAVIGATION';
export const PAGE_SET_JUMBOTRON_TEXT = 'PAGE_SET_JUMBOTRON_TEXT';

// Profile
export const CYDER_PROFILE_CHANGE_VALUE = 'CYDER_PROFILE_CHANGE_VALUE';
export const CYDER_PROFILE_SAVE = 'CYDER_PROFILE_SAVE';

export const CYDER_PROFILE_MODAL_TOGGLE = 'CYDER_PROFILE_MODAL_TOGGLE';

export const CYDER_PROFILE_SAVE_CHANGES_REQUEST = 'CYDER_PROFILE_SAVE_CHANGES_REQUEST';
export const CYDER_PROFILE_SAVE_CHANGES_SUCCESS = 'CYDER_PROFILE_SAVE_CHANGES_SUCCESS';
export const CYDER_PROFILE_SAVE_CHANGES_FAILURE = 'CYDER_PROFILE_SAVE_CHANGES_FAILURE';

export const CYDER_PROFILE_SAVE_PROFILEPIC_REQUEST = 'CYDER_PROFILE_SAVE_PROFILEPIC_REQUEST';
export const CYDER_PROFILE_SAVE_PROFILEPIC_SUCCESS = 'CYDER_PROFILE_SAVE_PROFILEPIC_SUCCESS';
export const CYDER_PROFILE_SAVE_PROFILEPIC_FAILURE = 'CYDER_PROFILE_SAVE_PROFILEPIC_FAILURE';
export const CYDER_PROFILE_FORCE_VALIDATE = 'CYDER_PROFILE_FORCE_VALIDATE';
export const CYDER_PROFILE_REFRESH_OVERRIDE_REQUEST = 'CYDER_PROFILE_REFRESH_OVERRIDE_REQUEST';
export const CYDER_PROFILE_REFRESH_OVERRIDE_SUCCESS = 'CYDER_PROFILE_REFRESH_OVERRIDE_SUCCESS';
export const CYDER_PROFILE_REFRESH_OVERRIDE_FAILURE = 'CYDER_PROFILE_REFRESH_OVERRIDE_FAILURE';

// Cyder Dev
export const CYDER_DEV_CACHE_CURRENT_PROFILE = 'CYDER_DEV_CACHE_CURRENT_PROFILE';
export const CYDER_DEV_REMOVE_CACHED_PROFILE = 'CYDER_DEV_REMOVE_CACHED_PROFILE';
export const CYDER_DEV_CACHE_FAKE_PROFILE = 'CYDER_DEV_CACHE_FAKE_PROFILE';
export const CYDER_DEV_RECHECK_PROFILE = 'CYDER_DEV_RECHECK_PROFILE';

// Expense New Receipt Claim
export const CYDER_EXPENSE_NEW_RECEIPT_MODAL_TOGGLE = 'CYDER_EXPENSE_NEW_RECEIPT_MODAL_TOGGLE';
export const CYDER_EXPENSE_NEW_RECEIPT_CHANGE_FIELD = 'CYDER_EXPENSE_NEW_RECEIPT_CHANGE_FIELD';
export const CYDER_EXPENSE_NEW_RECEIPT_FORCE_LOADING_STATE = 'CYDER_EXPENSE_NEW_RECEIPT_FORCE_LOADING_STATE';
export const CYDER_EXPENSE_NEW_RECEIPT_FORCE_VALIDATE = 'CYDER_EXPENSE_NEW_RECEIPT_FORCE_VALIDATE';

export const CYDER_EXPENSE_NEW_RECEIPT_SAVE_REQUEST = 'CYDER_EXPENSE_NEW_RECEIPT_SAVE_REQUEST';
export const CYDER_EXPENSE_NEW_RECEIPT_SAVE_SUCCESS = 'CYDER_EXPENSE_NEW_RECEIPT_SAVE_SUCCESS';
export const CYDER_EXPENSE_NEW_RECEIPT_SAVE_FAILURE = 'CYDER_EXPENSE_NEW_RECEIPT_SAVE_FAILURE';

export const CYDER_EXPENSE_NEW_RECEIPT_CHECK_DUPLICATION_REQUEST = 'CYDER_EXPENSE_NEW_RECEIPT_CHECK_DUPLICATION_REQUEST';
export const CYDER_EXPENSE_NEW_RECEIPT_CHECK_DUPLICATION_SUCCESS = 'CYDER_EXPENSE_NEW_RECEIPT_CHECK_DUPLICATION_SUCCESS';
export const CYDER_EXPENSE_NEW_RECEIPT_CHECK_DUPLICATION_FAILURE = 'CYDER_EXPENSE_NEW_RECEIPT_CHECK_DUPLICATION_FAILURE';

export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_REQUEST = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_REQUEST';
export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_SUCCESS = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_SUCCESS';
export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_FAILURE = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_FAILURE';

export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_INFO_REQUEST = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_REQUEST';
export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_INFO_SUCCESS = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_SUCCESS';
export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_INFO_FAILURE = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_FAILURE';
export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_REQUEST = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_REQUEST';
export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_SUCCESS = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_SUCCESS';
export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_FAILURE = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_FAILURE';

export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_GET_BLOCKCHAIN_INFO_REQUEST = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_GET_BLOCKCHAIN_INFO_REQUEST';
export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_GET_BLOCKCHAIN_INFO_SUCCESS = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_GET_BLOCKCHAIN_INFO_SUCCESS';
export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_GET_BLOCKCHAIN_INFO_FAILURE = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_GET_BLOCKCHAIN_INFO_FAILURE';

export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_REQUEST = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_REQUEST';
export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_SUCCESS = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_SUCCESS';
export const CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_FAILURE = 'CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_FAILURE';

export const CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_REQUEST = 'CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_REQUEST';
export const CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_SUCCESS = 'CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_SUCCESS';
export const CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_FAILURE = 'CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_FAILURE';

export const CYDER_EXPENSE_NEW_RECEIPT_SET_FIELDS = 'CYDER_EXPENSE_NEW_RECEIPT_SET_FIELDS';

// Expense New Mileage Claim
export const CYDER_EXPENSE_NEW_MILEAGE_CHANGE_FIELD = 'CYDER_EXPENSE_NEW_MILEAGE_CHANGE_FIELD';

export const CYDER_EXPENSE_NEW_MILEAGE_SAVE_REQUEST = 'CYDER_EXPENSE_NEW_MILEAGE_SAVE_REQUEST';
export const CYDER_EXPENSE_NEW_MILEAGE_SAVE_SUCCESS = 'CYDER_EXPENSE_NEW_MILEAGE_SAVE_SUCCESS';
export const CYDER_EXPENSE_NEW_MILEAGE_SAVE_FAILURE = 'CYDER_EXPENSE_NEW_MILEAGE_SAVE_FAILURE';

export const CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_REQUEST = 'CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_REQUEST';
export const CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_SUCCESS = 'CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_SUCCESS';
export const CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_FAILURE = 'CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_FAILURE';

export const CYDER_EXPENSE_NEW_MILEAGE_SET_FIELDS = 'CYDER_EXPENSE_NEW_MILEAGE_SET_FIELDS';
export const CYDER_EXPENSE_NEW_MILEAGE_RESET_FIELDS = 'CYDER_EXPENSE_NEW_MILEAGE_RESET_FIELDS';
export const CYDER_EXPENSE_NEW_MILEAGE_GET_SAVED_RECEIPT_REQUEST = 'CYDER_EXPENSE_NEW_MILEAGE_GET_SAVED_RECEIPT_REQUEST';
export const CYDER_EXPENSE_NEW_MILEAGE_GET_SAVED_RECEIPT_SUCCESS = 'CYDER_EXPENSE_NEW_MILEAGE_GET_SAVED_RECEIPT_SUCCESS';
export const CYDER_EXPENSE_NEW_MILEAGE_GET_SAVED_RECEIPT_FAILURE = 'CYDER_EXPENSE_NEW_MILEAGE_GET_SAVED_RECEIPT_FAILURE';
export const CYDER_EXPENSE_NEW_MILEAGE_FORCE_LOADING_STATE = 'CYDER_EXPENSE_NEW_MILEAGE_FORCE_LOADING_STATE';
export const CYDER_EXPENSE_NEW_MILEAGE_MODAL_TOGGLE = 'CYDER_EXPENSE_NEW_MILEAGE_MODAL_TOGGLE';

// Shared
export const CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_REQUEST = 'CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_REQUEST';
export const CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_SUCCESS = 'CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_SUCCESS';
export const CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_FAILURE = 'CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_FAILURE';
export const CYDER_EXPENSE_SHARED_GET_PROJECTS_REQUEST = 'CYDER_EXPENSE_SHARED_GET_PROJECTS_REQUEST';
export const CYDER_EXPENSE_SHARED_GET_PROJECTS_SUCCESS = 'CYDER_EXPENSE_SHARED_GET_PROJECTS_SUCCESS';
export const CYDER_EXPENSE_SHARED_GET_PROJECTS_FAILURE = 'CYDER_EXPENSE_SHARED_GET_PROJECTS_FAILURE';
export const CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_REQUEST = 'CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_REQUEST';
export const CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_SUCCESS = 'CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_SUCCESS';
export const CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_FAILURE = 'CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_FAILURE';
export const CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_REQUEST = 'CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_REQUEST';
export const CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_SUCCESS = 'CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_SUCCESS';
export const CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_FAILURE = 'CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_FAILURE';
export const CYDER_EXPENSE_SHARED_GET_LOCATIONS_REQUEST = 'CYDER_EXPENSE_SHARED_GET_LOCATIONS_REQUEST';
export const CYDER_EXPENSE_SHARED_GET_LOCATIONS_SUCCESS = 'CYDER_EXPENSE_SHARED_GET_LOCATIONS_SUCCESS';
export const CYDER_EXPENSE_SHARED_GET_LOCATIONS_FAILURE = 'CYDER_EXPENSE_SHARED_GET_LOCATIONS_FAILURE';
export const CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_REQUEST = 'CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_REQUEST';
export const CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_SUCCESS = 'CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_SUCCESS';
export const CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_FAILURE = 'CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_FAILURE';
export const CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_REQUEST = 'CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_REQUEST';
export const CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_SUCCESS = 'CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_SUCCESS';
export const CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_FAILURE = 'CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_FAILURE';
export const CYDER_EXPENSE_SHARED_GET_RELEVANT_ITEMS_SUCCESS = 'CYDER_EXPENSE_SHARED_GET_RELEVANT_ITEMS_SUCCESS';

// Reports
export const CYDER_REPORT_EXPENSE_RECEIPT_BY_CATEGORY = 'CYDER_REPORT_EXPENSE_RECEIPT_BY_CATEGORY';
export const CYDER_REPORT_EXPENSE_MILEAGE_BY_CATEGORY = 'CYDER_REPORT_EXPENSE_MILEAGE_BY_CATEGORY';
export const CYDER_REPORT_EXPENSE_RECEIPT_BY_PROJECT = 'CYDER_REPORT_EXPENSE_RECEIPT_BY_PROJECT';
export const CYDER_REPORT_EXPENSE_MILEAGE_BY_PROJECT = 'CYDER_REPORT_EXPENSE_MILEAGE_BY_PROJECT';
export const CYDER_REPORT_EXPENSE_LOADING_SHOW = 'CYDER_REPORT_EXPENSE_LOADING_SHOW';
export const CYDER_REPORT_EXPENSE_LOADING_HIDE = 'CYDER_REPORT_EXPENSE_LOADING_HIDE';
export const CYDER_REPORT_EXPENSE_START_DATE_CHANGED = 'CYDER_REPORT_EXPENSE_START_DATE_CHANGED';
export const CYDER_REPORT_EXPENSE_END_DATE_CHANGED = 'CYDER_REPORT_EXPENSE_END_DATE_CHANGED';
export const CYDER_REPORT_EXPENSE_STATUS_CHANGED = 'CYDER_REPORT_EXPENSE_STATUS_CHANGED';
export const CYDER_REPORT_EXPENSE_RESET_FORM = 'CYDER_REPORT_EXPENSE_RESET_FORM';
