export const CYDER_WORKFLOW_MYTASKS_GET_MYTASKS_REQUEST = 'CYDER_WORKFLOW_MYTASKS_GET_MYTASKS_REQUEST';
export const CYDER_WORKFLOW_MYTASKS_GET_MYTASKS_SUCCESS = 'CYDER_WORKFLOW_MYTASKS_GET_MYTASKS_SUCCESS';
export const CYDER_WORKFLOW_MYTASKS_GET_MYTASKS_FAILURE = 'CYDER_WORKFLOW_MYTASKS_GET_MYTASKS_FAILURE';
export const CYDER_WORKFLOW_MYTASKS_MODAL_TOGGLE = 'CYDER_WORKFLOW_MYTASKS_MODAL_TOGGLE';

export const CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_REQUEST = 'CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_REQUEST';
export const CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_SUCCESS = 'CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_SUCCESS';
export const CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_FAILURE = 'CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_FAILURE';

export const CYDER_WORKFLOW_TASKS_DETAIL_APPROVE_REJECT_TASK_REQUEST = 'CYDER_WORKFLOW_TASKS_DETAIL_APPROVE_REJECT_TASK_REQUEST';
export const CYDER_WORKFLOW_TASKS_DETAIL_APPROVE_REJECT_TASK_SUCCESS = 'CYDER_WORKFLOW_TASKS_DETAIL_APPROVE_REJECT_TASK_SUCCESS';
export const CYDER_WORKFLOW_TASKS_DETAIL_APPROVE_REJECT_TASK_FAILURE = 'CYDER_WORKFLOW_TASKS_DETAIL_APPROVE_REJECT_TASK_FAILURE';

export const CYDER_WORKFLOW_TASKS_DETAIL_PAYMENT_SET_STATUS_REQUEST = 'CYDER_WORKFLOW_TASKS_DETAIL_PAYMENT_SET_STATUS_REQUEST';
export const CYDER_WORKFLOW_TASKS_DETAIL_PAYMENT_SET_STATUS_SUCCESS = 'CYDER_WORKFLOW_TASKS_DETAIL_PAYMENT_SET_STATUS_SUCCESS';
export const CYDER_WORKFLOW_TASKS_DETAIL_PAYMENT_SET_STATUS_FAILURE = 'CYDER_WORKFLOW_TASKS_DETAIL_PAYMENT_SET_STATUS_FAILURE';

export const CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_IMAGE_REQUEST = 'CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_IMAGE_REQUEST';
export const CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_IMAGE_SUCCESS = 'CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_IMAGE_SUCCESS';
export const CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_IMAGE_FAILURE = 'CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_IMAGE_FAILURE';

export const CYDER_WORKFLOW_TASKS_DETAIL_MODAL_TOGGLE = 'CYDER_WORKFLOW_TASKS_DETAIL_MODAL_TOGGLE';
export const CYDER_WORKFLOW_TASKS_DETAIL_FORCE_LOADING_STATE = 'CYDER_WORKFLOW_TASKS_DETAIL_FORCE_LOADING_STATE';
