import React from 'react';
import Menu from './Menu';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';

import '../../scss/elements/left-sidebar-1.scss';

const LeftSidebar = ({ navigation, role }) => {
    return (
        <div>
            {/* <div className="left-sidebar-placeholder" /> */}
            <div className="left-sidebar left-sidebar-1">
                <div className="wrapper">
                    <div className="content">
                        {navigation.map((menu, i) => {
                            const { accessLevel, show } = menu;
                            if (!show || (accessLevel && !accessLevel.includes(role))) return null;

                            return (
                                <div key={i} className="section">
                                    {navigation.length === i + 1 ? <hr style={{ background: 'white', width: '85%' }} /> : null}
                                    <div className="section-title">
                                        <Trans>{'sidemenu:' + menu.title}</Trans>
                                    </div>
                                    <ul className="list-unstyled">
                                        {menu.items.map((items, i) => (
                                            <Menu key={i} role={role} items={items} />
                                        ))}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        role: state.cyderLoginReducer.userData.role,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LeftSidebar));
