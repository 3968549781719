import React from 'react';
import { Switch, Route } from 'react-router';

// COMPONENTS
import asyncImport from 'cyderComponents/asyncImport/asyncImport';

// PRIVATE ROUTE
import PrivateRoute from '../cyderComponents/common/PrivateRoute';

//PAGES
const Home = asyncImport(() => import('../pages/home/leaveTypeHome'));
const Login = asyncImport(() => import('../pages/login'));
const ChangePassword = asyncImport(() => import('../pages/changepassword/ChangePassword'));
const ForgotPassword = asyncImport(() => import('../pages/forgotpassword/ForgotPassword'));
const Profile = asyncImport(() => import('../pages/profile'));

// SETTINGS
const MainSettingsPage = asyncImport(() => import('../pages/settings/MainSettingsPage'));
const LeaveBalanceSettingsPage = asyncImport(() => import('../pages/settings/leave/LeaveBalanceSettingsPage'));

// TIMESHEETS
const ClockPage = asyncImport(() => import('../pages/timesheet/ClockPage'));
const MyClocksPage = asyncImport(() => import('../pages/timesheet/MyClocksPage'));
const MonthlyTimesheetPage = asyncImport(() => import('../pages/timesheet/MonthlyTimesheetPage'));
const YearlyTimesheetPage = asyncImport(() => import('../pages/timesheet/YearlyTimesheetPage'));
const YearlyTimesheetDetailPage = asyncImport(() => import('../pages/timesheet/YearlyTimesheetDetailPage'));
const TimesheetApprovalPage = asyncImport(() => import('../pages/timesheet/TimesheetApprovalPage'));
const TimesheetTaskDetailPage = asyncImport(() => import('../pages/timesheet/TimesheetTaskDetailPage'));

// LEAVES
const LeavePlanPage = asyncImport(() => import('../pages/leaves/LeavePlanPage'));
const LeaveApprovalPage = asyncImport(() => import('../pages/leaves/LeaveApprovalPage'));
const LeavePlanDetailPage = asyncImport(() => import('../pages/leaves/LeavePlanDetailPage'));
const LeaveTasksDetailPage = asyncImport(() => import('../pages/leaves/LeaveTasksDetailPage'));
const LeaveCalendar = asyncImport(() => import('../pages/leaves/LeaveCalendar'));

// COMMON
const ErrorPage = asyncImport(() => import('../pages/error-page'));

const LeaveTypeRoutes = () => (
    <div>
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/changepassword" component={ChangePassword} />
            <Route exact path="/changepassword/:token" component={ChangePassword} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <PrivateRoute exact path="/profile" component={Profile} />
            {/* GENERAL */}
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/leavetype/home" component={Home} />
            {/* TIMESHEETS */}
            <PrivateRoute exact path="/leavetype/timesheet/clock" component={ClockPage} />
            <PrivateRoute exact path="/leavetype/timesheet/myclocks" component={MyClocksPage} />
            <PrivateRoute exact path="/leavetype/timesheet/monthlytimesheet" component={MonthlyTimesheetPage} />
            <PrivateRoute exact path="/leavetype/timesheet/yearlytimesheet" component={YearlyTimesheetPage} />
            <PrivateRoute exact path="/leavetype/timesheet/yearlytimesheet/:taskid" component={YearlyTimesheetDetailPage} />
            <PrivateRoute exact path="/leavetype/timesheet/timesheetapproval" component={TimesheetApprovalPage} />
            <PrivateRoute exact path="/leavetype/timesheet/timesheetapproval/detail/:taskid" component={TimesheetTaskDetailPage} />

            {/* LEAVES */}
            <PrivateRoute exact path="/leavetype/leaves/leaveplan/:apply" component={LeavePlanPage} />
            <PrivateRoute exact path="/leavetype/leaves/leaveplan" component={LeavePlanPage} />
            <PrivateRoute exact path="/leavetype/leaves/leaveplan/detail/:id" component={LeavePlanDetailPage} />
            <PrivateRoute exact path="/leavetype/leaves/leaveapproval" component={LeaveApprovalPage} />
            <PrivateRoute path="/leavetype/leaves/leavetask/detail/:taskid" component={LeaveTasksDetailPage} />
            <PrivateRoute path="/leavetype/leaves/leavecalendar" component={LeaveCalendar} />

            {/* SETTINGS */}
            <PrivateRoute exact path="/settings/leave/leavebalance" component={MainSettingsPage} />
            <PrivateRoute exact path="/settings/leave/leavebalance/:email" component={LeaveBalanceSettingsPage} />
            <PrivateRoute path="/settings/leave/leavetype" component={MainSettingsPage} />
            <PrivateRoute path="/settings/leave" component={MainSettingsPage} />

            {/* ERROR PAGE */}
            <PrivateRoute path="/" component={ErrorPage} />
        </Switch>
    </div>
);

export default LeaveTypeRoutes;
