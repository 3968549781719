import React from 'react';
import { connect } from 'react-redux';
import {
    Redirect,
    // HashRouter,
    Route,
    // Switch,
} from 'react-router-dom';

const debugMode = false;

const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return React.createElement(component, finalProps);
};

const PrivateRoute = ({ role, accessLevel = 0, loggedIn, component, headerTitle, ...rest }) => {
    return (
        <Route
            {...rest}
            // render={props => renderMergedProps(component, props, rest)}
            render={props =>
                debugMode || (loggedIn && role !== undefined && role >= accessLevel) ? (
                    renderMergedProps(component, props, rest)
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        loggedIn: state.cyderLoginReducer.loggedIn,
        role: state.cyderLoginReducer.userData.role,
    };
};
const mapDispatchToProps = () => {
    return {};
};
// translate() is if we want to use HOC to perform t()
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
