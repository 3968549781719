import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router';
import history from './history';

// UI ELEMENTS
import { Card, CardBody } from 'reactstrap';
import { ConfirmModal } from 'cyderComponents/ConfirmModal';

// ACTIONS
import { switchNavMenu, setConfig } from 'actions/config';

// ROUTES
import ExpenseRoutes from './routes/ExpenseRoutes';
import LeaveTypeRoutes from './routes/LeaveTypeRoutes';
import MyCorpRoutes from './routes/MyCorpRoutes';

// GLOBAL CSS
import 'bootstrap/dist/css/bootstrap.css';
import 'react-viewer/dist/index.css';
import './scss/main.scss';

// CYDER CUSTOM
import './css/material-design-input.css';
import './scss/custom.scss';

// REACT-BOOTSTRAP TABLE
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import 'react-table/react-table.css';
import './css/react-table-custom.css';

//STRUCTURAL ELEMENTS
import LeftSidebar from './elements/left-sidebar';
import Navbar1 from './elements/navbar-1';
import TopNavigation1 from './elements/top-navigation-1';
import MyCorpNavBar from './elements/mycorp-navbar';
import Jumbotron from './elements/jumbotron';
import Backdrops from './elements/backdrops';
import Footer from './cyderComponents/common/Footer';

// CSS
import './scss/icons/flags.scss';
import './scss/icons/font-awesome.scss';
import './scss/icons/ionicons.scss';
import './scss/icons/material-design-icons.scss';
import './scss/icons/simple-line-icons.scss';
import './scss/icons/weather-icons.scss';

import './scss/ui-elements/alerts.scss';
import './scss/ui-elements/modals.scss';

import './scss/ui-elements/badges.scss';
import './scss/ui-elements/breadcrumbs.scss';
import './scss/ui-elements/buttons.scss';
import './scss/ui-elements/images.scss';
import './scss/ui-elements/lists.scss';
import './scss/ui-elements/pagination.scss';
import './scss/ui-elements/progress-bars.scss';
import './scss/ui-elements/tabs.scss';
import './scss/ui-elements/typography.scss';
import './scss/ui-elements/tooltips.scss';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mounted: false,
        };
    }

    componentDidMount() {
        this.determineNavMenu();
        this.setState({ ...this.state, ...{ mounted: true } });
    }
    determineNavMenu = () => {
        // IF PAGE RELOAD, DETERMINE NAVIGATION TYPE BY URL
        const url = window.location.pathname;
        const isLeaveType = url.includes('leave');
        const ismycorp = url.includes('mycorp');
        const navType = ismycorp ? 'mycorp' : isLeaveType ? 'leaveTypeNavigation' : 'expenseNavigation';
        this.props.switchNavMenu(navType);

        if (!this.props.loggedIn) {
            this.props.setLayout('empty-view-2');
        }
    };
    render() {
        if (!this.state.mounted) return <div></div>;

        const { layout, background, navbar, topNavigation, logo, leftSidebar, collapsed, navigationMenu, mycorpView } = this.props.config;
        // console.log("mycorpView: ", mycorpView, ", navigationMenu: ", navigationMenu)
        const isEmptyView = layout === 'empty-view-1';
        const isEmptyView2 = layout === 'empty-view-2';
        return (
            <ConnectedRouter history={history}>
                <div
                    className={isEmptyView || isEmptyView2 ? 'cf-height-calc' : 'containerFlex'}
                    data-layout={layout}
                    data-background={background}
                    data-navbar={navbar}
                    data-top-navigation={topNavigation}
                    data-logo={logo}
                    data-left-sidebar={leftSidebar}
                    data-collapsed={collapsed}
                >
                    <ConfirmModal />
                    <Backdrops />
                    {!mycorpView && navigationMenu !== 'mycorp' && <Navbar1 persistor={this.props.persistor} />}
                    {!mycorpView && navigationMenu !== 'mycorp' && <TopNavigation1 />}
                    {/* {(mycorpView || navigationMenu === 'mycorp') && <MyCorpNavBar persistor={this.props.persistor} />} */}
                    <Switch>
                        <Route
                            render={props => (
                                <RouteRest
                                    isEmptyView={isEmptyView}
                                    isEmptyView2={isEmptyView2}
                                    jumbotronText={this.props.jumbotronText}
                                    navigationMenu={navigationMenu}
                                    mycorpView={mycorpView}
                                    {...props}
                                />
                            )}
                        />
                    </Switch>
                    {/* {!mycorpView && navigationMenu !== 'mycorp' && <Footer />} */}
                </div>
            </ConnectedRouter>
        );
    }
}

const RouteRest = props => {
    const { isEmptyView2, isEmptyView, jumbotronText, navigationMenu, mycorpView } = props;
    return (
        <div
            style={isEmptyView2 ? { height: '100%' } : null}
            className={
                isEmptyView || isEmptyView2
                    ? 'mainContent d-flex flex-column justify-content-start'
                    : isEmptyView2
                    ? 'mainContent'
                    : !mycorpView && navigationMenu !== 'mycorp'
                    ? 'container-fluid mainContent'
                    : ''
            }
        >
            <div
                style={isEmptyView2 ? { height: '100%' } : null}
                className={isEmptyView ? 'd-flex justify-content-center' : isEmptyView2 ? 'mainContentChild row' : 'row'}
            >
                {!mycorpView && navigationMenu !== 'mycorp' && <LeftSidebar />}
                {/* <div className={isEmptyView2 ? 'col-sm-12 col-md-6 col-lg-7 col-xl-8' : 'd-none'} /> */}
                <div className={mycorpView || navigationMenu === 'mycorp' ? 'mycorp-main' : isEmptyView ? 'main' : 'col main'}>
                    {!mycorpView && navigationMenu !== 'mycorp' && <Jumbotron jumbotronText={jumbotronText} />}
                    <CardViewWrapper isEmptyView2={isEmptyView2}>
                        <DjarvisRoutes navMenuType={navigationMenu} />
                    </CardViewWrapper>
                </div>
            </div>
        </div>
    );
};

const DjarvisRoutes = ({ navMenuType }) => {
    if (navMenuType === 'expenseNavigation') return <ExpenseRoutes />;
    if (navMenuType === 'leaveTypeNavigation') return <LeaveTypeRoutes />;
    if (navMenuType === 'mycorp') return <MyCorpRoutes />;
    return '';
};

const CardViewWrapper = ({ isEmptyView2, children }) => {
    if (!isEmptyView2) return children;
    return (
        <Card className="full-transparent login-card" style={{ height: '100%' }}>
            <CardBody className="d-flex div-center">{children}</CardBody>
        </Card>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        config: state.config,
        jumbotronText: state.pagedata.jumbotronText,
        loggedIn: state.cyderLoginReducer.loggedIn,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        switchNavMenu: navType => dispatch(switchNavMenu(navType)),
        setLayout: layout => dispatch(setConfig('layout', layout)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
