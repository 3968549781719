import {
    CYDER_USERS_ADD_CHANGE_FIELD,
    CYDER_USERS_ADD_ADD_USER_FORCE_VALIDATE,
    CYDER_USERS_ADD_ADD_USER_REQUEST,
    CYDER_USERS_ADD_ADD_USER_SUCCESS,
    CYDER_USERS_ADD_ADD_USER_FAILURE,
    CYDER_USERS_ADD_MESSAGE_MODAL_TOGGLE,
    CYDER_USERS_ADD_MESSAGE_MODAL_OPEN,
    CYDER_USERS_ADD_MESSAGE_MODAL_CLOSE,
    CYDER_USERS_ADD_CLEAR_FIELDS,
} from '../../actions/constants/actionTypes' 
import {
    SETTINGS_DEPARTMENTS_PROJECT_ADD_REQUEST,
    SETTINGS_DEPARTMENTS_PROJECT_ADD_SUCCESS,
    SETTINGS_DEPARTMENTS_PROJECT_ADD_FAILURE,
} from "../../actions/constants/actionTypesSettings";

import cyderlib from '../../js/cyderlib';

// defaults
var cyderUsersAddState = {
    fields: {
        email: "",
        email2: "",
        firstname: "",
        lastname: "",
        password: "",
        confirmpassword: "",
        mobilenumber: "",
        officenumber: "",
        homenumber: "",
        companyname: "",
        companyaddress: "",
        homeaddress: "",
        dateofbirth: "",
        idno: "",
        idtype: "NRIC",
        department_code: "",
        admin: false,
    },
    validation: {},
    saveButtonPressed: false,
    loading: false,
    saving: false,
    saved: false,
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
}

export function cyderUsersAddReducer(state = cyderUsersAddState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case CYDER_USERS_ADD_CHANGE_FIELD:
            newState.fields = Object.assign({}, state.fields);
            newState.validation = Object.assign({}, state.validation);

            newState.fields[action.key] = action.value;

            let pw = null;
            if (action.key === "confirmpassword") {
                pw = state.fields['password'];
            }
            newState.validation[action.key] = cyderlib.validate(action.key, action.value, pw);
            return newState;
        case CYDER_USERS_ADD_ADD_USER_FORCE_VALIDATE:
            newState.fields = Object.assign({}, state.fields);
            newState.validation = Object.assign({}, state.validation);
            for (var k in state.fields) {
                let pw = null;
                if (k === "confirmpassword") {
                    pw = state.fields['password'];
                }
                newState.validation[k] = cyderlib.validate(k, state.fields[k], pw);
            }
            newState.saveButtonPressed = true;
            return newState;
        case CYDER_USERS_ADD_ADD_USER_REQUEST:
            // newState.fields = Object.assign({}, state.fields);
            // newState.validation = Object.assign({}, state.validation);
            newState.saving = true;
            return newState;
        case CYDER_USERS_ADD_ADD_USER_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "User Successfully added";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "redirect";
            return newState;
        case CYDER_USERS_ADD_ADD_USER_FAILURE:
            newState.modalMessage = "Failed to save user. " + action.response.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_USERS_ADD_MESSAGE_MODAL_TOGGLE:
            newState.fields = Object.assign({}, state.fields);
            newState.validation = Object.assign({}, state.validation);
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === "save") {
                newState.modalMessage = "Are you sure you want to save this user?";
                newState.saved = false;
                newState.modalHeader = "Add User";
            } else if (action.modalAction === "adddepartment") {
                newState.modalMessage = null;
                newState.saved = false;
                newState.modalHeader = "Add Department";
            } else if (action.modalAction === "delete") {
                newState.modalMessage = "Are you sure you want to delete this user?";
                newState.modalHeader = "Delete User";
                newState.saved = false;
            }
            return newState;
        case CYDER_USERS_ADD_MESSAGE_MODAL_OPEN:
            newState.fields = Object.assign({}, state.fields);
            newState.validation = Object.assign({}, state.validation);
            newState.modalOpen = true;
            return newState;
        case CYDER_USERS_ADD_MESSAGE_MODAL_CLOSE:
            newState.fields = Object.assign({}, state.fields);
            newState.validation = Object.assign({}, state.validation);
            newState.modalOpen = false;
            return newState;
        case CYDER_USERS_ADD_CLEAR_FIELDS:
            newState.fields = cyderUsersAddState.fields;
            newState.validation = cyderUsersAddState.validation;
            newState.saveButtonPressed = false;
            return newState;
        case SETTINGS_DEPARTMENTS_PROJECT_ADD_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case SETTINGS_DEPARTMENTS_PROJECT_ADD_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Department has been successfully added";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case SETTINGS_DEPARTMENTS_PROJECT_ADD_FAILURE:
            newState.modalMessage = "Failed to add project: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        default:
            return state;
    }
}
