import {
    CYDER_GROUPS_SEARCH_REQUEST,
    CYDER_GROUPS_SEARCH_SUCCESS,
    CYDER_GROUPS_SEARCH_FAILURE,
    CYDER_GROUPS_SEARCH_PAGE_RESET_STATE,
} from '../../actions/constants/actionTypes' 

// defaults
var cyderGroupsInitialState = {
    loading: false,
    groups: []
}

export function cyderGroupsSearchReducer(state = cyderGroupsInitialState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
    case CYDER_GROUPS_SEARCH_REQUEST:
        newState.loading = true;
        newState.errorMessage = null;
        return newState;
    case CYDER_GROUPS_SEARCH_SUCCESS:
        newState.loading = false;
        newState.groups = action.response;
        return newState;
    case CYDER_GROUPS_SEARCH_FAILURE:
        newState.loading = false;
        newState.errorMessage = action.error.message;
        newState.groups = [];
        return newState;
    case CYDER_GROUPS_SEARCH_PAGE_RESET_STATE:
        return cyderGroupsInitialState;
    default:
        return state;
    }
}
