import {
    PAYMENTS_LIST_GET_EXPENSES_REQUEST,
    PAYMENTS_LIST_GET_EXPENSES_SUCCESS,
    PAYMENTS_LIST_GET_EXPENSES_FAILURE,
    PAYMENTS_LIST_GET_REPORTS_REQUEST,
    PAYMENTS_LIST_GET_REPORTS_SUCCESS,
    PAYMENTS_LIST_GET_REPORTS_FAILURE,
    PAYMENTS_LIST_DELETE_RECEIPT_REQUEST,
    PAYMENTS_LIST_DELETE_RECEIPT_SUCCESS,
    PAYMENTS_LIST_DELETE_RECEIPT_FAILURE,
    PAYMENTS_LIST_TOGGLE_CHECKBOX,
    PAYMENTS_LIST_TOGGLE_CHECKBOX_ALL,
    PAYMENTS_LIST_MODAL_TOGGLE,
} from '../../actions/constants/actionTypesPayments' 

// defaults
var defaultState = {
    selectedItems:  {
    },
    selectedAll: false,
    receipts: [], // leaving this here in case payments end up going by expense again
    reports: [],
    loading: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
}

export function paymentsListReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    let newSelectedItems = {};
    let stateResult;
    switch (action.type) {
        case PAYMENTS_LIST_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === "save") {
                newState.modalMessage = "Are you sure you want to save this receipt?";
                newState.saved = false;
            } else if (action.modalAction === "delete") {
                newState.modalMessage = "Are you sure you want to delete this receipt?";
                newState.receiptIdForDeletion = action.data.receiptId
                newState.saved = false;
            }
            return newState;
        case PAYMENTS_LIST_TOGGLE_CHECKBOX:
            newSelectedItems = Object.assign({}, state.selectedItems);
            if (newSelectedItems[action.id] === undefined) {
                newSelectedItems[action.id] = true;
            } else {
                newSelectedItems[action.id] = !newSelectedItems[action.id];
            }
            newState.selectedItems = newSelectedItems;
            return newState;
        case PAYMENTS_LIST_TOGGLE_CHECKBOX_ALL:
            stateResult = !newState.selectedAll;
            newState.selectedAll = stateResult;
            for (let key in newState.selectedItems) {
                newState.selectedItems[key] = stateResult;
            }
            return newState;
        case PAYMENTS_LIST_GET_EXPENSES_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case PAYMENTS_LIST_GET_EXPENSES_SUCCESS:
            newState.loading = false;
            return newState;
        case PAYMENTS_LIST_GET_EXPENSES_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.receipts = [];
            return newState;
        case PAYMENTS_LIST_GET_REPORTS_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case PAYMENTS_LIST_GET_REPORTS_SUCCESS:
            newState.loading = false;
            newState.reports = action.response;
            action.response.forEach((each) => {
                newSelectedItems[each.receiptId] = false;
            });
            newState.selectedItems = newSelectedItems;
            newState.selectedAll = false;
            return newState;
        case PAYMENTS_LIST_GET_REPORTS_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.reports = [];
            return newState;
        case PAYMENTS_LIST_DELETE_RECEIPT_REQUEST:
            newState.saving = true;
            return newState;
        case PAYMENTS_LIST_DELETE_RECEIPT_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Receipt successfully deleted";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case PAYMENTS_LIST_DELETE_RECEIPT_FAILURE:
            newState.modalMessage = "Failed to delete receipt : " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            newState.modalOpen = true;
            return newState;
        default:
            return state;
    }
}
