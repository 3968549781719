import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    cyderDevCacheCurrentProfile,
    cyderDevRemoveCachedProfile,
    cyderDevCacheFakeProfile,
} from '../../actions/profile/cyderProfileAction';
import { setConfig } from '../../actions/config';
import { capitalize } from 'js/generalUtils';

const pjson = require('../../../package.json');

class Footer extends Component {
    render() {
        const env = process.env.NODE_ENV;
        const isDev = env === 'development';

        return (
            <footer className="main footer d-flex my-3">
                <div className="d-flex flex-column-reverse pl-2 ">
                    <span className="text-bold">Djarvis v{pjson.version} </span>
                    {isDev && <span className="text-danger text-bold">{capitalize(env)}</span>}
                </div>
            </footer>
        );
    }
}

const mapStateToProps = state => {
    return {
        config: state.config,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setConfig: (key, value) => dispatch(setConfig(key, value)),
        cacheCurrentProfile: () => dispatch(cyderDevCacheCurrentProfile()),
        removeCachedProfile: () => dispatch(cyderDevRemoveCachedProfile()),
        cacheFakeProfile: () => dispatch(cyderDevCacheFakeProfile()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Footer);
