import {
    CYDER_PAYMENT_MODES_SETTINGS_SEARCH_REQUEST,
    CYDER_PAYMENT_MODES_SETTINGS_SEARCH_SUCCESS,
    CYDER_PAYMENT_MODES_SETTINGS_SEARCH_FAILURE,
    CYDER_PAYMENT_MODES_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG,
    CYDER_PAYMENT_MODES_SETTINGS_MODAL_TOGGLE,
    CYDER_PAYMENT_MODES_SETTINGS_MODAL_SET_PAYMENT_MODE,
    CYDER_PAYMENT_MODES_SETTINGS_MODAL_CHANGE_VALUE,
    CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_ADD_REQUEST,
    CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_ADD_SUCCESS,
    CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_ADD_FAILURE,
    CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_UPDATE_REQUEST,
    CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_UPDATE_SUCCESS,
    CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_UPDATE_FAILURE,
    CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_DELETE_REQUEST,
    CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_DELETE_SUCCESS,
    CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_DELETE_FAILURE,
} from '../../actions/constants/actionTypes' 

// defaults
var defaultState = {
    modalPaymentMode: {
        id: "",
        code: "",
        name: "",
        groups: [],
    },
    saving: false,
    saved: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: "",
    modalHeader: "",
    loading: false,
    paymentmodes: [],
    forceRefresh: false,
}

export function cyderPaymentModesSettingsSearchReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case CYDER_PAYMENT_MODES_SETTINGS_SEARCH_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_SEARCH_SUCCESS:
            newState.loading = false;
            newState.paymentmodes = action.response;
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_SEARCH_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.paymentmodes = [];
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_ADD_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_ADD_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Payment mode has been successfully added";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_ADD_FAILURE:
            newState.modalMessage = "Failed to add project: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_UPDATE_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_UPDATE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Payment mode has been successfully updated";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_UPDATE_FAILURE:
            newState.modalMessage = "Failed to save changes: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_DELETE_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_DELETE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Payment mode has been successfully deleted";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_PAYMENT_MODE_DELETE_FAILURE:
            newState.modalMessage = "Failed to save changes: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_SEARCH_SET_FORCE_REFRESH_FLAG:
            newState.forceRefresh = action.val;
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_MODAL_SET_PAYMENT_MODE:
            newState.modalPaymentMode = {
                id: action.id,
                code: action.code,
                name: action.name,
                groups: action.groups,
            }
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === "update") {
                newState.modalMessage = null;
                newState.saved = false;
                newState.modalHeader = "Update Payment Mode";
            } else if (action.modalAction === "add") {
                newState.modalMessage = null;
                newState.saved = false;
                newState.modalHeader = "Add Payment Mode";
            } else if (action.modalAction === "delete") {
                newState.modalMessage = "Are you sure you want to delete this Payment Mode?";
                newState.saved = false;
                newState.modalHeader = "Delete Payment Mode";
            }
            return newState;
        case CYDER_PAYMENT_MODES_SETTINGS_MODAL_CHANGE_VALUE:
            newState.modalPaymentMode = Object.assign({}, state.modalPaymentMode);
            newState.modalPaymentMode[action.key] = action.value
            return newState;
        default:
            return state;
    }
}
