import React from 'react';
import { connect } from 'react-redux';
import { setConfig } from '../../actions/config';
import { Button } from 'reactstrap';

import Cookies from 'universal-cookie';

import {
    cyderDevCacheCurrentProfile,
    cyderDevRemoveCachedProfile,
    cyderDevCacheFakeProfile,
} from '../../actions/profile/cyderProfileAction';

const use = false;
class DevNav extends React.Component {
    printProfile() {
        const cookies = new Cookies();
        console.log(cookies.get('profile'));
    }
    clearPersist() {
        this.props.persistor.purge();
    }
    render() {
        return !use ? null : (
            <ul className="nav nav-inline">
                <li className="nav-item">
                    <Button size="sm" className="mr-2" onClick={() => this.clearPersist()}>
                        Clear Persist
                    </Button>
                </li>
            </ul>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        config: state.config,
        profile: state.cyderProfileReducer.profile,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setConfig: (key, value) => dispatch(setConfig(key, value)),
        cacheCurrentProfile: () => dispatch(cyderDevCacheCurrentProfile()),
        removeCachedProfile: () => dispatch(cyderDevRemoveCachedProfile()),
        cacheFakeProfile: () => dispatch(cyderDevCacheFakeProfile()),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DevNav);
