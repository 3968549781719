import {
    CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_REQUEST,
    CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_SUCCESS,
    CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_FAILURE,
    CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_REQUEST,
    CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_SUCCESS,
    CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_FAILURE,
    CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX,
    CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX_ALL,
    CYDER_EXPENSE_MYRECEIPTS_MODAL_TOGGLE,
} from '../../actions/constants/actionTypes';
import { expenseSubmitableStatuses } from 'js/constlib';

var defaultState = {
    selectedItems: {},
    selectedAll: false,
    receipts: [],
    loading: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
    expenseSubmitableStatuses,
};

export function cyderExpenseMyReceiptsReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    let newSelectedItems = {};
    let stateResult;
    switch (action.type) {
        case CYDER_EXPENSE_MYRECEIPTS_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === 'save') {
                newState.modalMessage = `Are you sure you want to save this ${action.recurring ? 'recurring expense' : 'receipt'}?`;
                newState.saved = false;
            } else if (action.modalAction === 'delete') {
                newState.modalMessage = `Are you sure you want to delete this ${action.recurring ? 'recurring expense' : 'receipt'}?`;
                newState.receiptIdForDeletion = action.data.receiptId;
                newState.saved = false;
            }
            return newState;
        case CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX:
            newSelectedItems = Object.assign({}, state.selectedItems);
            if (newSelectedItems[action.id] === undefined) {
                newSelectedItems[action.id] = true;
            } else {
                newSelectedItems[action.id] = !newSelectedItems[action.id];
            }
            newState.selectedItems = newSelectedItems;
            return newState;
        case CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX_ALL:
            stateResult = !newState.selectedAll;
            newState.selectedAll = stateResult;
            for (let key in newState.selectedItems) {
                newState.selectedItems[key] = stateResult;
            }
            return newState;
        case CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_SUCCESS:
            newState.loading = false;
            newState.receipts = action.response;
            action.response.forEach(each => {
                if (defaultState.expenseSubmitableStatuses[each.status.toLowerCase()] && each.rejected !== 'Y') {
                    newSelectedItems[each.receiptId] = false;
                }
            });
            newState.selectedItems = newSelectedItems;
            newState.selectedAll = false;
            return newState;
        case CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.receipts = [];
            return newState;
        case CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_SUCCESS:
            newState.modalMessage = action.response.message
                ? action.response.message
                : `${action.recurring ? 'Recurring expense' : 'Receipt'} successfully deleted`;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'refresh';
            return newState;
        case CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_FAILURE:
            newState.modalMessage = `Failed to delete ${action.recurring ? 'recurring expense' : 'receipt'}: ${action.error.message}`;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            newState.modalOpen = true;
            return newState;
        default:
            return state;
    }
}
