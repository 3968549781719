import {
    CYDER_WORKFLOW_TASKS_DETAIL_MODAL_TOGGLE,
    CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_REQUEST,
    CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_SUCCESS,
    CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_FAILURE,
    CYDER_WORKFLOW_TASKS_DETAIL_APPROVE_REJECT_TASK_REQUEST,
    CYDER_WORKFLOW_TASKS_DETAIL_APPROVE_REJECT_TASK_SUCCESS,
    CYDER_WORKFLOW_TASKS_DETAIL_APPROVE_REJECT_TASK_FAILURE,
    CYDER_WORKFLOW_TASKS_DETAIL_PAYMENT_SET_STATUS_REQUEST,
    CYDER_WORKFLOW_TASKS_DETAIL_PAYMENT_SET_STATUS_SUCCESS,
    CYDER_WORKFLOW_TASKS_DETAIL_PAYMENT_SET_STATUS_FAILURE,
    CYDER_WORKFLOW_TASKS_DETAIL_FORCE_LOADING_STATE,
} from '../../actions/constants/actionTypesWorkflow' 
import moment from 'moment';

// import cyderlib from '../../js/cyderlib';

var defaultState = {
    loading: false,
    saving: false,
    saved: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
    taskFields: {
        expensedate: moment().format('DD/MM/YYYY'),
        amount: "",
        project: null,
        receiptno: "",
        paymentmode: "",
        category: "",
        remarks: "",
        reimbursable: true,
        receipts: [],
    }
}

export function tasksDetailReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case CYDER_WORKFLOW_TASKS_DETAIL_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === "approve") {
                newState.modalMessage = "Are you sure you want to approve this item?";
                newState.saved = false;
            } else if (action.modalAction === "reject") {
                newState.modalMessage = "Are you sure you want to reject this item?";
                newState.saved = false;
            } else if (action.modalAction === "validating") {
                newState.modalMessage = "Are you sure you want to mark this item as Validating?";
            } else if (action.modalAction === "paid") {
                newState.modalMessage = "Are you sure you want to mark this item as Paid?";
                newState.saved = false;
            }
            return newState;
        case CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_SUCCESS:
            newState.loading = false;
            newState.taskFields = Object.assign({}, action.response);
            return newState;
        case CYDER_WORKFLOW_TASKS_DETAIL_GET_TASK_FAILURE:
            newState.loading = false;
            newState.modalOpen = true;
            newState.modalHeader = "Error";
            newState.modalAction = "close";
            newState.errorMessage = action.error.message;
            return newState;
        case CYDER_WORKFLOW_TASKS_DETAIL_APPROVE_REJECT_TASK_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_WORKFLOW_TASKS_DETAIL_APPROVE_REJECT_TASK_SUCCESS:
            const actionText = action.action === "approve" ? "approved" : "rejected";
            newState.modalMessage = action.response.message ? action.response.message : "Item successfully " + actionText;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "redirect";
            return newState;
        case CYDER_WORKFLOW_TASKS_DETAIL_APPROVE_REJECT_TASK_FAILURE:
            const actionText2 = action.action === "approve" ? "approve" : "reject";
            newState.modalMessage = "Failed to " + actionText2 +  " item: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_WORKFLOW_TASKS_DETAIL_PAYMENT_SET_STATUS_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_WORKFLOW_TASKS_DETAIL_PAYMENT_SET_STATUS_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Item status succesfully set to " + action.newStatus;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "redirectPayment";
            return newState;
        case CYDER_WORKFLOW_TASKS_DETAIL_PAYMENT_SET_STATUS_FAILURE:
            newState.modalMessage = "Failed to set item status";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_WORKFLOW_TASKS_DETAIL_FORCE_LOADING_STATE:
            newState.loading = action.loading;
            return newState;
        default:
            return state;
    }
}
