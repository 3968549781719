import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router';

import { colors, backgroundColors } from './colors';
import { pagedata } from './pagedata';
import history from '../history';
import { config } from './config';

// Navigation
import { expenseNavigation } from './navigation/expenseNavigation';
import { leaveTypeNavigation } from './navigation/leaveTypeNavigation';
import { usermenu } from './usermenu';

// Cyder customisation
import { cyderLoginReducer } from './security/cyderLoginReducer';
import { cyderChangePasswordReducer } from './security/cyderChangePasswordReducer';
import { cyderForgotPasswordReducer } from './security/cyderForgotPasswordReducer';
import { cyderLogoutReducer } from './security/cyderLogoutReducer';
import { cyderExpenseReducer } from './expenses/cyderExpenseReducer';
import { cyderExpenseSharedReducer } from './expenses/cyderExpenseSharedReducer';
import { cyderExpenseMyReceiptsReducer } from './expenses/cyderExpenseMyReceiptsReducer';
import { paymentsListReducer } from './payments/paymentsListReducer';
import { cyderExpenseMyReportsReducer } from './expenses/cyderExpenseMyReportsReducer';
import { cyderExpenseReportsCreateReducer } from './expenses/cyderExpenseReportsCreateReducer';
import { expenseReportsDetailReducer } from './expenses/expenseReportsDetailReducer';
import { cyderExpenseNewReceiptClaimReducer } from './expenses/cyderExpenseNewReceiptClaimReducer';
import { cyderExpenseNewMileageClaimReducer } from './expenses/cyderExpenseNewMileageClaimReducer';
import { cyderSysparamReducer } from './settings/cyderSysparamReducer';
import { cyderProjectsSettingsSearchReducer } from './settings/cyderProjectsSettingsSearchReducer';
import { departmentSettingsReducer } from './settings/departmentSettingsReducer';
import { cyderLocationsSettingsSearchReducer } from './settings/cyderLocationsSettingsSearchReducer';
import { cyderPaymentModesSettingsSearchReducer } from './settings/cyderPaymentModesSettingsSearchReducer';
import { cyderExpenseCategoriesSettingsSearchReducer } from './settings/cyderExpenseCategoriesSettingsSearchReducer';
import { cyderMileageCategoriesSettingsSearchReducer } from './settings/cyderMileageCategoriesSettingsSearchReducer';
import { cyderUsersSearchReducer } from './users/cyderUsersSearchReducer';
import { cyderUsersDetailReducer } from './users/cyderUsersDetailReducer';
import { cyderUsersAddReducer } from './users/cyderUsersAddReducer';
import { cyderGroupsSearchReducer } from './groups/cyderGroupsSearchReducer';
import { cyderGroupsDetailReducer } from './groups/cyderGroupsDetailReducer';
import { cyderGroupsAddReducer } from './groups/cyderGroupsAddReducer';
import { cyderProfileReducer } from './profile/cyderProfileReducer';
import { cyderFakeUserReducer } from './common/cyderFakeUserReducer';
import { homeReducer } from './common/homeReducer';

// Djarvis Reports
import { cyderReportExpenseReducer } from './report/cyderReportExpenseReducer';

import { myTasksReducer } from './workflow/myTasksReducer';
import { tasksDetailReducer } from './workflow/tasksDetailReducer';

import { delegationsReducer } from './workflow/delegationsReducer';

// timesheet
import clockReducer from './timesheet/clockReducer';

// mycorp
import mycorpReducer from './mycorp/mycorpReducer';

const rootReducer = combineReducers({
    router: connectRouter(history),
    routing: routerReducer,
    config,
    colors,
    backgroundColors,
    expenseNavigation,
    leaveTypeNavigation,
    delegationsReducer,
    cyderLoginReducer,
    cyderChangePasswordReducer,
    cyderForgotPasswordReducer,
    cyderLogoutReducer,
    cyderExpenseReducer,
    cyderExpenseSharedReducer,
    cyderExpenseMyReceiptsReducer,
    cyderExpenseMyReportsReducer,
    cyderExpenseReportsCreateReducer,
    expenseReportsDetailReducer,
    cyderExpenseNewReceiptClaimReducer,
    cyderExpenseNewMileageClaimReducer,
    cyderSysparamReducer,
    cyderProjectsSettingsSearchReducer,
    departmentSettingsReducer,
    cyderLocationsSettingsSearchReducer,
    cyderPaymentModesSettingsSearchReducer,
    cyderExpenseCategoriesSettingsSearchReducer,
    cyderMileageCategoriesSettingsSearchReducer,
    cyderUsersSearchReducer,
    cyderUsersDetailReducer,
    cyderUsersAddReducer,
    cyderProfileReducer,
    cyderFakeUserReducer,
    cyderGroupsSearchReducer,
    cyderGroupsDetailReducer,
    cyderGroupsAddReducer,
    cyderReportExpenseReducer,
    usermenu,
    pagedata: pagedata,
    homeReducer,
    myTasksReducer,
    tasksDetailReducer,
    paymentsListReducer,
    clockReducer,
    mycorpReducer,
});
export default rootReducer;
