import approve from 'approvejs';

const validationTypes = {
    lat: {
        title: 'Latitude',
        required: true,
        format: {
            regex: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
            message: 'Invalid latitude',
        },
    },
    lng: {
        title: 'Longitude',
        required: true,
        format: {
            regex: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
            message: 'Invalid longitude',
        },
    },
    department_code: {
        title: 'Department Code',
        required: true,
    },
    email: {
        title: 'Email',
        required: true,
        email: true,
        max: 200,
    },
    email2: {
        title: 'Secondary Email',
        ignoreNull: true,
        email: true,
        max: 200,
    },
    admin: {
        required: false,
    },
    firstname: {
        title: 'First Name',
        required: true,
        format: {
            regex: /^[A-Za-z 0-9]+$/,
            message: 'First Name can only contain alphanumeric characters and space',
        },
        max: 50,
    },
    lastname: {
        title: 'Last Name',
        required: true,
        format: {
            regex: /^[A-Za-z 0-9]+$/,
            message: 'Last Name can only contain alphanumeric characters and space',
        },
        max: 50,
    },
    password: {
        title: 'Password',
        required: true,
        max: 20,
    },
    confirmpassword: {
        title: 'Confirm Password',
        required: true,
        max: 20,
    },
    amount: {
        required: true,
        title: 'Amount',
        format: {
            regex: /^[0-9]*(.[0-9]{1,2})?$/,
            message: 'Invalid Amount',
        },

        max: 20,
    },
    mobilenumber: {
        required: true,
        title: 'Mobile Number',
        format: {
            regex: /^\+?[0-9]*$/,
            message: 'Invalid Phone Number',
        },
        max: 20,
    },
    officenumber: {
        title: 'Office Number',
        numeric: true,
        ignoreNull: true,
        max: 20,
    },
    homenumber: {
        title: 'Home Number',
        numeric: true,
        ignoreNull: true,
        max: 20,
    },
    companyname: {
        title: 'Company Name',
        ignoreNull: true,
        max: 200,
    },
    companyaddress: {
        title: 'Company Address',
        ignoreNull: true,
        max: 300,
    },
    homeaddress: {
        title: 'Home Address',
        ignoreNull: true,
        max: 300,
    },
    // TODO: better validation
    dateofbirth: {
        format: {
            regex: /^(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))\/(0[1-9]|1[0-2])\/([12]\d{3})$/,
            message: 'Invalid date. Please Enter DD/MM/YYYY.',
        },
        required: true,
    },
    // TODO: better validation
    idno: {
        title: 'ID No.',
        required: true,
        max: 20,
    },
    idtype: {
        title: 'ID Type',
        required: true,
    },
    gender: {
        title: 'Gender',
        required: true,
    },
    groupname: {
        title: 'Group Name',
        required: true,
        max: 50,
    },
    groupdescription: {
        title: 'Group Description',
        required: true,
        max: 50,
    },
    tfa: {
        title: 'TFA',
    },
    category: {
        title: 'Category',
        required: true,
    },
    expensedate: {
        title: 'Expense Date',
        required: true,
    },
    paymentmode: {
        title: 'Payment Mode',
        required: true,
    },
    project: {
        title: 'Project',
        required: true,
    },
    receiptno: {
        title: 'Receipt No',
        required: true,
    },
};

export default {
    validate: function(key, value, pw) {
        // TODO catch errors
        if (!validationTypes[key]) {
            return false;
        }
        let rules = validationTypes[key] ? validationTypes[key] : null;
        // will throw error if rules == null
        if (key === 'confirmpassword' && pw) {
            rules = {
                required: true,
                title: 'Confirm Password',
                equal: {
                    value: pw,
                    field: 'Password',
                },
                max: 20,
            };
        }
        // for debug
        // console.log(value)
        // console.log(key)
        // console.log(rules)
        // console.log(approve.value(value, validationTypes[key]));
        return approve.value(value, rules);
    },
};
