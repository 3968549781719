export const SETTINGS_DEPARTMENTS_SEARCH_REQUEST = 'SETTINGS_DEPARTMENTS_SEARCH_REQUEST';
export const SETTINGS_DEPARTMENTS_SEARCH_SUCCESS = 'SETTINGS_DEPARTMENTS_SEARCH_SUCCESS';
export const SETTINGS_DEPARTMENTS_SEARCH_FAILURE = 'SETTINGS_DEPARTMENTS_SEARCH_FAILURE';
export const SETTINGS_DEPARTMENTS_SEARCH_SET_FORCE_REFRESH_FLAG = 'SETTINGS_DEPARTMENTS_SEARCH_SET_FORCE_REFRESH_FLAG';

export const SETTINGS_DEPARTMENTS_MODAL_TOGGLE = 'SETTINGS_DEPARTMENTS_MODAL_TOGGLE';
export const SETTINGS_DEPARTMENTS_MODAL_OPEN = 'SETTINGS_DEPARTMENTS_MODAL_OPEN';
export const SETTINGS_DEPARTMENTS_MODAL_CLOSE = 'SETTINGS_DEPARTMENTS_MODAL_CLOSE';
export const SETTINGS_DEPARTMENTS_MODAL_CHANGE_VALUE = 'SETTINGS_DEPARTMENTS_MODAL_CHANGE_VALUE';
export const SETTINGS_DEPARTMENTS_LOADING = 'SETTINGS_DEPARTMENTS_LOADING';
export const SETTINGS_DEPARTMENTS_MODAL_SET_PROJECT = 'SETTINGS_DEPARTMENTS_MODAL_SET_PROJECT';

export const SETTINGS_DEPARTMENTS_PROJECT_ADD_REQUEST = 'SETTINGS_DEPARTMENTS_PROJECT_ADD_REQUEST';
export const SETTINGS_DEPARTMENTS_PROJECT_ADD_SUCCESS = 'SETTINGS_DEPARTMENTS_PROJECT_ADD_SUCCESS';
export const SETTINGS_DEPARTMENTS_PROJECT_ADD_FAILURE = 'SETTINGS_DEPARTMENTS_PROJECT_ADD_FAILURE';

export const SETTINGS_DEPARTMENTS_PROJECT_UPDATE_REQUEST = 'SETTINGS_DEPARTMENTS_PROJECT_UPDATE_REQUEST';
export const SETTINGS_DEPARTMENTS_PROJECT_UPDATE_SUCCESS = 'SETTINGS_DEPARTMENTS_PROJECT_UPDATE_SUCCESS';
export const SETTINGS_DEPARTMENTS_PROJECT_UPDATE_FAILURE = 'SETTINGS_DEPARTMENTS_PROJECT_UPDATE_FAILURE';

export const SETTINGS_DEPARTMENTS_PROJECT_DELETE_REQUEST = 'SETTINGS_DEPARTMENTS_PROJECT_DELETE_REQUEST';
export const SETTINGS_DEPARTMENTS_PROJECT_DELETE_SUCCESS = 'SETTINGS_DEPARTMENTS_PROJECT_DELETE_SUCCESS';
export const SETTINGS_DEPARTMENTS_PROJECT_DELETE_FAILURE = 'SETTINGS_DEPARTMENTS_PROJECT_DELETE_FAILURE';

