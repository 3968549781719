import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const LinkButton = ({
    color,
    altColor,
    to = '#',
    materialIcon,
    text,
    className,
    strong,
    fontSize,
    trueLink,
    notLink,
    onClick,
    preset,
    disabled,
    block,
    addStyle,
}) => {
    if (preset === 'addButton') {
        color = 'success';
        altColor = 'light';
        notLink = true;
        className = 'ml-auto';
        materialIcon = 'add';
    }

    const classNames = [
        'btn',
        block ? 'btn-block' : null,
        'border-' + color,
        'color-' + color,
        'color-hover-' + altColor,
        'bg-unimportant-' + altColor,
        'bg-hover-' + color,
        'rounded',
        className,
    ];

    const cn = classNames.join(' ');
    const addStyleObj = addStyle || {};

    let style = Object.assign(
        addStyleObj,
        strong
            ? {
                  fontWeight: 'bolder',
              }
            : {},
    );

    style = fontSize
        ? Object.assign(style, {
              fontSize: '0.675rem',
          })
        : style;

    const InnerElement = () => (
        <button disabled={disabled} className={cn} onClick={onClick} style={addStyleObj}>
            <div className="d-flex justify-content-center">
                <div className="d-flex flex-row">
                    {materialIcon && <i className="align-self-center material-icons">{materialIcon}</i>}
                    <span style={style} className="text-uppercase">
                        {text}
                    </span>
                </div>
            </div>
        </button>
    );

    if (trueLink) {
        return (
            <a href={to} className="d-flex justify-content-center">
                <InnerElement />
            </a>
        );
    }

    if (notLink) return <InnerElement />;

    return (
        <Link to={to} className="d-flex justify-content-center">
            <InnerElement />
        </Link>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = () => {
    return {};
};

// translate() is if we want to use HOC to perform t()
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LinkButton);
