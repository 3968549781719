
/**
 * Cyder Login Reducer
 * 
 * Author: Fernando
 * 
 */
import {
    CYDER_FORGOT_PASSWORD_REQUEST,
    CYDER_FORGOT_PASSWORD_SUCCESS,
    CYDER_FORGOT_PASSWORD_FAILURE,
    CYDER_FORGOT_PASSWORD_PAGE_SET_MESSAGE,
} from '../../actions/constants/actionTypes'

var cyderForgotPasswordIntialState = {
    fields: [
        {
            id: 'email',
            value: '',
            name: 'email',
            icon: 'email',
            type: 'email',
            placeholder: "Registered Email",
            errors: [],
            rules: {
                title: '',
                required: true
            }
        },
    ],
    success: false,
    userData: {},
    requesting: false,
    loginError: false,
    message: null,
}

/**
 * Login reducer
 * 
 * @param {*} state 
 * @param {*} action 
 */
export function cyderForgotPasswordReducer(state = cyderForgotPasswordIntialState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case CYDER_FORGOT_PASSWORD_REQUEST:
            newState.requesting = true;
            return newState;
        case CYDER_FORGOT_PASSWORD_SUCCESS:
            newState.requesting = false;
            newState.success = true;
            newState.message = "Email has been sent with instructions for password reset.";
            return newState;
        case CYDER_FORGOT_PASSWORD_FAILURE:
            newState.requesting = false;
            newState.message = (action.error.errdescription || action.error);
            return newState;
        case CYDER_FORGOT_PASSWORD_PAGE_SET_MESSAGE:
            newState.message = action.message;
            return newState;
        default:
            return state;
    }
}

