import {
    CYDER_EXPENSE_NEW_RECEIPT_MODAL_TOGGLE,
    CYDER_EXPENSE_NEW_RECEIPT_CHANGE_FIELD,
    CYDER_EXPENSE_NEW_RECEIPT_SAVE_REQUEST,
    CYDER_EXPENSE_NEW_RECEIPT_SAVE_SUCCESS,
    CYDER_EXPENSE_NEW_RECEIPT_SAVE_FAILURE,
    CYDER_EXPENSE_NEW_RECEIPT_CHECK_DUPLICATION_REQUEST,
    CYDER_EXPENSE_NEW_RECEIPT_CHECK_DUPLICATION_SUCCESS,
    CYDER_EXPENSE_NEW_RECEIPT_CHECK_DUPLICATION_FAILURE,
    CYDER_EXPENSE_NEW_RECEIPT_SET_FIELDS,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_REQUEST,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_SUCCESS,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_FAILURE,
    CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_REQUEST,
    CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_SUCCESS,
    CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_FAILURE,
    CYDER_EXPENSE_NEW_RECEIPT_FORCE_LOADING_STATE,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_SUCCESS,
    CYDER_EXPENSE_NEW_RECEIPT_FORCE_VALIDATE,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_GET_BLOCKCHAIN_INFO_REQUEST,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_GET_BLOCKCHAIN_INFO_SUCCESS,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_GET_BLOCKCHAIN_INFO_FAILURE,
} from 'actions/constants/actionTypes';
import moment from 'moment';

// import cyderlib from '../../js/cyderlib';
import validationlib from 'js/validation';

var defaultState = {
    loading: false,
    saving: false,
    saved: false,
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
    receiptFields: {
        expensedate: moment().format('DD/MM/YYYY'),
        amount: '',
        project: null,
        receiptno: '',
        paymentmode: '',
        category: '',
        remarks: '',
        reimbursable: true,
        frequency_type: 'month',
        frequency: '1',
    },
    workflowHistory: null,
    validation: {},
    checkingReceiptNo: false,
    checkedOnceReceiptNo: false,
    validReceiptNo: true,
};

export function cyderExpenseNewReceiptClaimReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_SUCCESS:
            newState.workflowHistory = action.response;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === 'save') {
                newState.modalMessage = `Are you sure you want to save this ${action.recurring ? 'recurring expense' : 'receipt'}?`;
                newState.saved = false;
            } else if (action.modalAction === 'delete') {
                newState.modalMessage = `Are you sure you want to delete this ${action.recurring ? 'recurring expense' : 'receipt'}?`;
                newState.saved = false;
            }
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_FORCE_VALIDATE:
            newState.receiptFields = Object.assign({}, state.receiptFields);
            newState.validation = Object.assign({}, state.validation);
            for (var k in state.receiptFields) {
                newState.validation[k] = validationlib.validate(k, state.receiptFields[k]);
            }
            newState.saveButtonPressed = true;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_CHANGE_FIELD:
            newState.receiptFields = Object.assign({}, state.receiptFields);
            newState.receiptFields[action.key] = action.value;
            newState.validation = Object.assign({}, state.validation);
            newState.validation[action.key] = validationlib.validate(action.key, action.value);
            // newState.changesMade = true;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_SAVE_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_SAVE_SUCCESS:
            newState.modalMessage = action.response.message
                ? action.response.message
                : `${action.recurring ? 'Recurring expense' : 'Receipt'} successfully saved`;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'redirect';
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_SAVE_FAILURE:
            newState.modalMessage = `Failed to save ${action.recurring ? 'recurring expense' : 'receipt'}: ${action.error.message}`;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_CHECK_DUPLICATION_REQUEST:
            newState.checkingReceiptNo = true;
            newState.checkedOnceReceiptNo = true;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_CHECK_DUPLICATION_SUCCESS:
            if (action.response.exist === true) {
                newState.validReceiptNo = false;
            } else {
                newState.validReceiptNo = true;
            }
            newState.checkingReceiptNo = false;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_CHECK_DUPLICATION_FAILURE:
            newState.checkingReceiptNo = false;
            newState.checkReceiptNoNetworkError = true;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_SET_FIELDS:
            if (action.receiptFields) {
                newState.receiptFields = {
                    ...action.receiptFields,
                    status: '',
                };
            } else if (action.resetFields === true) {
                newState.receiptFields = Object.assign({}, defaultState.receiptFields);
            }
            newState.workflowHistory = null;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_REQUEST:
            newState.loading = true;
            newState.modalMessage = null;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_SUCCESS:
            // newState.loading = false;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_FAILURE:
            newState.loading = false;
            newState.modalOpen = true;
            newState.modalHeader = 'Error';
            newState.modalAction = 'redirect';
            newState.modalMessage = action.error.message;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_SUCCESS:
            newState.modalMessage = action.response.message
                ? action.response.message
                : `${action.recurring ? 'Recurring expense' : 'Receipt'} successfully deleted`;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'redirect';
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_FAILURE:
            newState.modalMessage = `Failed to delete ${action.recurring ? 'recurring expense' : 'receipt'}: ${action.error.message}`;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            newState.modalOpen = true;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_GET_BLOCKCHAIN_INFO_REQUEST:
            newState.loading = true;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_GET_BLOCKCHAIN_INFO_SUCCESS:
            const { data } = action.response;
            newState.blockchainVerified = data && Object.keys(data).length !== 0 ? true : false;
            newState.loading = false;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_GET_BLOCKCHAIN_INFO_FAILURE:
            newState.blockchainVerified = false;
            newState.loading = false;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_FORCE_LOADING_STATE:
            newState.loading = action.loading;
            return newState;
        default:
            return state;
    }
}
