import {
    SETTINGS_DEPARTMENTS_SEARCH_REQUEST,
    SETTINGS_DEPARTMENTS_SEARCH_SUCCESS,
    SETTINGS_DEPARTMENTS_SEARCH_FAILURE,
    SETTINGS_DEPARTMENTS_SEARCH_SET_FORCE_REFRESH_FLAG,
    SETTINGS_DEPARTMENTS_MODAL_TOGGLE,
    SETTINGS_DEPARTMENTS_MODAL_SET_PROJECT,
    SETTINGS_DEPARTMENTS_MODAL_CHANGE_VALUE,
    SETTINGS_DEPARTMENTS_PROJECT_ADD_REQUEST,
    SETTINGS_DEPARTMENTS_PROJECT_ADD_SUCCESS,
    SETTINGS_DEPARTMENTS_PROJECT_ADD_FAILURE,
    SETTINGS_DEPARTMENTS_PROJECT_UPDATE_REQUEST,
    SETTINGS_DEPARTMENTS_PROJECT_UPDATE_SUCCESS,
    SETTINGS_DEPARTMENTS_PROJECT_UPDATE_FAILURE,
    SETTINGS_DEPARTMENTS_PROJECT_DELETE_REQUEST,
    SETTINGS_DEPARTMENTS_PROJECT_DELETE_SUCCESS,
    SETTINGS_DEPARTMENTS_PROJECT_DELETE_FAILURE,
} from '../../actions/constants/actionTypesSettings' 

// defaults
var defaultState = {
    modalDepartment: {
        id: "",
        code: "",
        name: "",
        users: [],
        approvers: [],
        approvalvotescount: 1,
        overrideexpenseapproval: false,
    },
    usersReference: {},
    approversReference: {},
    saving: false,
    saved: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: "",
    modalHeader: "",
    loading: false,
    departments: [],
    forceRefresh: false,
}

export function departmentSettingsReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case SETTINGS_DEPARTMENTS_SEARCH_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case SETTINGS_DEPARTMENTS_SEARCH_SUCCESS:
            newState.loading = false;
            newState.departments = action.response;
            return newState;
        case SETTINGS_DEPARTMENTS_SEARCH_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.departments = [];
            return newState;
        case SETTINGS_DEPARTMENTS_PROJECT_ADD_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case SETTINGS_DEPARTMENTS_PROJECT_ADD_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Department has been successfully added";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case SETTINGS_DEPARTMENTS_PROJECT_ADD_FAILURE:
            newState.modalMessage = "Failed to add project: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case SETTINGS_DEPARTMENTS_PROJECT_UPDATE_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case SETTINGS_DEPARTMENTS_PROJECT_UPDATE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Department has been successfully updated";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case SETTINGS_DEPARTMENTS_PROJECT_UPDATE_FAILURE:
            newState.modalMessage = "Failed to save changes: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case SETTINGS_DEPARTMENTS_PROJECT_DELETE_REQUEST:
            newState.saving = true;
            newState.errorMessage = null;
            return newState;
        case SETTINGS_DEPARTMENTS_PROJECT_DELETE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Department has been successfully deleted";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "refresh";
            return newState;
        case SETTINGS_DEPARTMENTS_PROJECT_DELETE_FAILURE:
            newState.modalMessage = "Failed to save changes: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case SETTINGS_DEPARTMENTS_SEARCH_SET_FORCE_REFRESH_FLAG:
            newState.forceRefresh = action.val;
            return newState;
        case SETTINGS_DEPARTMENTS_MODAL_SET_PROJECT:
            newState.modalDepartment = {
                ...action
            }
            action.approvers.forEach((approver) => {
                newState.approversReference[approver] = true;
            });
            return newState;
        case SETTINGS_DEPARTMENTS_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === "update") {
                newState.modalMessage = null;
                newState.saved = false;
                newState.modalHeader = "Update Department";
            } else if (action.modalAction === "add") {
                newState.modalMessage = null;
                newState.saved = false;
                newState.modalHeader = "Add Department";
            } else if (action.modalAction === "delete") {
                newState.modalMessage = "Are you sure you want to delete this Department?";
                newState.saved = false;
                newState.modalHeader = "Delete Department";
            }
            return newState;
        case SETTINGS_DEPARTMENTS_MODAL_CHANGE_VALUE:
            newState.modalDepartment = Object.assign({}, state.modalDepartment);
            newState.modalDepartment[action.key] = action.value
            return newState;
        default:
            return state;
    }
}
