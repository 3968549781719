import { expenseDeletableStatuses, urlRecurring, urlReceipt, layoutLib } from 'js/constlib';
const isMobile = window.innerWidth <= layoutLib.mobileWidth;

const urls = [
    {
        title: 'Concierge',
        show: true,
        accessLevel: [0, 1, 2],
        items: [
            {
                url: '/leavetype/home',
                icon: 'home',
                title: 'Concierge',
                show: true,
            },
        ],
    },
    {
        title: 'Timesheet',
        show: true,
        accessLevel: [0, 1, 2],
        items: [
            {
                micon: 'alarm',
                title: 'Timesheet',
                show: true,
                items: [
                    {
                        url: '/leavetype/timesheet/clock',
                        icon: 'timer',
                        title: 'Check In/Out',
                        show: true,
                    },
                    {
                        url: '/leavetype/timesheet/monthlytimesheet',
                        icon: 'timer',
                        title: 'Monthly Timesheet',
                        show: true,
                    },
                    {
                        url: '/leavetype/timesheet/yearlytimesheet',
                        icon: 'timer',
                        title: 'Yearly Timesheet',
                        show: true,
                    },
                    {
                        url: '/leavetype/timesheet/timesheetapproval',
                        icon: 'timer',
                        title: 'Timesheet Approval',
                        show: true,
                    },
                ],
            },
        ],
    },
    {
        title: 'Leaves',
        show: true,
        accessLevel: [0, 1, 2],
        items: [
            {
                micon: 'map',
                title: 'Leaves',
                show: true,
                items: [
                    {
                        url: '/leavetype/leaves/leaveplan',
                        icon: 'timer',
                        title: 'Leave Plan',
                        show: true,
                    },
                    {
                        url: '/leavetype/leaves/leaveapproval',
                        icon: 'timer',
                        title: 'Leave Approval',
                        show: true,
                    },
                    {
                        url: '/leavetype/leaves/leavecalendar',
                        icon: 'timer',
                        title: 'Leave Calendar',
                        show: true,
                    },
                ],
            },
        ],
    },
    {
        title: 'Settings',
        show: !isMobile,
        accessLevel: [1, 2],
        items: [
            {
                url: '/settings/leave/',
                icon: 'cog',
                title: 'Settings',
                show: true,
            },
        ],
    },
    {
        title: 'Others',
        show: true,
        items: [
            {
                url: '/home',
                icon: 'credit-card',
                title: 'Djarvis Expenses',
                show: true,
            },
            {
                url: '/profile',
                micon: 'account_circle',
                title: 'Profile',
                show: true,
                items: [],
            },
            {
                action: 'logout',
                micon: 'exit_to_app',
                title: 'Logout',
                show: true,
                items: [],
            },
        ],
    },
];

export function leaveTypeNavigation(state = Array.from(urls), action) {
    switch (action.type) {
        case 'SET_NAVIGATION':
            return Object.assign({}, state);
        default:
            return state;
    }
}
