/** @format */

import React, { Component } from 'react';

import Modal from 'cyderComponents/modals/DialogModal';
import ConfirmModalManager from 'cyderComponents/ConfirmModal/ConfirmModalManager';

class ConfirmModal extends Component {
    state = {
        onlyOneButton: false,
        isModalOpen: false,
        onConfirm: null,
        header: 'Confirmation',
        body: '',
    };

    toggleModal = () => {
        ConfirmModalManager.toggle();
    };

    componentWillMount = () => {
        ConfirmModalManager.addChangeListener(this.handleChange);
    };

    componentWillUnmount = () => {
        ConfirmModalManager.removeChangeListener(this.handleChange);
    };

    handleChange = modalState => {
        this.setState(modalState);
    };

    render() {
        const { isModalOpen, onConfirm, header, body, onlyOneButton } = this.state;

        return (
            <Modal
                modal={isModalOpen}
                toggle={this.toggleModal}
                positiveButtonAction={onConfirm}
                customHeader={header}
                customModalBody={body}
                onlyOneButton={onlyOneButton}
            />
        );
    }
}

export default ConfirmModal;
