export const DELEGATIONS_SEARCH_REQUEST = 'DELEGATIONS_SEARCH_REQUEST';
export const DELEGATIONS_SEARCH_SUCCESS = 'DELEGATIONS_SEARCH_SUCCESS';
export const DELEGATIONS_SEARCH_FAILURE = 'DELEGATIONS_SEARCH_FAILURE';
export const DELEGATIONS_SEARCH_SET_FORCE_REFRESH_FLAG = 'DELEGATIONS_SEARCH_SET_FORCE_REFRESH_FLAG';

export const DELEGATIONS_MODAL_TOGGLE = 'DELEGATIONS_MODAL_TOGGLE';
export const DELEGATIONS_MODAL_OPEN = 'DELEGATIONS_MODAL_OPEN';
export const DELEGATIONS_MODAL_CLOSE = 'DELEGATIONS_MODAL_CLOSE';
export const DELEGATIONS_MODAL_CHANGE_VALUE = 'DELEGATIONS_MODAL_CHANGE_VALUE';
export const DELEGATIONS_LOADING = 'DELEGATIONS_LOADING';
export const DELEGATIONS_MODAL_SET_DELEGATION = 'DELEGATIONS_MODAL_SET_DELEGATION';

export const DELEGATIONS_DELEGATION_ADD_REQUEST = 'DELEGATIONS_DELEGATION_ADD_REQUEST';
export const DELEGATIONS_DELEGATION_ADD_SUCCESS = 'DELEGATIONS_DELEGATION_ADD_SUCCESS';
export const DELEGATIONS_DELEGATION_ADD_FAILURE = 'DELEGATIONS_DELEGATION_ADD_FAILURE';

export const DELEGATIONS_DELEGATION_UPDATE_REQUEST = 'DELEGATIONS_DELEGATION_UPDATE_REQUEST';
export const DELEGATIONS_DELEGATION_UPDATE_SUCCESS = 'DELEGATIONS_DELEGATION_UPDATE_SUCCESS';
export const DELEGATIONS_DELEGATION_UPDATE_FAILURE = 'DELEGATIONS_DELEGATION_UPDATE_FAILURE';

export const DELEGATIONS_DELEGATION_DELETE_REQUEST = 'DELEGATIONS_DELEGATION_DELETE_REQUEST';
export const DELEGATIONS_DELEGATION_DELETE_SUCCESS = 'DELEGATIONS_DELEGATION_DELETE_SUCCESS';
export const DELEGATIONS_DELEGATION_DELETE_FAILURE = 'DELEGATIONS_DELEGATION_DELETE_FAILURE';
export const DELEGATIONS_ADD_USER = 'DELEGATIONS_ADD_USER';
export const DELEGATIONS_REMOVE_USER = 'DELEGATIONS_REMOVE_USER';
export const DELEGATIONS_ADD_APPROVER = 'DELEGATIONS_ADD_APPROVER';
export const DELEGATIONS_REMOVE_APPROVER = 'DELEGATIONS_REMOVE_APPROVER';

