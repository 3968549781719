import {
    CYDER_USERS_DETAIL_REQUEST,
    CYDER_USERS_DETAIL_SUCCESS,
    CYDER_USERS_DETAIL_FAILURE,
    CYDER_USERS_DETAIL_CHANGE_FIELD,
    CYDER_USERS_DETAIL_SAVE_CHANGES_REQUEST,
    CYDER_USERS_DETAIL_SAVE_CHANGES_SUCCESS,
    CYDER_USERS_DETAIL_SAVE_CHANGES_FAILURE,
    CYDER_USERS_DETAIL_DELETE_REQUEST,
    CYDER_USERS_DETAIL_DELETE_SUCCESS,
    CYDER_USERS_DETAIL_DELETE_FAILURE,
    CYDER_USERS_DETAIL_MODAL_TOGGLE,
    CYDER_USERS_DETAIL_FORCE_VALIDATE,
} from '../../actions/constants/actionTypes' 
import cyderlib from '../../js/cyderlib'

// defaults
var cyderGroupsDetailInitialState = {
    loading: false,
    saving: false,
    saved: false,
    errorMessage: null,
    checkFields: {
        email: true,
        email2: true,
        firstname: true,
        lastname: true,
        mobilenumber: true,
        officenumber: true,
        homenumber: true,
        companyname: true,
        companyaddress: true,
        homeaddress: true,
        dateofbirth: true,
        idtype: true,
        idno: true,
        gender: true,
        department_code: true,
    },
    user: {
        email: "",
        email2: "",
        firstname: "",
        lastname: "",
        mobilenumber: "",
        officenumber: "",
        homenumber: "",
        companyname: "",
        companyaddress: "",
        homeaddress: "",
        dateofbirth: "",
        idtype: "NRIC",
        idno: "",
        gender: "Male",
    },
    validation: {},
    changesMade: false,
    saveButtonPressed: false,
    modalOpen: false,
    modalAction: null,
    modalMessage: "Are you sure you want to update this user?",
    forceRefresh: false,
}

export function cyderUsersDetailReducer(state = cyderGroupsDetailInitialState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case CYDER_USERS_DETAIL_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            newState.saved = false;
            return newState;
        case CYDER_USERS_DETAIL_SUCCESS:
            newState.loading = false;
            newState.user = action.response;
            return newState;
        case CYDER_USERS_DETAIL_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            return newState;
        case CYDER_USERS_DETAIL_CHANGE_FIELD:
            newState.user = Object.assign({}, state.user);
            newState.validation = Object.assign({}, state.validation);
            newState.user[action.key] = action.value;
            newState.validation[action.key] = cyderlib.validate(action.key, action.value);
            newState.changesMade = true;
            return newState;
        case CYDER_USERS_DETAIL_SAVE_CHANGES_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_USERS_DETAIL_SAVE_CHANGES_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Success";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "redirect";
            return newState;
        case CYDER_USERS_DETAIL_SAVE_CHANGES_FAILURE:
            newState.modalMessage = "Failed to save changes: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_USERS_DETAIL_DELETE_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_USERS_DETAIL_DELETE_SUCCESS:
            newState.forceRefresh = true;
            newState.modalMessage = "User successfully deleted";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "redirect";
            return newState;
        case CYDER_USERS_DETAIL_DELETE_FAILURE:
            newState.modalMessage = "Failed to delete user : " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_USERS_DETAIL_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === "save") {
                newState.modalMessage = "Are you sure you want to update this user?";
                newState.saved = false;
            } else if (action.modalAction === "delete") {
                newState.modalMessage = "Are you sure you want to delete this user?";
                newState.saved = false;
            }
            return newState;
        case CYDER_USERS_DETAIL_FORCE_VALIDATE:
            newState.user = Object.assign({}, state.user);
            newState.validation = Object.assign({}, state.validation);
            for (var k in state.user) {
                if (state.user[k] !== null && state.checkFields[k]) {
                    newState.validation[k] = cyderlib.validate(k, state.user[k]);
                }
            }
            newState.saveButtonPressed = true;
            return newState;
        default:
            return state;
    }
}

