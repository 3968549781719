/** @format */

import { EventEmitter } from 'events';

const Constants = {
    MODAL: 'modal',
};

class ConfirmModalManager extends EventEmitter {
    modal = {};

    create(modal) {
        this.modal = modal;
        this.emitChange();
    }

    show(header, body, onConfirm, onlyOneButton) {
        this.create({
            onlyOneButton: onlyOneButton || false,
            header: header || 'Confirmation',
            body: body || '',
            onConfirm,
            isModalOpen: true,
        });
    }

    toggle() {
        this.modal = {
            ...this.modal,
            isModalOpen: !this.modal.isModalOpen,
        };
        this.emitChange();
    }

    emitChange() {
        this.emit(Constants.MODAL, this.modal);
    }

    addChangeListener(callback) {
        this.addListener(Constants.MODAL, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(Constants.MODAL, callback);
    }
}

export default new ConfirmModalManager();
