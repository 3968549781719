import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { setConfig } from '../../actions/config';

class ToggleEmptyLayout extends React.Component {
    constructor() {
        super();
        this.toggleLayout = this.toggleLayout.bind(this);
    }
    toggleLayout() {
        let { layout } = this.props.config;
        const isDefaultSidebar = layout === 'default-sidebar-1';
        this.props.setConfig('layout', isDefaultSidebar ? 'collapsed-sidebar-1' : 'default-sidebar-1');
        this.props.setConfig('collapsed', false);
    }
    render() {
        return (
            <ul className="nav nav-inline nav-toggle-layout-1">
                <li className="nav-item"></li>
            </ul>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        config: state.config,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setConfig: (key, value) => dispatch(setConfig(key, value)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ToggleEmptyLayout);
