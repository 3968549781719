import { connect } from 'react-redux';
import { compose, branch, renderComponent, renderNothing } from 'recompose';
import LeftSidebar from './LeftSidebar';

const layouts = ['default-sidebar-1', 'collapsed-sidebar-1'];

const mapStateToProps = ({ config, expenseNavigation, leaveTypeNavigation }) => {
    const menuNavigation = config.navigationMenu === 'expenseNavigation' ? expenseNavigation : leaveTypeNavigation;
    return {
        navigation: menuNavigation,
        layout: config.layout,
    };
};

const Component = compose(
    connect(mapStateToProps),
    branch(({ layout }) => layouts.includes(layout), renderComponent(LeftSidebar), renderNothing),
)(LeftSidebar);

export default Component;
