import {
    HOME_PAGE_FORCE_LOADING
} from '../../actions/constants/actionTypesHome' 

// import cyderlib from '../../js/cyderlib';

var defaultState = {
    loading: false,
}

export function homeReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case HOME_PAGE_FORCE_LOADING:
            newState.loading = action.loading;
            return newState;
        default:
            return state;
    }
}
